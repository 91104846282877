import React from 'react';
import { Navigate } from "react-router-dom";

import { logoff } from "../helpers/authHelper";
const Logoff = (props) => {
  logoff();
  return (
    <Navigate replace={true} to="/" />)
}

export default Logoff;