import React from 'react';

import './SomaLoader.css';

const SomaLoader = () => {
    return (
        <div className='div__somaloader-container'>
            <div className='div__somaloader-loader'/>
        </div>
    );
};

export default SomaLoader;
