import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';

import { authFetch } from '../../../../helpers/authHelper';
import { encodeFilter } from '../../../../helpers/webHelper';

import '../../../../components/TableSettings/TableSettings.css';
import SomaFilter from '../../../../components/SomaStandard/SomaFilter';
import SomaStatus from '../../../../components/SomaStandard/SomaStatus';
import SomaTable from '../../../../components/SomaStandard/SomaTable/SomaTable';
import ConverteData from '../../../../components/SomaStandard/TableComponents/ConverteData';
import DropdownMenu from '../../../../components/SomaStandard/FilterComponents/DropdownMenu';
import ToFrom from '../../../../components/SomaStandard/FilterComponents/ToFrom';
import DatePicker from '../../../../components/SomaStandard/FilterComponents/DatePicker';
import ButtonExport from '../../Components/DataExcelDivergencia/ButtonExport';

import Tag from '../../../../components/Tag/Tag';
import Spinner from '../../../../components/Spinner/Spinner';

import useDivergenciaReducer from '../../../../Hooks/useDivergenciaReducer';
import ToggleFilter from '../../../../components/SomaStandard/ToggleFilter';
import AddIcon from '@mui/icons-material/Add';
import TableButton from '../../../../components/SomaStandard/TableComponents/TableButton/TableButton';
import Modal from '../../../../components/Modal/Modal';
import useUser from '../../../../Hooks/UserContext';
import ConverteParaIcone from '../../../../components/SomaStandard/TableComponents/ConverteParaIcone';

const ListaDivergencias = () => {
    const { statusId } = useParams()
    const [total, setTotal] = useState(0);
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [status, setStatus] = useState('wait');
    const [filter, setFilter] = useState({ status: statusId });
    const { state, typeStatusDivergencias, iniciarAtendimento, tratativaDivergencias } = useDivergenciaReducer();
    const statusDivergencias = statusId;
    const user = useUser();

    const pillOpts = [
        { key: "AGUARDANDO ANALISE", value: "AGUARDANDO ANALISE", color: "#D66157" },
        { key: "EM ANDAMENTO", value: "PROCESSO EM ANDAMENTO", color: "#E3CC6C" },
        { key: "FINALIZADO", value: "PROCESSO FINALIZADO", color: "#148A6A" },
    ];

    const DivergencyTags = (props) => {
        return (
            <div style={{ display: 'flex' }}>
                {props.line?.divergencia_tag?.includes("falta") && (<Tag name="Falta" color="#ED8E00" />)}
                {props.line?.divergencia_tag?.some(tag => ["extra", "sobra"].includes(tag)) && (<Tag name="Sobra" color="#2596DA" />)}
            </div>
        )
    }

    const StatusAtendimentoTags = (props) => {
        const color = props.line?.status_atendimento === "Aguardando MM" ? "#ED8E00" : "#2596DA";
        return (
            <div style={{ display: 'flex' }}>
                <Tag name={props.line?.status_atendimento} color={color} style={{ padding: "15px", width: "auto" }} />
            </div>
        )
    }

    const findDivergenceGrid = (caixas) => {
        if (caixas) {
            const produtosComDivergencia = caixas?.flatMap(caixa =>
                caixa.Produtos.flatMap(produto => {
                    return produto.Tratativas.flatMap(tratativa => {
                        return {
                            data_apontamento: tratativa.createdAt,
                            produto: tratativa.produto,
                            grade: tratativa.gradeItem,
                            cor: tratativa.cor,
                            situacao: tratativa?.Status?.type,
                            tamanho: tratativa.gradeItem,
                            divergencia_tag: tratativa.idStatusGradeItem === 4 ? ["falta"] : ["extra"],
                            quantidade: tratativa.qtde,
                            pedido: tratativa.pedido,
                            notaFiscal: tratativa.nfSaida,
                            caixa: tratativa.caixa
                        };
                    })
                })
            );
            return produtosComDivergencia.filter(p => p.divergencia_tag);
        }
        return []
    };

    const ButtonProduct = props => {
        const click = () => {
            statusDivergencias === null || statusDivergencias === 'AGUARDANDO ANALISE' ?
                props.setShow(!props.show)
                : tratativaDivergencias(props.line.entrega, props.line.clifor, props.line.cliente)
        }

        return (
            <TableButton clicked={click}>
                <AddIcon />
                <span>Analisar</span>
            </TableButton>
        )
    }

    let Opts = {
        endpoint: `/v1/divergencias`,
        columns: [
            {
                propName: "ROMANEIO",
                componentProps: {
                    colunas: [
                        {
                            propName: "status",
                            propText: "",
                            componentProps: { options: pillOpts },
                            component: ConverteParaIcone,
                        },
                        { propName: "id_atendimento", propText: "Nº ATENDIMENTO" },
                        { propName: "", propText: "DIVERGÊNCIA", component: DivergencyTags },
                        { propName: "data_apontamento", propText: "DATA DO APONTAMENTO", component: ConverteData },
                        { propName: "ultima_alteracao", propText: "ÚLTIMA ALTERAÇÃO", component: ConverteData },
                        { propName: "", propText: "STATUS", component: StatusAtendimentoTags },
                        { propName: "nome_multimarca", propText: "MULTIMARCA" },
                        { propName: "atendente", propText: "COLABORADOR" },
                        { propName: "", propText: "", component: ButtonProduct, isButton: true },
                    ],
                    infoList: {
                        component: (props) => {
                            return <Modal
                                modalName="Iniciar Análise"
                                modalDescription=<span>Ao clicar em prosseguir, o responsável por esse atendimento será <b>{user?.realUser?.cliente || user?.login}</b> e não poderá ser alterado.</span>
                                closeName="Voltar"
                                onClose={() => props.setShow(false)}
                                actionName="PROSSEGUIR"
                                open={props.show}
                                action={() => iniciarAtendimento(props.line.entrega, props.line.clifor)}
                            />
                        }
                    }
                }
            }
        ],
        total, results,
        page, setPage,
        rowsPerPage, setRowsPerPage
    };

    const FilterOpts = {
        filterFields: [
            { propName: "entrega", propText: "Nº Embarque" },
            { propName: "nfSaida", propText: "Nota Fiscal" },
            {
                propName: "DIVERGENCIA_TAG", propText: "Tipo", component: DropdownMenu, componentProps: {
                    options: [
                        { text: "SOBRA", value: "sobra" },
                        { text: "FALTA", value: "falta" },
                    ],
                },
            },
            { propName: "clifor", propText: "Clifor" },
            { propName: "atendente", propText: "Colaborador responsável", show: { filter: !!statusDivergencias && statusDivergencias !== 'AGUARDANDO ANALISE' } },
            { propName: "DATA_APONTAMENTO", component: ToFrom, propText: "Data apontamento", componentProps: { component: DatePicker } },
        ],
        updateFilter: (e) => {
            setFilter({ ...e, "status": statusDivergencias });
            setPage(0);
        }
    };

    const produtos = findDivergenceGrid(results?.flatMap(caixas => caixas?.Caixas));
    const toggleOpts = {
        opts: pillOpts,
        valorAtivo: statusDivergencias || "AGUARDANDO ANALISE",
        handleChange: (event, newAlignment) => {
            if (newAlignment) {
                setFilter((prev) => {
                    return { ...prev, "status": newAlignment };
                });
                setPage(0);
                typeStatusDivergencias(newAlignment)
            }
        },
    };

    useEffect(() => {
        const abortController = new AbortController();
        setStatus('wait');
        (async () => {
            try {
                const json = await (await authFetch(
                    `${Opts.endpoint}?pg=${page}&sz=${rowsPerPage}&${encodeFilter(filter)}`,
                    undefined,
                    undefined,
                    abortController.signal
                )).json();

                setResults(json.results);
                setTotal(json.total);
                setStatus(json.results?.length ? 'result' : 'empty');
            } catch (err) {
                setResults(undefined);
                if (abortController.signal.aborted) setStatus('wait');
                else setStatus('error');
            }
        })();

        return () => {
            abortController.abort();
        };

    }, [Opts.endpoint, page, rowsPerPage, filter]);

    return (
        state.loading ?
            <Spinner /> :
            <div style={{ padding: "0 31px" }}>
                <div className='div__tableFilters--conteiner'>
                    <ButtonExport results={results} products={produtos} />
                    <SomaFilter {...FilterOpts} />
                </div>
                <ToggleFilter {...toggleOpts} />
                <br /><br />
                <SomaStatus status={status}>
                    <SomaTable {...Opts} />
                </SomaStatus>
            </div>
    );
};

export default ListaDivergencias