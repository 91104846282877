import React from 'react';
import { Box, Typography } from "@mui/material";
import Modal from "../../components/Modal";

import styles from './ConfirmPasswordResetModal.module.css';

const ConfirmPasswordResetModal = (props) => {
  return (
    <Modal open={props.open} modalName={"NÃO SABE SUA SENHA?"} actionName={"ENVIAR SENHA"} action={props.onConfirm} onClose={props.onClose}>
      <Box className={styles.modalbox}>
        <Typography style={{ color: "#8B8B8B" }}>
          Encontramos esse endereço de email no seu cadastro:
        </Typography>
        <Typography style={{ padding: "10px" }}>{props.confirmEmail}</Typography>
        <Typography style={{ color: "#8B8B8B", paddingBottom: '10px' }}>
          Ao clicar em "Enviar senha", voce confirma que esse é seu endereço atual.
        </Typography>
        <Typography style={{ color: "#8B8B8B", paddingBottom: '10px' }}>
          Caso não reconheça esse endereço ou necessite de atualização, entre em contato com o seu representante ou o comercial.
        </Typography>
        <Typography style={{ color: "#000", paddingBottom: '10px', fontWeight: "bold" }}>
          Se não encontrar na sua caixa de entrada, verifique na sua caixa de spam ou lixo eletrônico.
        </Typography>
      </Box>
    </Modal>
  );
};

export default ConfirmPasswordResetModal;
