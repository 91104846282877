import React from 'react';
import { Button } from "@mui/material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const ContratoButton = (props) => {
  return <Button
    disabled={props.disabled}
    onClick={() => props.onClick()}
    color={'primary'}
    variant={'contained'}
    startIcon={!Array.isArray(props.value) ? <CheckBoxIcon /> : <CheckBoxOutlineBlankIcon />}
    style={{ width: "100%", height: "100%", color: "white", fontWeight: 600, backgroundColor: props.error ? 'red' : undefined }}>
    {props.label}
  </Button>
}


export default ContratoButton