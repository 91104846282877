import React from "react";

const ConverteDinheiro = (props) =>
  typeof props.children === "number" ? (
    <span style={{whiteSpace:"nowrap"}}> { parseFloat(props.children).toLocaleString('pt-br',{style:'currency',currency:'BRL'}) } </span>
  ) : (
    <span>{props.children}</span>
  );

export default ConverteDinheiro;
