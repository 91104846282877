import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import ConverteData from '../../../components/SomaStandard/TableComponents/ConverteData';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import { encodeFilter } from '../../../helpers/webHelper';
import TableSettings from '../../../components/TableSettings/TableSettings';


const Bloqueios = (props) => {
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState('');

  let Opts = {
    endpoint: "/v1/bloqueio",
    columns: [
      {
        propName: "BLOQUEIO",
        componentProps: {
          colunas: [
            { propName: "CLIENTE_ATACADO", propText: "Cliente ", highlighted: true },
            { propName: "TIPO_BLOQUEIO", propText: "Tipo de bloqueio" },
            { propName: "BLOQUEIO_EXPEDICAO", propText: "D. bloqueio de Expedição", component: ConverteData },
            { propName: "BLOQUEIO_FATURAMENTO", propText: "D. bloqueio de Faturamento", component: ConverteData },
            { propName: "BLOQUEIO_PEDIDOS", propText: "D. bloqueio de Pedidos", component: ConverteData }
          ]
        }
      }
    ],
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      { propName: "CLIENTE_ATACADO[lk]", propText: "Cliente", format: val => `%${val}%` },
      { propName: "BLOQUEIO_EXPEDICAO[lk]", propText: "Bloqueio Expedição" },
      { propName: "BLOQUEIO_FATURAMENTO[lk]", propText: "Bloqueio Faturamento" },
      { propName: "BLOQUEIO_PEDIDOS[lk]", propText: "Bloqueio Pedidos" },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  useEffect(() => {
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`)).json();
        setResults(json.results);
        setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
      } catch (err) {
        setResults(undefined);
        console.log(err);
        setStatus('error');
      }
    })();
  }, [Opts.endpoint, page, rowsPerPage, filter]);

  return <>
    <TableSettings>
      <div></div>
      <SomaFilter {...FilterOpts} />
    </TableSettings>
    <SomaStatus status={status}>
      <SomaTable {...Opts} />
    </SomaStatus>

  </>;
};


export default Bloqueios;
