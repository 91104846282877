import React from 'react';
import ListaAfiliados from '../Components/ListaAfiliados/ListaAfiliados';
import NPossuiCadastro from '../Components/NPossuiCadastro';
import useAffiliate from '../../../Hooks/AffiliateContext';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';

const Afiliados = (props) => {
    const { affiliate, status, updateAffiliate, fetchStatus } = useAffiliate();

    if(fetchStatus === 'pending'){
        return <SomaStatus status='wait' />;
    }
    if (affiliate) {
        return <ListaAfiliados data={affiliate} status={status} reload={updateAffiliate} />
    } else {
        return <NPossuiCadastro />
    }
};

export default Afiliados;