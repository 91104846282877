import React from "react";
import { TextField } from "@mui/material";
import Modal from "../../components/Modal";
import { useReducer } from "react";
import { useState } from "react";
import { useCallback } from "react";
import MaisAtacado from "../../Services/MaisAtacado";
import { toast } from "react-toastify";
import styles from "./ChangePassword.module.css";
import PasswordCheck, { ValidationMessages } from "../../Utils/PasswordChecks";

const ChangePasswordReducer = (state, event) => {
  if (event.type === 'reset') return { type: "mostrandoEmail" };
  return ({
    mostrandoEmail: {
      retornoApi: (st, ev) => ({
        type: "codigoEnviado",
        tries: 0,
      }),
    },
    codigoEnviado: {
      voltar: (st, ev) => ({
        type: "mostrandoEmail",
      }),
      retornoApi: (st, ev) => ({
        type: "inputSenha",
        code: ev.code
      }),
      erroApi: (st, ev) => (st.tries >= 2 ? {
        ...st,
        type: "bloqueio",
      } : {
        ...st,
        tries: st.tries + 1,
      })
    },
  }[state.type][event.type] || (() => { })
  )(state, event);
};

export const MostrandoEmail = (props) => <>
  <p data-testid="MostrandoEmail">Encontramos esse endereço de email no seu cadastro:</p>
  <span className={styles.emphasis}>{props.email}</span>
  <p>Ao clicar em "Continuar" você confirma que esse é seu email atual.</p>
  <p>Caso não reconheça esse endereço ou necessite de atualização, entre em contato com o seu representante ou o comercial.</p>
</>;

export const CodigoEnviado = (props) => {
  const [code, setCode] = useState("");

  return (
    <>
      <p data-testid="CodigoEnviado">Voce recebeu um codigo por email, com ele você poderá definir sua nova senha</p>
      <p><strong>Se não o encontrar na sua caixa de entrada, verifique na sua caixa de spam ou lixo eletrônico.</strong></p>

      <TextField
        data-testid="inputCodigoEnviado"
        style={{ backgroundColor: "rgba(255, 255, 255)", padding: "10px" }}
        name="code"
        placeholder="codigo"
        onChange={(e) => setCode(e.target.value)}
        value={code}
      />
    </>
  );
};

const PassIcon = () => <span style={{ color: 'green', marginRight: '8px', fontSize: '1rem' }}>✓</span>;// ✓✗  ☑☒
const FailIcon = () => <span style={{ color: 'red', marginRight: '8px', fontSize: '1rem' }}>✗</span>;

export const InputSenha = (props) => {
  const [senha, setSenha] = useState("");
  const [confirma, setConfirma] = useState("");
  const conditions = PasswordCheck(senha, confirma);

  return (
    <>
      <p data-testid="InputSenha">Agora você pode definir sua nova senha</p>
      <TextField
        data-testid="inputSenhaText"
        style={{ backgroundColor: "rgba(255, 255, 255)", padding: "10px" }}
        name="senha"
        placeholder="senha"
        onChange={(e) => setSenha(e.target.value)}
        value={senha}
      />
      <TextField
        data-testid="inputSenhaTextConfirm"
        style={{ backgroundColor: "rgba(255, 255, 255)", padding: "10px" }}
        name="confirma"
        placeholder="confirmar senha"
        onChange={(e) => setConfirma(e.target.value)}
        value={confirma}
      />
      <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit,minmax(200px, 1fr))' }}>
        {Object.entries(conditions.tests).map(([cond, pass]) => <small key={cond}>{pass ? <PassIcon /> : <FailIcon />}{ValidationMessages[cond]}</small>)}
      </div>
    </>
  );
};

const ChangePasswordModal = ({ clifor, confirmEmail, onClose, open }) => {
  const [state, dispatch] = useReducer(ChangePasswordReducer, { type: 'mostrandoEmail' });

  const mostrandoEmail = useCallback(async (e) => {
    try {
      await MaisAtacado.Auth.startPasswordChange(clifor);
      dispatch({ type: 'retornoApi' });
    } catch (error) {
      toast.error('Parece que encontramos um problema, tente novamente mais tarde');
      dispatch({ type: 'reset' });
      onClose();
    }
  }, [clifor, onClose]);

  const codigoEnviado = useCallback(async (e) => {
    try {
      await MaisAtacado.Auth.checkPasswordChange(e.code, clifor);
      dispatch({ type: 'retornoApi', code: e.code });
    } catch (error) {
      dispatch({ type: 'erroApi' });
      if (state.tries >= 2) {
        toast.error('Parece que encontramos um problema, tente novamente mais tarde');
        dispatch({ type: 'reset' });
        onClose();
      } else {
        toast.error('Codigo Incorreto');
      }
    }
  }, [clifor, onClose, state.tries]);

  const inputSenha = useCallback(async (e) => {
    const conditions = PasswordCheck(e.senha, e.confirma);
    if (!conditions.pass) {
      toast.error('Verifique as condições de senha');
      return;
    }
    try {
      await MaisAtacado.Auth.endPasswordChange(state.code, clifor, e.senha, e.confirma);
      toast.success('Senha Alterada com Sucesso');
      dispatch({ type: 'reset' });
      onClose();
    } catch (error) {
      toast.error(error.response?.data.message);
    }
  }, [clifor, onClose, state.code]);

  return (
    <Modal
      open={open}
      modalName={"NÃO SABE SUA SENHA?"}
      actionName={"CONTINUAR"}
      action={{ mostrandoEmail, codigoEnviado, inputSenha }[state.type]}
      onClose={state.type === 'codigoEnviado' ? (e) => dispatch({ type: 'voltar' }) : onClose}
    >
      {state.type === "mostrandoEmail" && (
        <MostrandoEmail email={confirmEmail} />
      )}
      {state.type === "codigoEnviado" && <CodigoEnviado />}
      {state.type === "inputSenha" && <InputSenha />}
    </Modal>
  );
};

export default ChangePasswordModal;
