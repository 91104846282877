import React, { useState, useEffect } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DatePickerComponent = (props) => {
  const [date, setDate] = useState(null);
  const { onChange, isOnlyDate, endDate, maxDate, minDate} = props;

  useEffect(() => {
    if (isOnlyDate) {
      onChange(date && date.valueOf() && date.toISOString().split('T')[0])
    } else {
      if (endDate) {
        date && date.setHours(23)
      } else {
        date && date.setHours(0, 0, 0, 0)
      }
      onChange(date && date.valueOf() && date.toISOString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  let timeoutID = undefined;
  return (
    <DatePicker
      data-testid="KeyboardDatePicker"
      slotProps={{ textField: { variant: 'standard', sx: { mr: 2 } } }}
      value={date}
      maxDate={maxDate}
      minDate={minDate}
      onChange={(e) => {
        clearTimeout(timeoutID);
        timeoutID = setTimeout(() => {
          setDate(e);
        }, 200);
      }}
      InputLabelProps={{ shrink: true }}
      label={props.label}
      disableToolbar={true}
      variant="inline"
      format="dd/MM/yyyy"
    />
  );
};

export default DatePickerComponent;
