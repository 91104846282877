import React from "react";
import { Grid } from "@mui/material";
import ConverteDinheiro from "../../../components/SomaStandard/TableComponents/ConverteDinheiro";
import GerarPagamentoButton from "../Components/GerarPagamentoButton";

const footerStyle = {
  backgroundColor: "white",
  fontSize: "20px",
  color: "gray",
  borderTop: "1px solid #E7E7E7",
  fontFamily: "flama-medium",
  textAlign: "center",
  // padding: "10px",
  position: "fixed",
  left: "0",
  bottom: "0",
  height: "auto",
  width: "100%",
};

const phantomStyle = {
  display: "block",
  padding: "20px",
  height: "60px",
  width: "100%",
};

const BarraFlutuante = ({
  id_profiles,
  updateCheckBox,
  setCheckBox,
  optionSelected,
  setComissaoTotal,
  setCredito,
  setDeposito,
  comissaoTotal,
  credito,
  deposito,
  valorTotal,
  setStatus,
  upload,
}) => {
  return (
    <div>
      <div style={phantomStyle} />
      <div style={footerStyle}>
        <Grid container>
          <Grid
            item
            sm
            style={{
              padding: "5px",
              textAlign: "left",
              marginLeft: "15%",
              color: "#C6A489",
              size: "13px",
            }}
          >
            <Grid container>
              <Grid item xs={12}>
                <span> CALCULADORA DE COMISSÕES </span>
              </Grid>
            </Grid>

            <Grid
              container
              style={{
                color: "#707070",
                size: "11px",
                fontFamily: "flama-book",
              }}
            >
              <Grid item xs={4} sm={4}>
                <span> Comissão: </span>
              </Grid>
              <Grid item xs={4} sm={4}>
                <span> Crédito: </span>
              </Grid>
              <Grid item xs={4} sm={4}>
                <span> Depósito: </span>
              </Grid>
            </Grid>

            <Grid container style={{ color: "#707070", size: "13px" }}>
              <Grid item xs={4} sm={4}>
                <ConverteDinheiro children={comissaoTotal} />
              </Grid>
              <Grid item xs={4} sm={4}>
                <ConverteDinheiro children={credito} />
              </Grid>
              <Grid item xs={4} sm={4}>
                <ConverteDinheiro children={deposito} />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            sm
            style={{ padding: "25px", textAlign: "right", marginRight: "8%" }}
          >
            <GerarPagamentoButton
              profile={id_profiles}
              updateCheckBox={updateCheckBox}
              valorTotal={valorTotal}
              comissaoTotal={comissaoTotal}
              setCheckBox={setCheckBox}
              options={optionSelected}
              setComissaoTotal={setComissaoTotal}
              setCredito={setCredito}
              setDeposito={setDeposito}
              setStatus={setStatus}
              upload={upload}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BarraFlutuante;
