import React, { useState } from 'react';
import { Grid, TextField, CircularProgress, Box } from '@mui/material';
import './SomaFormStyle/SomaForm.css'

import PrimaryButton from '../../components/PrimaryButton/PrimaryButton'

const SomaForm = (props) => {
  const [state, setState] = props.state;
  const [errors, setErrors] = useState({});
  const [wait, setWait] = useState(false);

  const validateState = () => {
    let newE = { ...errors }
    const valid = props.columns.map(c => {
      if (c.validate) {
        return newE[c.propName] = c.validate.map(v => v.test(state[c.propName]) ? false : v.message).filter(v => v)[0]
      }
      else {
        return false
      }
    }
    ).reduce((a, b) => a || b)
    setErrors({ ...errors, ...newE })
    return valid;
  }

  const submit = (e) => {
    setWait(true)
    e.preventDefault();
    if (!validateState()) {
      props.setResults(state).then(() => { setState({}) }).finally(e => setWait(false))
    } else {
      setWait(false)
    }
  }

  return (
    <div style={{ padding: "20px" }} >
      <Grid className="div__form--conteiner" container direction='row' spacing={3} >
        {props.columns.map((c, i) =>
          <Grid key={`field-${i}`} item xs={c.size || 6} className="div__input--items" style={{ alignSelf: 'center', overflow: "none" }}>
            <Box flex={1} padding='10px'
            // bgcolor="rgba(207, 188, 173, 0.20)"
            >
              {c.component ?
                <c.component
                  error={errors[c.propName] ? true : false}
                  helperText={errors[c.propName]}
                  {...c.componentProps}
                  label={c.propText}
                  value={state[c.propName] !== undefined ? state[c.propName] : []}
                  disabled={props.disable?.[c.propName]}
                  onChange={(value) => setState({ ...state, [c.propName]: c.parse ? c.parse(value) : value })} />
                :
                <TextField
                  error={errors[c.propName] ? true : false}
                  helperText={errors[c.propName]}
                  label={c.propText}
                  value={c.propName === 'id_profiles' ? c.propProfile : state[c.propName] || ""}
                  onChange={({ target: { value } }) => setState({ ...state, [c.propName]: c.parse ? c.parse(value) : value })}
                  InputLabelProps={{ shrink: true }}
                  variant="standard"
                  disabled={props.disable?.[c.propName]}
                  color="secondary"
                  fullWidth />
              }
            </Box>
          </Grid>
        )}
      </Grid>
      <br /><br />
      <Grid container justifyContent="flex-end">
        <PrimaryButton disabled={wait} type="button" onClick={submit}>
          {wait ? <CircularProgress color='secondary' size={24} /> : 'Salvar'}
        </PrimaryButton>
      </Grid>


    </div>
  )
}
export default SomaForm;