import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { ptBR } from "@mui/material/locale";

const sizeStr = (s) => `max(min(calc(${s.min}px + (${s.max} - ${s.min}) * ((100vw - ${s.minVW}px) / (${s.maxVW} - ${s.minVW}))), ${s.max}px),${s.min}px)`

const sizes = [
  { min: 9, max: 14, minVW: 280, maxVW: 1000 },
  { min: 10, max: 16, minVW: 280, maxVW: 1000 },
  { min: 11, max: 17.5, minVW: 280, maxVW: 1000 },
  { min: 12, max: 19, minVW: 280, maxVW: 1000 },
  { min: 13, max: 28, minVW: 280, maxVW: 1000 },
  { min: 14, max: 30, minVW: 280, maxVW: 1000 },
  { min: 15, max: 32, minVW: 280, maxVW: 1000 },
  { min: 16, max: 34, minVW: 280, maxVW: 1000 },
  { min: 17, max: 36, minVW: 280, maxVW: 1000 },
  { min: 18, max: 38, minVW: 280, maxVW: 1000 }
];

const palette = {
  primary: {
    main: '#AD9078'
  },
  secondary: {
    main: '#CFBCAD'
  },
  pastel: {
    escuro: '#C6A489',
    intenso: '#AD9078',
    medio: '#CFBCAD',
    medio2: '#DCC6B7',
    claro: '#F4EDE8',
    claro2: '#F5EFEA'
  },
  branco: {
    main: '#FFFFFF'
  },
  cinza: {
    escuro: '#707070',
    medio: '#979797',
    claro: '#F6F6F6'
  }
};

const themeName = 'Turbo Black Kultarr';
const typography = {
  h1: {},
  h2: {},
  h3: {},
  h4: {},
  h5: {},
  h6: {},
  subtitle1: {},
  subtitle2: {},
  body1: {},
  body2: {},
  button: {},
  caption: {},
  overline: {},
  fontFamily: [
    'flama-basic',
    'Roboto',
    '"Segoe UI"',
    '-apple-system',
    'sans-serif'
  ]
};
const overrides = {};
const extras = {
  fontStyles: {
    flama: {
      fontFamily: 'flama-basic'
    },
    flamaBook: {
      fontFamily: 'flama-book'
    },
    flamaM: {
      fontFamily: 'flama-medium'
    },
    flamaU: {
      fontFamily: 'Flama Ultracond'
    },
    flamaS: {
      fontFamily: 'Flama Semicond'
    },
    bolder: {
      fontWeight: 'bolder'
    },
    bold: {
      fontWeight: 'bold'
    },
    semibold: {
      fontWeight: 'semibold'
    },
    normal: {
      fontWeight: 'normal'
    },
    book: {
      fontWeight: 'book'
    },
    light: {
      fontWeight: 'light'
    },
    thin: {
      fontWeight: 'thin'
    },
    lighter: {
      fontWeight: 'lighter'
    }
  },
  flexFontSizes: {
    ...Array(20).fill().map((x, i) => i + 12).reduce((obj, cur, i) => {
      obj[`s${i + 1}`] = { fontSize: sizeStr({ min: cur, max: cur * 1.2, minVW: 280, maxVW: 1000 }) };
      return obj;
    }, {}),
    ...sizes.reduce((obj, cur, i) => {
      obj[`c${i + 1}`] = { fontSize: sizeStr(cur) };
      return obj;
    }, {})
  }
};
const fontSizeOptions = {};

const combineClasses = {
  naive: (...classes) => classes.join(' '),
  boolean: (...classes) => Object.entries(classes).filter(([n, b]) => b).map(([n, b]) => n).join(' '),
};

export default responsiveFontSizes(
  createTheme(
    {
      palette,
      themeName,
      typography,
      overrides,
      ...extras
    }
    , ptBR)
  , fontSizeOptions,
);
export { combineClasses }