import React, { useCallback, useEffect, useState } from 'react';
//import { Typography } from '@mui/material'
import { authFetch } from '../../../helpers/authHelper';
import SomaForm from '../../../components/SomaStandard/SomaForm';
import Dropdown from '../../../components/SomaStandard/FormComponents/Dropdown';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal';
import Contrato from './Contrato';
import ContratoButton from './ContratoButton';
import useAffiliate from '../../../Hooks/AffiliateContext';

const parseCep = (cep) => cep.match(/\d/g)?.slice(0, 8).map((c, i, l) => { if (i === 2) return '.' + c; if (i === 5) return '-' + c; return c; }).join('');

const FormMultimarca = (props) => {
  const { profiles, edit, setAfiliado } = props;

  const selectPerfil = profiles.length >= 2;

  const profile = selectPerfil ? edit?.id_profiles || 9 : edit?.id_profiles === profiles[0].id_profiles ? edit?.id_profiles : profiles[0].id_profiles;

  const [state, setState] = useState({ state: '', id_profiles: profile, ...edit });
  const [showContrato, setShowContrato] = useState(false);




  const { acceptedNewContract } = useAffiliate();

  useEffect(() => {
    if (state.contratoAccept && state.id_profiles !== state.contratoAccept.id_profiles) {
      setState({ ...state, contratoAccept: undefined });
    }
  }, [state.id_profiles, state]);

  useEffect(() => {
    if (!acceptedNewContract) setShowContrato(true);
  }, [acceptedNewContract]);

  const closeModal = useCallback(() => setShowContrato(false), [setShowContrato]);

  let CepLookupEvent = 0;
  const cepLookup = async (cep) => {
    const ans = await fetch(`http://viacep.com.br/ws/${cep}/json/`).then(r => r.json());

    setState({ ...state, city: ans.localidade, state: ans.uf, postalCode: parseCep(cep) });
  };

  const Opts = {
    state: [state, setState],
    setResults: (result) => new Promise(async (resolve, reject) => {
      const response = await authFetch('/v1/afiliados/Multimarca', edit ? 'PATCH' : 'POST', result);
      const afiliado = await response.json();
      if (afiliado.success) {
        setTimeout(() => setAfiliado(afiliado.data).then(() => resolve()), 4000);
        toast.success("Cadastro Efetuado com sucesso", { theme: "colored" });
      }
      else {
        reject();
        toast.error(afiliado.message, { theme: "colored" });
      }
    })
    ,
    disable: edit ? {
      name: true,
      email: true,
      document: true,
      id_profiles: selectPerfil ? false : true
    } : {}
    ,
    columns: [
      selectPerfil ?
        {
          //campo perfil dropdown
          propText: 'Perfil*',
          propName: 'id_profiles',
          size: 8,
          component: Dropdown,
          componentProps: {
            options: profiles.map((p) => ({
              text: p.profile_name, value: p.id_profiles
            })),
          },
          validate: [{ test: (e) => e, message: 'Obrigatorio' }]
        } :
        {
          //campo perfil texto
          propText: 'perfil',
          propName: 'id_profiles',
          propProfile: profiles[0].profile_name,
          validate: [{ test: (e) => e, message: 'Obrigatorio' }]
        },
      {
        propText: 'Contrato',
        propName: 'contratoAccept',
        component: ContratoButton,
        componentProps: { onClick: () => { setShowContrato(true); } },
        size: 4,
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Email*',
        propName: 'email',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: (e) => e?.split('@').length >= 2, message: 'Email Invalido' }]
      },
      {
        propText: 'Telefone',
        propName: 'phone',
        parse: (v) => v.match(/\d/g)?.slice(0, 10).map((c, i, l) => {
          if (i === 0) return '(' + c;
          if (i === 2) return ')' + c;
          if (i === 6) return '-' + c;
          return c;
        }).join('')
      },
      {
        propText: 'Celular*',
        propName: 'cellPhone',
        parse: (v) => v.match(/\d/g)?.slice(0, 11).map((c, i, l) => {
          if (i === 0) return '(' + c;
          if (i === 2) return ')' + c;
          if (i === 7) return '-' + c;
          return c;
        }).join(''),
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'CEP*',
        propName: 'postalCode',
        parse: (v) => {
          if (v?.length === 10) {
            clearTimeout(CepLookupEvent);
            CepLookupEvent = setTimeout(() => cepLookup(v.match(/\d/g).join('')), 300);
          }
          return parseCep(v);
        },
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Cidade*',
        propName: 'city',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Estado*',
        propName: 'state',
        component: Dropdown,
        componentProps: {
          options: [
            { text: 'Acre', value: 'AC' },
            { text: 'Alagoas', value: 'AL' },
            { text: 'Amapá', value: 'AP' },
            { text: 'Amazonas', value: 'AM' },
            { text: 'Bahia', value: 'BA' },
            { text: 'Ceará', value: 'CE' },
            { text: 'Distrito Federal', value: 'DF' },
            { text: 'Espírito Santo', value: 'ES' },
            { text: 'Goiás', value: 'GO' },
            { text: 'Maranhão', value: 'MA' },
            { text: 'Mato Grosso', value: 'MT' },
            { text: 'Mato Grosso do Sul', value: 'MS' },
            { text: 'Minas Gerais', value: 'MG' },
            { text: 'Pará', value: 'PA' },
            { text: 'Paraíba', value: 'PB' },
            { text: 'Paraná', value: 'PR' },
            { text: 'Pernambuco', value: 'PE' },
            { text: 'Piauí', value: 'PI' },
            { text: 'Rio de Janeiro', value: 'RJ' },
            { text: 'Rio Grande do Norte', value: 'RN' },
            { text: 'Rio Grande do Sul', value: 'RS' },
            { text: 'Rondônia', value: 'RO' },
            { text: 'Roraima', value: 'RR' },
            { text: 'Santa Catarina', value: 'SC' },
            { text: 'São Paulo', value: 'SP' },
            { text: 'Sergipe', value: 'SE' },
            { text: 'Tocantins', value: 'TO' }
          ]
        },
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Codigo do Banco*',
        propName: 'banco',
        parse: (v) => v.match(/\d/g)?.slice(0, 3).join(''),
        size: 4,
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Agencia*',
        propName: 'agencia',
        size: 4,
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Conta*',
        propName: 'conta',
        parse: (v) => v.match(/\d/g)?.slice(0, 12).map((c, i, l) => { if (i === l.length - 1) return '-' + c; return c; }).join(''),
        size: 4,
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      }
    ]
  };

  return (
    <>
      {/*<Modal open={showUpdateModal} actionName="aceitar contrato" closeName="mais tarde" modalName="reajuste da comissão" action={() => { setShowContrato(true); setShowUpdateModal(false) }} onClose={() => { setShowUpdateModal(false); setShowConfirmModal(true) }}>
        <Typography style={{ textAlign: "center", fontSize: '1.25rem', marginBottom: "28px", color: "#000000", fontWeight: "bold" }}>Programa afiliados</Typography>

        <Typography style={{ color: "#707070", marginBottom: "21px" }}>Lojista parceiro,</Typography>

        <Typography style={{ color: "#707070", marginBottom: "16px" }}>Como todos vocês sabem, o <span style={{ color: "#000", fontWeight: 'bold' }}>PROGRAMA AFILIADOS</span> nasceu com o objetivo de fortalecer a parceria entre os lojistas, e as marcas do Grupo de Moda SOMA. O mesmo tem sido sucesso absoluto em todas as esferas, e para que ele continue sendo saudável para todas as partes envolvidas, tornam-se necessários alguns ajustes.</Typography>

        <Typography style={{ color: "#707070", marginBottom: "16px" }}>A partir do dia <span style={{ color: "#000", fontWeight: 'bold' }}>01/11/2021</span>, o percentual de comissão pago no <span style={{ color: "#000", fontWeight: 'bold' }}>PROGRAMA AFILIADOS</span>, passará a ser de <span style={{ color: "#000", fontWeight: 'bold' }}>10%</span> em cima das vendas realizadas com os códigos cadastrados em suas multimarcas. O pagamento será feito exclusivamente no formato de <span style={{ color: "#000", fontWeight: 'bold' }}>CRÉDITO</span> para os pedidos futuros.</Typography>

        <Typography style={{ color: "#707070", marginBottom: "16px" }}>Nossa orientação é que deste valor, <span style={{ color: "#000", fontWeight: 'bold' }}>6%</span> fiquem como pagamento para a multimarca, e <span style={{ color: "#000", fontWeight: 'bold' }}>4%</span> como pagamento para seus vendedores. </Typography>

        <Typography style={{ color: "#707070", marginBottom: "16px" }}>Para que sua permanência seja garantida no <span style={{ color: "#000", fontWeight: 'bold' }}>PROGRAMA AFILIADOS</span>, é necessária a validação de um novo contrato, que você encontra no botão abaixo. É só clicar e continuar aproveitando todos os benefícios.</Typography>

        <Typography style={{ color: "#707070", marginBottom: "16px" }}><span style={{ color: "#000", fontWeight: 'bold' }}>IMPORTANTE:</span> Se a validação do novo contrato não for realizada até o dia <span style={{ color: "#000", fontWeight: 'bold' }}>30/10/2021</span>, a sua multimarca e todos os vendedores cadastrados nela, estarão suspensos do <span style={{ color: "#000", fontWeight: 'bold' }}>PROGRAMA AFILIADOS</span>, e a partir do dia <span style={{ color: "#000", fontWeight: 'bold' }}>08/11/2021</span>, os seus vendedores estarão disponíveis para ingressar em outra multimarca.</Typography>
      </Modal>

      <Modal open={showConfirmModal} actionName="atualizar agora" closeName="sair" modalName="Tem certeza ?" action={() => { setShowContrato(true); setShowConfirmModal(false) }} onClose={() => setShowConfirmModal(false)}>
        <Typography style={{ textAlign: "center", fontSize: '1.25rem', marginBottom: "28px", color: "#000000", fontWeight: "bold" }}>Programa afiliados</Typography>
        <Typography style={{ color: "#707070" }}>Se a validação do novo contrato não for realizada até o dia <span style={{ color: "#000", fontWeight: 'bold' }}>30/10/2021</span>, a sua multimarca e todos os vendedores cadastrados nela, estarão suspensos do <span style={{ color: "#000", fontWeight: 'bold' }}>PROGRAMA AFILIADOS</span>, e a partir do dia <span style={{ color: "#000", fontWeight: 'bold' }}>08/11/2021</span>, os seus vendedores estarão disponíveis para ingressar em outra multimarca. </Typography>
      </Modal>*/}

      <Modal open={showContrato} modalName={"Contrato"} hideButtons onClose={closeModal}>
        <Contrato perfil={state.id_profiles} onCancel={closeModal} aceito={(contrato) => {
          setState({
            ...state, contratoAccept: {
              id_contracts: contrato.id_contracts,
              id_profiles: contrato.id_profiles, accepted_at: new Date()
            }
          });
          closeModal();
          toast.warn("Você precisa salvar para atualizar o contrato!!", { theme: "colored" });
        }} />
      </Modal>
      <SomaForm {...Opts} />
    </>
  );
};

export default FormMultimarca;
