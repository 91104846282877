import React from "react";
import { useLocation } from "react-router-dom";

import FullPage from "../../components/FullPage";

import SViaNotaFiscal from "./Section/SViaNotaFiscal";
import MeusPedidos from "./Section/MeusPedidos";
import BemVindo from "../../components/BemVindo";
import Bloqueios from "./Section/Bloqueios";
import Metas from "./Section/Metas";
import Recebimento from "./Section/Recebimento";


const ComercialPage = (props) => {
  const location = useLocation();
  let path = location.pathname

  const insertProductPathname = location.pathname.includes('insert');

  let routes = [
    //{ route: "fat-realizados", text: "FATURAMENTOS REALIZADOS", component: FatRealizados },
    //{ route: "curva-fat", text: "CURVA DE FATURAMENTO", component: CurvaFat },
    {
      route: "seg-via-nota-fiscal",
      text: "SEGUNDA VIA DE NOTA FISCAL",
      component: SViaNotaFiscal,
    },
    { route: "meus-pedidos", text: "MEUS PEDIDOS", component: MeusPedidos },
    { route: "bloqueios", text: "BLOQUEIOS", component: Bloqueios },
    {
      route: "recebimento",
      text: insertProductPathname ? "ADICIONAR PRODUTO" : "RECEBIMENTO",
      component: Recebimento
    },
    { route: "metas", text: "METAS", component: Metas, show: "repre" },
  ];

  let config = {
    parentUrl: path,
    parentName: "COMERCIAL",
    parentComponent: BemVindo,
    routes: routes,
  };

  return <FullPage {...config} />;
};

export default ComercialPage;
