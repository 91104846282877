import React, { useState } from 'react';
import SomaForm from '../../../components/SomaStandard/SomaForm';
import Dropdown from '../../../components/SomaStandard/FormComponents/Dropdown';
import { authFetch } from '../../../helpers/authHelper';
import { toast } from 'react-toastify';
import Modal from '../../../components/Modal/Modal';
import { Grid, Typography } from '@mui/material';

const validateCPF = (e) => {
  if (!e) return false;
  let dig = e.match(/\d/g).map(d => parseInt(d));
  let dv = [
    (dig?.slice(0, 9).map((n, i) => n * (10 - i)).reduce((_, e) => _ + e) * 10) % 11 % 10,
    (dig?.slice(0, 10).map((n, i) => n * (11 - i)).reduce((_, e) => _ + e) * 10) % 11 % 10
  ];
  let rep = !dig?.slice(0, 9).map((e, i, l) => e === l[0]).reduce((_, e) => _ && e);

  return dig[9] === dv[0] && dig[10] === dv[1] && rep
}


const FormAfiliado = () => {
  const [state, setState] = useState({ state: '', });
  const [afiliadoCriado, setAfiliadoCriado] = useState({})

  const Opts = {
    state: [state, setState],
    setResults: (result) => new Promise(async (resolve, reject) => {
      const response = await authFetch('/v1/Afiliados/cadastrarAfiliado', 'POST', result);
      const afiliado = await response.json();

      if (afiliado.success) {
        setState({ state: '', });
        resolve()
        setAfiliadoCriado(afiliado.result)
        toast.success("Cadastro Efetuado com sucesso", { theme: "colored" });
      }
      else {
        reject();
        toast.error(afiliado.message, { theme: "colored" });
      }
    }),
    columns: [
      {
        propText: 'Nome*',
        propName: 'affiliate_name',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'perfil*',
        propName: 'id_profiles',
        component: Dropdown,
        componentProps: {
          options: [
            { text: 'Multimarca - Vendedores', value: '1' },
            { text: 'Multimarca - Crédito + Depósito', value: '2' },
            { text: 'Multimarca - Representantes', value: '3' },
            { text: 'Influenciadores', value: '4' },
            { text: 'Adoro Código - Treinadores', value: '5' },
            { text: 'Adoro Código - Vendedores', value: '6' },
            { text: 'Loja Virtual Animale - Treinadores', value: '7' },
            { text: 'Loja Virtual Animale - Vendedores', value: '8' },
            { text: 'Multimarca - Credito', value: '9' },
            { text: '+afiliados teste', value: '10' },
            { text: 'Influenciadores Foxton', value: '11' },
            { text: 'Afiliados Bloqueados', value: '12' },
            { text: 'Influenciadores Off', value: '13' },
            { text: 'Influenciadores Off - Vendedores', value: '14' },
            { text: 'Quero Vender', value: '15' },
            { text: 'Quero Vender - Vendedores', value: '16' },
            { text: 'Influenciadores Atacado', value: '17' },
            { text: 'Influenciadores Atacado Vendedores', value: '18' },
          ]
        },
      },
      {
        propText: 'Email*',
        propName: 'email',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: (e) => e?.split('@').length >= 2, message: 'Email Invalido' }]
      },
      {
        propText: 'CPF*',
        propName: 'CPF',
        parse: (v) => v.match(/\d/g)?.slice(0, 11).map((c, i, l) => c).join(''),
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: validateCPF, message: 'CPF invalido' }]
      },
      {
        propText: 'Afiliado Pai (id)*',
        propName: 'parents',
      },
      {
        propText: 'Marcas',
        propName: 'brands',
        component: Dropdown,
        componentProps: {
          multiple: true,
          options: [
            { text: 'ANIMALE', value: '1' },
            { text: 'FARM', value: '2' },
            { text: 'FABULA', value: '5' },
            { text: 'FOXTON', value: '7' },
            { text: 'CRIS BARROS', value: '9' },
            { text: 'OUTLET', value: '6' },
            { text: 'MARIA FILO', value: '15' },
            { text: 'BYNV', value: '16' },
            { text: 'HERING', value: '17' },
            { text: 'DZARM', value: '20' },
          ]
        },
      },
      {
        propText: 'Nome Completo',
        propName: 'full_name',
      },
      {
        propText: 'Código Vendedor',
        propName: 'vendor_code',
      },
      {
        propText: 'Data de Nascimento',
        propName: 'birth_date',
        parse: (v) => v.match(/\d/g)?.slice(0, 8).map((c, i, l) => {
          if (i === 2) return '/' + c;
          if (i === 4) return '/' + c;
          return c;
        }).join(''),
      },
      {
        propText: 'Celular',
        propName: 'phone',
      },
      {
        propText: 'PIS',
        propName: 'PIS',
      },
      {
        propText: 'CEP',
        propName: 'CEP',
      },
      {
        propText: 'Cidade',
        propName: 'city',
      },
      {
        propText: 'Estado',
        propName: 'state',
        component: Dropdown,
        componentProps: {
          options: [
            { text: 'Acre', value: 'AC' },
            { text: 'Alagoas', value: 'AL' },
            { text: 'Amapá', value: 'AP' },
            { text: 'Amazonas', value: 'AM' },
            { text: 'Bahia', value: 'BA' },
            { text: 'Ceará', value: 'CE' },
            { text: 'Distrito Federal', value: 'DF' },
            { text: 'Espírito Santo', value: 'ES' },
            { text: 'Goiás', value: 'GO' },
            { text: 'Maranhão', value: 'MA' },
            { text: 'Mato Grosso', value: 'MT' },
            { text: 'Mato Grosso do Sul', value: 'MS' },
            { text: 'Minas Gerais', value: 'MG' },
            { text: 'Pará', value: 'PA' },
            { text: 'Paraíba', value: 'PB' },
            { text: 'Paraná', value: 'PR' },
            { text: 'Pernambuco', value: 'PE' },
            { text: 'Piauí', value: 'PI' },
            { text: 'Rio de Janeiro', value: 'RJ' },
            { text: 'Rio Grande do Norte', value: 'RN' },
            { text: 'Rio Grande do Sul', value: 'RS' },
            { text: 'Rondônia', value: 'RO' },
            { text: 'Roraima', value: 'RR' },
            { text: 'Santa Catarina', value: 'SC' },
            { text: 'São Paulo', value: 'SP' },
            { text: 'Sergipe', value: 'SE' },
            { text: 'Tocantins', value: 'TO' }
          ]
        },
      },
      {
        propText: 'Endereço',
        propName: 'street',
      },
      {
        propText: 'Número (endereço)',
        propName: 'street_number',
      },
      {
        propText: 'Complemento (endereço)',
        propName: 'complement',
      },
      {
        propText: 'Codigo do Banco',
        propName: 'bank_number',
      },
      {
        propText: 'Agencia',
        propName: 'bank_agency',

      },
      {
        propText: 'Conta',
        propName: 'bank_account',
      }
    ]
  };

  return (
    <>
      <SomaForm {...Opts} />
      <Modal modalName="Afiliado Criado" open={Object.keys(afiliadoCriado).length > 0} onClose={() => setAfiliadoCriado({})}>
        <Grid item style={{ paddingBottom: "10px" }}>
          {
            Object.entries(afiliadoCriado).map(([key, value]) => {
              return <Typography>
                <b>{key}</b>: {value}
              </Typography>
            })
          }
        </Grid>
      </Modal>
    </>
  );
};

export default FormAfiliado;
