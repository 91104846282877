import React, { useState, useEffect } from 'react';
import SomaTable from '../../../../components/SomaStandard/SomaTableOld';
import { pageList } from '../../../../helpers/webHelper';

const InfosDetalhar = ({ show, data, columns, totalPage }) => {
    const [result, setResult] = useState(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (show) {
            setResult(data ? pageList(data, page, rowsPerPage) : [])
        }
    }, [data, page, rowsPerPage, show]);

    return (
        <SomaTable size="small" results={result} columns={columns}
            {...{ page, setPage, rowsPerPage, setRowsPerPage, total: totalPage }} />
    );
};

export default InfosDetalhar;