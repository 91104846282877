import React from 'react';
import { v4 } from 'uuid'
import './SomaTableBody.css';
import SomaTableBodyItems from '../SomaTableBodyItems/SomaTableBodyItems';

const SomaTableBody = ({ data, checked, hasButton, columns, infoLine, lineHighlighted, highlightedColor }) => (
  <tbody className="tbody__somatable--conteiner">
    {data.map((res, i) => (
      <SomaTableBodyItems
        key={v4()}
        checked={checked}
        hasButton={hasButton}
        columns={columns}
        res={res}
        infoLine={infoLine}
        lineHighlighted={lineHighlighted}
        highlightedColor={highlightedColor}
      />
    ))}
  </tbody>
);

export default SomaTableBody;
