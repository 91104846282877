import React, { useState } from 'react'
import './Nfe.css'

import { ReactComponent as RightArrow } from '../../../../assets/images/icons/right-arrow-productItem.svg';

import ProductsList from '../../../../components/productsList/ProductsList';
import FooterButton from '../../../../components/FooterButton/footerButton';
import SeparateLine from '../../../../components/SeparateLine/SeparateLine';
import SomaTextEditor from '../../../../components/SomaStandard/SomaTextEditor/SomaTextEditor';

import ButtonBack from './ButtonBack';
import CompararGrades from '../../../../Utils/CompararGrades';
import Spinner from '../../../../components/Spinner/Spinner';
import useNfeCaixaFlow from "../../../../Hooks/useNfeReducer";
import CardBox from '../../../../components/CardBox/Card';
import ProgressBar from '../../../../components/ProgressBar/ProgressBar';
import DivergencyTag from '../../../../components/Tag/Tag';
import ConverteParaIcone from '../../../../components/SomaStandard/TableComponents/ConverteParaIcone';
import Modal from '../../../../components/Modal/Modal';
import CardTag from '../../../../components/CardTag/CardTag';

import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import useUser from '../../../../Hooks/UserContext';

const NfeBoxes = () => {
    const { state, dispatch, salvarMensagem, registerKeepProduct, finishRecebimento } = useNfeCaixaFlow();
    const user = useUser();
    const caixas = state.data?.CAIXAS || null;
    const entrega = state.data?.ROMANEIO;
    const recFinished = state.data?.VERIFICADO;

    const [modalState, setModalState] = useState('none')
    const [current, setCurrent] = useState(0)
    const [divergenceData, setDivergenceData] = useState([])
    const [productsWithExtraDivergency, setProductsWithExtraDivergency] = useState([])
    const [finishButtonDisabled, setFinishButtonDisabled] = useState(true)
    const [keepProduct, setKeepProduct] = useState(false);
    const [message, setMessage] = useState("");

    const handleScroll = (e) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (scrollTop + clientHeight + 10 >= scrollHeight) setFinishButtonDisabled(false);
    };

    const handleCurrent = (act) => {
        let newCurrent = 0;

        if (act === "next") {
            if (current === productsWithExtraDivergency.length - 1) {
                setModalState("finish")
                setCurrent(0);
                return;
            } else {
                newCurrent = current + 1;
            }
        } else {
            newCurrent = current === 0 ? current : current - 1;
        }

        setCurrent(newCurrent);
        setKeepProduct(divergenceData[productsWithExtraDivergency[newCurrent]]?.querPeca);
    }

    const handleFinish = async () => {
        setModalState("isLoading")
        !!message && await salvarMensagem(entrega?.toString(), message, user.login, null, true)
        await registerKeepProduct(divergenceData)
            .then(() => {
                setModalState("conferenceFinished")
            }).catch(() => {
                setModalState("none")
            })
    }

    const onClickBackButton = () => dispatch({ type: 'goBack' })

    const onClickFooterButton = () => {
        setModalState("keepProduct")
        setKeepProduct(false)

        const divergences = caixas
            .map((p) =>
                p.divergenciaTag.length
                    ? p.produtos.map((g) =>
                        CompararGrades(g.divergencia, g.grade).map((tag) => ({
                            entrega: entrega,
                            chaveNfe: g.chave_nfe,
                            nfSaida: g.nf_saida,
                            serieNf: g.serie_nf,
                            produto: `${g.produto} ${g.desc}`,
                            pedido: g.pedido,
                            marca: g.marca,
                            idMarca: g.idMarca,
                            cor: g.cor,
                            caixa: p.caixa,
                            item: g.item,
                            divergencia: tag[0],
                            tam: tag[1],
                            qtde: tag[2],
                            querPeca: false,
                            caixaViolada: p.caixaViolada
                        }))
                    )
                    : null
            )
            .flat(2)
            .filter((e) => e !== null);

        if (divergences.length === 0) {
            setFinishButtonDisabled(false)
            setModalState("finish")
        } else {
            const divergencesWithExtra = divergences
                .map((e, id) => (e.divergencia !== 'falta' ? id : null))
                .filter((e) => e !== null);

            divergences.length === 1 && setFinishButtonDisabled(false);
            setDivergenceData(divergences)

            if (divergencesWithExtra.length === 0) {
                setModalState("finish")
            } else {
                setProductsWithExtraDivergency(divergencesWithExtra)
            }
        }
    }

    const finishBoxes = caixas.filter(caixa => caixa.caixaStatus && caixa.caixaStatus !== "Aguardando conferência")

    const progressContent = (
        <>
            Seu Progresso<br />
            <b style={{ color: '#333333' }}>
                {Math.floor((!recFinished ? finishBoxes.length : caixas.length) * 100 / caixas.length)}% Concluído
            </b>
        </>
    )

    const divergencyTagContent = (caixa) => (
        <div style={{ display: 'flex' }}>
            {caixa.divergenciaTag.includes("falta") && (<DivergencyTag name="Falta" color="#F3AF00" />)}
            {caixa.divergenciaTag.some(tag => ["extra", "sobra"].includes(tag)) && (<DivergencyTag name="Sobra" color="#2596DA" />)}
        </div>
    )

    const statusCaixaContent = ({ key, value, color }) => (
        <div style={{ fontSize: '0.8125rem', color: '#333', fontFamily: 'flama-basic', marginBottom: '5px' }}>
            Status: <span style={{ fontFamily: 'flama-medium', color: color }}>{value}</span>
        </div>
    )

    const modalKeepProductContent = ((divergencia, current) => {
        return (
            <div>
                <CardTag tagName="sobra" productName={divergencia?.produto} isFinish={false}>
                    <p>Marca: {divergencia?.marca}</p>
                    <p>Tam: {divergencia?.tam}</p>
                    <p>Qtde: {divergencia?.qtde}</p>
                </CardTag>

                <p className="textModalAlignCenter">Você deseja manter o produto acima, com a divergência de sobra?</p>

                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="opcao"
                        name="opcao"
                        value={keepProduct}
                        onChange={(e) => {
                            const aux = divergenceData
                            aux[productsWithExtraDivergency[current]].querPeca = e.target.value === "true"
                            setKeepProduct(e.target.value === "true")
                            setDivergenceData(aux)
                        }}
                    >
                        <FormControlLabel value={true} control={<Radio color="default" className='formCheckboxRadio' />} label="Sim" />
                        <FormControlLabel value={false} control={<Radio color="default" className='formCheckboxRadio' />} label="Não" />
                    </RadioGroup>
                </FormControl>
            </div>
        )
    })

    const list = caixas.map(caixa => {
        const options = [
            { key: "Finalizado com divergência", value: "Finalizado com divergência", color: "#B82814" },
            { key: "Finalizado", value: "Finalizado", color: "#138267" },
        ];

        const isFinishRecNotBoxes = typeof caixa.caixaStatus === "undefined" && recFinished; //Mapea fluxo anterior, de caixas que não foram finalizadas junto com o recebimento
        const includeDivergency = isFinishRecNotBoxes && caixa?.divergenciaTag?.length;

        const defaultValue = !isFinishRecNotBoxes
            ? { key: "Aguardando conferência", value: "Aguardando conferência", color: "#E3BE25" }
            : includeDivergency
                ? { key: "Finalizado com divergência", value: "Finalizado com divergência", color: "#B82814" }
                : { key: "Finalizado", value: "Finalizado", color: "#138267" };

        return {
            title: {
                name: 'Caixa',
                Icon: <ConverteParaIcone size={15} options={options} defaultValue={defaultValue}>{caixa?.caixaStatus}</ConverteParaIcone>,
                id: caixa.caixa,
            },
            quantity: {
                number: caixa.qtdeItems,
                name: 'itens',
                Icon: <RightArrow />,
            },
            infos: [
                { value: statusCaixaContent((options.find(opt => opt.key === caixa?.caixaStatus) || defaultValue)) },
                caixa?.divergenciaTag?.length && { value: divergencyTagContent(caixa) },
            ],
            linkColumn: {
                route: `./nfeBoxItems/${caixa.caixa}`,
                click: () => dispatch({ type: 'showNfeBoxItems', boxId: caixa.caixa })
            }
        }
    });

    const propsCardBox = {
        title: { name: 'Entrega', id: entrega },
        infos: [
            {
                left: progressContent,
                componentProps: { addClassName: 'boxInfoItemOneChild' }
            },
            {
                left:
                    <ProgressBar
                        total={caixas.length}
                        progress={!recFinished ? finishBoxes.length : caixas.length}
                        description={<><b>{!recFinished ? finishBoxes.length : caixas.length} de {caixas.length}</b> caixas conferidas</>}
                    />,
                componentProps: { addClassName: 'boxInfoItemOneChild' }
            }
        ],
    }

    const textModal = [
        user?.features?.recebimento ? "Ao finalizar o recebimento, não será possível efetuar mais alterações ou apontar divergências dessa entrega. " : "",
        caixas.length !== finishBoxes.length && (
            <>
                <strong>Existem {caixas.length - finishBoxes.length} caixas pendentes</strong>
                {" de conferência que serão marcadas como concluídas ao término deste processo."}
            </>
        )
    ]

    const modalProps = {
        none: { open: false },
        keepProduct: {
            modalName: "Manter o produto?",
            open: modalState === "keepProduct",
            backName: "Voltar",
            actionName: "Continuar",
            onBack: () => handleCurrent("back"),
            onClose: () => { setModalState("none"); setCurrent(0) },
            action: () => handleCurrent("next"),
            current: current + 1,
            total: productsWithExtraDivergency?.length,
            notInputs: true,
        },
        finish: {
            modalName: "",
            closeName: "Revisar",
            onClose: () => { setModalState("none"); setFinishButtonDisabled(true) },
            actionName: "Finalizar Recebimento",
            open: modalState === "finish",
            action: handleFinish,
            finishButtonDisabled: finishButtonDisabled,
        },
        conferenceFinished: {
            modalName: "CONFERÊNCIA FINALIZADA",
            open: modalState === "conferenceFinished",
            actionName: "Sair",
            onClose: () => finishRecebimento(),
            action: () => finishRecebimento(),
            notInputs: true,
        },
        isLoading: { open: true },
    };

    const modalContent = {
        keepProduct: modalKeepProductContent(divergenceData[productsWithExtraDivergency[current]], current),
        finish: (
            <>
                <div className='divCardTagContainerScroll' onScroll={handleScroll}>
                    <h1 className="h1__modaltitle--text"> Tem certeza? </h1>
                    <p className="textModalAlignCenter">{textModal}</p>
                    {divergenceData.length > 0 && <p>Divergência(s) nessa entrega:</p>}
                    {divergenceData.map((e) => (
                        <CardTag
                            tagName={e.divergencia}
                            productName={e.produto}
                            isFinish={true}
                            chosenOption={e.divergencia === "falta" ? e.caixaViolada : e.querPeca}
                        >
                            <p>Marca: {e.marca}</p>
                            <p>Tam: {e.tam}</p>
                            <p>Qtde: {e.qtde}</p>
                            <p>Caixa: {e.caixa}</p>
                        </CardTag>
                    ))}
                    {divergenceData.length > 0 &&
                        <>
                            <p>Observações:</p>
                            <SomaTextEditor setText={setMessage} initialValue={message} />
                        </>
                    }
                </div>
            </>
        ),
        conferenceFinished: (
            <p className="textModalAlignCenter">
                {!user?.features?.recebimento ? "Sua conferência foi finalizada com sucesso." : "Sua conferência foi finalizada com sucesso e iniciamos a análise da(s) sua(s) divergência(s). Fique atenta para mais atualizações em breve."}
            </p>
        ),
        isLoading: <Spinner />
    };

    return (
        <>
            <Modal {...modalProps[modalState]}>
                {modalContent[modalState]}
            </Modal>

            <div className='container'>
                <CardBox {...propsCardBox} />
                <ButtonBack onClick={onClickBackButton} />
                <br />
                <SeparateLine />
                <ProductsList list={list} />
            </div>

            {!recFinished ? (
                <FooterButton onClick={onClickFooterButton}>
                    FINALIZAR RECEBIMENTO
                </FooterButton>
            ) : <></>}
        </>
    )
}

export default NfeBoxes