import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { authFetch } from '../../../helpers/authHelper';

import useAffiliate from '../../../Hooks/AffiliateContext'

import SomaForm from '../../../components/SomaStandard/SomaForm';
import Dropdown from '../../../components/SomaStandard/FormComponents/Dropdown';

const FormVendedorEdit = (props) => {
  const [state, setState] = useState({ ...props.data })
  const { acceptedNewContract } = useAffiliate()

  const parseCep = (cep) => cep.match(/\d/g)?.slice(0, 8).map((c, i, l) => { if (i === 2) return '.' + c; if (i === 5) return '-' + c; return c; }).join('');
  const parseCPF = (cpf) => cpf.match(/\d/g)?.slice(0, 11).map((c, i) => { if (i === 3 || i === 6) return '.' + c; if (i === 9) return '-' + c; return c; }).join('');
  const validateCPF = (e) => {
    if (!e) return false;
    let dig = e.match(/\d/g).map(d => parseInt(d));
    let dv = [
      (dig?.slice(0, 9).map((n, i) => n * (10 - i)).reduce((_, e) => _ + e) * 10) % 11 % 10,
      (dig?.slice(0, 10).map((n, i) => n * (11 - i)).reduce((_, e) => _ + e) * 10) % 11 % 10
    ];
    let rep = !dig?.slice(0, 9).map((e, i, l) => e === l[0]).reduce((_, e) => _ && e);

    return dig[9] === dv[0] && dig[10] === dv[1] && rep
  }

  let cpfLookupEvent = 0
  let CepLookupEvent = 0;
  const cepLookup = async (cep) => {
    const ans = await fetch(`http://viacep.com.br/ws/${cep}/json/`).then(r => r.json())
    setState({ ...state, city: ans.localidade, state: ans.uf, CEP: parseCep(cep) })
  }


  const Opts = {
    state: [state, setState],
    setResults: async (result) => {
      const lastValueData = props.data
      const response = await authFetch('/v1/afiliados/Editarvendedor', 'POST', {acceptedNewContract, result, lastValueData})
      const afiliado = await response.json()
     
      if (afiliado.success) {
        toast.success("Editado com sucesso!", { theme: "colored" })
      }
      else {
        toast.error(afiliado.messages[0], { theme: "colored" })
      }
      props.reload();
    },
    disable: {
      CPF: true
    },
    columns: [
      {
        propText: 'Nome Completo*',
        propName: 'full_name',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: (e) => e?.split(' ').length >= 2, message: 'Nome e Sobrenome' }]
      },
      {
        propText: 'Email*',
        propName: 'email',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }, { test: (e) => e?.split('@').length >= 2, message: 'Email Invalido' }]
      },
      {
        propText: 'CPF*',
        propName: 'CPF',
        parse: (cpf) => {
          if (cpf.match(/\d/g)?.length === 11) {
            clearTimeout(cpfLookupEvent)
            cpfLookupEvent = setTimeout(() => cpf.match(/\d/g).join(''), 300)
          }
          return parseCPF(cpf)
        },
        validate: [
          { test: (e) => e, message: 'Obrigatorio' },
          { test: validateCPF, message: 'CPF invalido' }
        ]
      },
      {
        propText: 'Telefone',
        propName: 'phone',
        parse: (v) => v.match(/\d/g)?.slice(0, 10).map((c, i, l) => {
          if (i === 0) return '(' + c;
          if (i === 2) return ')' + c;
          if (i === 6) return '-' + c;
          return c;
        }).join('')
      },
      {
        propText: 'Celular',
        propName: 'phone_2',
        parse: (v) => v.match(/\d/g)?.slice(0, 11).map((c, i, l) => {
          if (i === 0) return '(' + c;
          if (i === 2) return ')' + c;
          if (i === 7) return '-' + c;
          return c;
        }).join('')
      },
      {
        propText: 'CEP',
        propName: 'CEP',
        parse: (v) => {
          if (v.match(/\d/g)?.length === 8) {
            clearTimeout(CepLookupEvent)
            CepLookupEvent = setTimeout(() => cepLookup(v.match(/\d/g).join('')), 300)
          }
          return parseCep(v)
        }
      },
      {
        propText: 'Cidade*',
        propName: 'city',
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      },
      {
        propText: 'Estado*',
        propName: 'state',
        component: Dropdown,
        componentProps: {
          options: [
            { text: 'Acre', value: 'AC' },
            { text: 'Alagoas', value: 'AL' },
            { text: 'Amapá', value: 'AP' },
            { text: 'Amazonas', value: 'AM' },
            { text: 'Bahia', value: 'BA' },
            { text: 'Ceará', value: 'CE' },
            { text: 'Distrito Federal', value: 'DF' },
            { text: 'Espírito Santo', value: 'ES' },
            { text: 'Goiás', value: 'GO' },
            { text: 'Maranhão', value: 'MA' },
            { text: 'Mato Grosso', value: 'MT' },
            { text: 'Mato Grosso do Sul', value: 'MS' },
            { text: 'Minas Gerais', value: 'MG' },
            { text: 'Pará', value: 'PA' },
            { text: 'Paraíba', value: 'PB' },
            { text: 'Paraná', value: 'PR' },
            { text: 'Pernambuco', value: 'PE' },
            { text: 'Piauí', value: 'PI' },
            { text: 'Rio de Janeiro', value: 'RJ' },
            { text: 'Rio Grande do Norte', value: 'RN' },
            { text: 'Rio Grande do Sul', value: 'RS' },
            { text: 'Rondônia', value: 'RO' },
            { text: 'Roraima', value: 'RR' },
            { text: 'Santa Catarina', value: 'SC' },
            { text: 'São Paulo', value: 'SP' },
            { text: 'Sergipe', value: 'SE' },
            { text: 'Tocantins', value: 'TO' }
          ]
        },
        validate: [{ test: (e) => e, message: 'Obrigatorio' }]
      }
    ]
  }

  useEffect(() => {
    if (state.CEP) setState(prevState => ({ ...prevState, CEP: parseCep(prevState.CEP) }))
    if (state.CPF) setState(prevState => ({ ...prevState, CPF: parseCPF(prevState.CPF) }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <SomaForm {...Opts} />
    </>
  )
}

export default FormVendedorEdit;