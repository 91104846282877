import * as pdfFonts from '../../../../temp/vfs_fonts';
import * as pdfMake from 'pdfmake/build/pdfmake';

const makePdf = (nfeBox) => {
    const groupedByMarca = nfeBox.produtos.reduce((acc, cur) => {
        (acc[cur.marca.trim()] = acc[cur.marca.trim()] || []).push(cur);
        return acc;
    }, {});

    const somaSize = (grade) => {
        let sum = 0
        Object.entries(grade).map(size => sum += size[1])
        return sum
    }

    const dataEmissao = new Date(nfeBox.pedido?.EMISSAO).toLocaleDateString();
    const formatandoCPFeCNPJ = nfeBox.pedido?.CGC_CPF.trim().length === 14 ?
        nfeBox.pedido?.CGC_CPF.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') :
        nfeBox.pedido?.CGC_CPF.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    const valorTotal = parseFloat(nfeBox.pedido?.TOT_VALOR_ORIGINAL).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });

    const tableLines = (keys, produtos) => {
        const lines = []
        const linesWidth = []

        if (keys.length === 0) return null

        lines.push([
            ...(nfeBox.pdfPedido
                ? []
                : [{ text: 'Pedido', style: 'headerRow' }]
            ),
            { text: 'Rede', style: 'headerRow' },
            { text: 'Produto', style: 'headerRow' },
            { text: 'Cor', style: 'headerRow' },
            ...(nfeBox.pdfPedido
                ? [{ text: 'Preço', style: 'headerRow' }, { text: 'Valor', style: 'headerRow' }]
                : []
            ),
            { text: 'Qtde', style: 'headerRow' },
            ...keys
        ])

        produtos.forEach(prod => {
            const { pedido, marca, produto, desc, cor, descCor, grade, preco, qntOriginal } = prod

            if (keys.some(key => grade[key.text])) {
                const row = [
                    ...(nfeBox.pdfPedido
                        ? []
                        : [{ text: pedido.trim(), style: 'bodyRow' }]
                    ),
                    { text: marca?.trim(), style: 'bodyRow' },
                    { text: `${produto.trim()} - ${desc.trim()}`, style: 'bodyRow' },
                    { text: `${cor.trim()} - ${descCor.trim().split('_')[0]}`, style: 'bodyRow' },
                    ...(nfeBox.pdfPedido
                        ? [
                            { text: preco?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), style: 'bodyRow' },
                            { text: (preco * qntOriginal).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }), style: 'bodyRow' }
                        ]
                        : []
                    ),
                    { text: somaSize(grade), style: 'bodyRow' },
                    ...keys.map(key => ({ text: grade[key.text] || '', style: 'bodyRow' }))
                ];
                lines.push(row);
            }
        });

        for (let i = 0; i < lines[0].length; i++) {
            if (lines[0][i].text === 'Produto' || lines[0][i].text === 'Cor') {
                linesWidth.push('*')
            } else {
                linesWidth.push('auto')
            }
        }

        return { lines, linesWidth }
    }

    const allTables = Object.entries(groupedByMarca).map(([marca, produto]) => {
        const size = []
        const sizeUn = []
        const numbering = []

        const measurementsForBrand = [...new Set(produto.flatMap(item => Object.keys(item.grade)))];
        const measurementsWithValues = measurementsForBrand.filter(measurement => {
            return produto.some(item => item.grade[measurement] > 0);
        });
        measurementsWithValues.forEach(measure => {
            if (isNaN(measure)) {
                if (measure === 'U' || measure === 'UN') {
                    sizeUn.push({ text: measure, style: 'headerRow' });
                } else {
                    size.push({ text: measure, style: 'headerRow' });
                }
            } else {
                numbering.push({ text: measure, style: 'headerRow' });
            }
        });

        const tableSize = tableLines(size, produto)
        const tableSizeUn = tableLines(sizeUn, produto)
        const tableNumbering = tableLines(numbering, produto)

        return [
            !nfeBox.pdfPedido && measurementsWithValues.length > 0 ? {
                text: `Marca: ${marca?.trim()}`,
                color: "#3A3A3A",
                margin: [0, 50, 0, 6]
            } : {},
            tableSize && {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: tableSize.linesWidth,
                    body: tableSize.lines
                },
                text: ``, margin: [0, 0, 0, 30]
            },
            tableSizeUn && {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: tableSizeUn.linesWidth,
                    body: tableSizeUn.lines
                },
                text: ``, margin: [0, 0, 0, 30]
            },
            tableNumbering && {
                layout: "lightHorizontalLines",
                table: {
                    headerRows: 1,
                    widths: tableNumbering.linesWidth,
                    body: tableNumbering.lines
                }
            }
        ];
    });

    const docDefinition = {
        pageSize: 'A3',
        pageOrientation: 'landscape',
        pageMargins: [20, 30, 20, 60],
        content: [
            nfeBox.pdfPedido ?
                { text: `Pedido: ${nfeBox.pedido.PEDIDO}`, style: 'title' }
                :
                { text: `Romaneio - Caixa: ${nfeBox.caixa}`, style: 'title' },
            nfeBox.pdfPedido ?
                [
                    { text: 'Dados do pedido:', style: 'subTitleValue' },
                    { text: [{ text: `CLIENTE: `, style: 'subTitle' }, { text: `${nfeBox.pedido?.COD_CLIENTE} ${nfeBox.pedido?.RAZAO_SOCIAL}`, style: 'subTitleValue' }] },
                    { text: [{ text: `CNPJ/CPF: `, style: 'subTitle' }, { text: `${formatandoCPFeCNPJ}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Marca: `, style: 'subTitle' }, { text: `${nfeBox.pedido?.MARCA}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Coleção: `, style: 'subTitle' }, { text: `${nfeBox.pedido?.COLECAO}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Data de emissão: `, style: 'subTitle' }, { text: `${dataEmissao}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Tabela de preço: `, style: 'subTitle' }, { text: `${nfeBox.pedido.Tabela}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Quantidade total: `, style: 'subTitle' }, { text: `${nfeBox.pedido?.TOT_QTDE_ORIGINAL}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Valor total: `, style: 'subTitle' }, { text: `${valorTotal}`, style: 'subTitleValue' }] },
                    { text: [{ text: `Condição de pagamento: `, style: 'subTitle' }, { text: `${nfeBox.pedido.DESC_COND_PGTO}`, style: 'subTitleValue' }] },
                    { text: ``, margin: [0, 10] },
                    ...allTables
                ]
                :
                [...allTables]
        ],
        styles: {
            title: {
                alignment: 'center',
                fontSize: 18,
                bold: true,
                margin: [0, 0, 0, 20]

            },
            subTitle: {
                fontSize: 14,
                bold: true,
                margin: [0, 10],
                color: "#3A3A3A"
            },
            subTitleValue: {
                fontSize: 14,
                bold: true,
                margin: [0, 10],
                color: '#707070',
            },
            headerRow: {
                fillColor: '#d9d9d9',
                margin: [10, 10, 10, 10],
                bold: true
            },
            bodyRow: {
                margin: [10, 5, 10, 5],
            }
        },
    }


    const fileName = nfeBox.pdfPedido ? `Pedido ${nfeBox.pedido.PEDIDO?.trim()}` : `Romaneio - Caixa ${nfeBox.caixa}`;

    pdfMake.createPdf(docDefinition, undefined, undefined, pdfFonts.pdfMake.vfs).download(fileName);

}

export default makePdf
