import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import { encodeFilter } from '../../../helpers/webHelper';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import useUser from '../../../Hooks/UserContext';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import TableSettings from '../../../components/TableSettings/TableSettings';

const Descontos = (props) => {
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState("");
  const [status, setStatus] = useState('result');
  const user = useUser();

  let Opts = {
    endpoint: "/v1/desconto",
    columns: [
      {
        propName: "TIPO_VENDA",
        componentProps: {
          colunas: [
            { propName: "TIPO_VENDA", propText: "Marca ", highlighted: true },
            { propName: "VALOR_DESCONTO_ORIGINAL", propText: "Valor Original", component: ConverteDinheiro },
            { propName: "VALOR_DESCONTO_FATURADO", propText: "Faturado", component: ConverteDinheiro },
            { propName: "VALOR_DESCONTO_SALDO", propText: "Saldo", component: ConverteDinheiro },
            { propName: "DATA_APROVACAO", propText: "data de Aprovação" },
            { propName: "MOTIVO_DESCONTO", propText: "Motivo do Desconto" }
          ]
        }
      }
    ],
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      {
        propName: "CLIENTE_ATACADO[lk]", propText: "Cliente",
        format: val => `%${val}%`, show: { filter: user.features.repre === true }
      },
      {
        propName: "TIPO_VENDA[lk]", propText: "Marca",
        format: val => `%${val}%`
      },
      { propName: "DATA_APROVACAO", component: ToFrom, propText: "Aprovação", isOnlyDate: true, componentProps: { component: DatePicker } },
      { propName: "VALOR_DESCONTO_ORIGINAL", component: ToFrom, propText: "Desc. Original" },
      { propName: "VALOR_DESCONTO_SALDO", component: ToFrom, propText: "Desc. Saldo" },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };


  useEffect(() => {
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`)).json();
        setResults(json.results); setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
      } catch (err) {
        console.log(err);
        setResults(undefined);
        setStatus('error');
      }
    })();

  }, [Opts.endpoint, page, rowsPerPage, filter]);

  return (
    <>
      <TableSettings>
        <div></div>
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <SomaTable {...Opts} />
      </SomaStatus>
    </>
  );
};

export default Descontos;
