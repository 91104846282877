import React from 'react';
import './TableButton.css';

const TableButton = ({ highlighted, disabled, clicked, children }) => (
  <button
    className={`button__TableButton--conteiner ${highlighted ? 'button__TableButton--highlighted' : ''}`}
    disabled={disabled}
    onClick={clicked}
  >
    {children}
  </button>
);

export default TableButton;
