import React, { useState, useRef } from 'react';
import { stateToHTML } from 'draft-js-export-html';
import { Editor, EditorState, RichUtils, ContentState, convertFromHTML } from 'draft-js';

import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';

import './SomaTextEditor.css';
import 'draft-js/dist/Draft.css';

const setInicialValue = (initialValue) => {
  if (initialValue) {
    const blocksFromHTML = convertFromHTML(initialValue);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );
    return EditorState.createWithContent(contentState);
  }
  
  return EditorState.createEmpty();
};

const SomaTextEditor = ({ placeholder, setText, initialValue}) => {
  const editorRef = useRef(null);
  const [isTyping, setIsTyping] = useState(false);
  const [editorState, setEditorState] = useState(()=>setInicialValue(initialValue));  

  const getCurrentText = () => {
    const text = stateToHTML(editorState.getCurrentContent());
    return text;
  };
  
  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
    const text = getCurrentText();
    setText(text);
  };

  const toggleInlineStyle  = (style) => {
    const newEditorState = RichUtils.toggleInlineStyle(editorState, style);
    setEditorState(newEditorState);
    editorRef.current.focus();
  };

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
    setIsTyping(true)
  };

  const currentStyle = editorState.getCurrentInlineStyle();

  return (
    <div className='div__somatexteditor--container'>
      <div className='div__somatexteditor--container-buttons'>
        <button
          className={`button__somatexteditor button__somatexteditor--bold ${currentStyle.has('BOLD') && 'button__somatexteditor--active'}`}
          onMouseDown={(e) => { e.preventDefault(); toggleInlineStyle ('BOLD')}}
        >
          B
        </button>
        <button
          className={`button__somatexteditor button__somatexteditor--italic ${currentStyle.has('ITALIC') && 'button__somatexteditor--active'}`}
          onMouseDown={(e) => {e.preventDefault();toggleInlineStyle ('ITALIC')}}
        >
          I
        </button>
        <button
          className={`button__somatexteditor button__somatexteditor--underline ${currentStyle.has('UNDERLINE') && 'button__somatexteditor--active'}`}
          onMouseDown={(e) => {e.preventDefault();toggleInlineStyle ('UNDERLINE')}}
        >
          U
        </button>
        <button
          className={`button__somatexteditor button__somatexteditor--strike-through ${currentStyle.has('STRIKETHROUGH') && 'button__somatexteditor--active'}`}
          onMouseDown={(e) => {e.preventDefault();toggleInlineStyle ('STRIKETHROUGH')}}
        >
          S
        </button>
        <button className="button__somatexteditor" onClick={() => toggleBlockType('unordered-list-item')}>
          <FormatListBulletedIcon fontSize="small" />
        </button>
        <button className="button__somatexteditor" onClick={() => toggleBlockType('ordered-list-item')}>
          <FormatListNumberedIcon fontSize="small" />
        </button>
      </div>

      <Editor
        editorState={editorState}
        onChange={handleChange}
        placeholder={!isTyping && (placeholder || 'Digite sua mensagem')}
        ref={editorRef}
      />
    </div>
  );
};

export default SomaTextEditor;
