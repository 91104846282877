import React, { useState } from 'react'
import { v4 } from 'uuid'
import './SomaTableBodyItems.css'

const SomaTableBodyItems = props => {
    const [show, setShow] = useState(false)
    const [itemActive, setItemActive] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)

    const getRowHighlightedStyle = () => props?.lineHighlighted?.(props.res) && props?.highlightedColor ? { backgroundColor: props?.highlightedColor } : {}
    
    return (
        <>
            <tr 
                style={getRowHighlightedStyle()}
                className={`tr__somatablebody--conteiner ${props?.lineHighlighted?.(props.res) ? "tr_lineHighlighted" : ""} ${itemActive ? "tr__somatablebodyactive--conteiner" : isDisabled && "tr__somatablebodydisabled--conteiner"}`}
            >
                {
                    props.columns.map((col, j) => {
                        if (!col) return null;
                        if (col.show === false) return null;
                        const classes = [props.hasButton ? "td__item--conteiner fixedButton" : "td__item--conteiner"];
                        if (col.highlighted) classes.push("td__item--conteinerhighlighted");
                        if (col.isButton) classes.push("td__item--conteinerbutton");
                        if (col?.columnHighlighted?.(props.res)) classes.push("td__columnHighlighted");
                        return (
                            col.component ?
                                <td key={v4()} className={classes.join(' ')}><col.component line={props.res} col={col} show={show} setChecked={setItemActive} isDisabled={setIsDisabled} setShow={setShow}  {...col.componentProps}>{props.res[col.propName] ? props.res[col.propName] : '-'}</col.component></td>
                                :
                                <td key={v4()} className={classes.join(' ')}>{props.res[col.propName] ? props.res[col.propName] : "-"}</td>
                        )
                    })}
            </tr>
            {
                props.infoLine && show ? <tr><td key={v4()} colSpan="100%"><props.infoLine.component line={props.res} show={show} setShow={setShow} {...props.infoLine.componentProps} /></td></tr>
                    : null
            }
        </>
    )
}

export default SomaTableBodyItems