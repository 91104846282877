import ReactGA from "react-ga4";

ReactGA.initialize("G-27VXCGSFDW");

const trackingPageview = (hitType, page, title) => {
    ReactGA.send({ hitType, page, title });
}

const trackingEvent = (category, action) => {
    ReactGA.event({ category, action });
}

export { trackingPageview, trackingEvent }
