import React from 'react';
import styles from './Footer.module.css';

const Footer = (props) => (
  <footer
    data-testid="footer"
    className={styles.footer}
    style={{
      justifyContent: props.end ? "flex-end" : "flex-start", ...props.style
    }}>
    <small>{new Date().getFullYear()}</small>
    <span>SomaLabs</span>
  </footer>
);

export default Footer;
