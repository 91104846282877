import React from 'react';
const Templater = ({ template, params = {} }) => {
    if(!template) return <></>;
    if(!params) return <></>
    const names = Object.keys(params);
    const vals = Object.values(params);
    // eslint-disable-next-line no-new-func
    return <div dangerouslySetInnerHTML={{ __html: (new Function(...names, `return \`${template}\`;`)(...vals)) }} />;
}

export default Templater