import React, { useReducer } from 'react'
import { useParams } from "react-router-dom";

import useNfeCaixaFlow from "../../../../Hooks/useNfeReducer";

import ButtonBack from './ButtonBack'
import DetailsItem from '../../../../components/DetailsBox/DetailsBoxItem';
import FooterButton from '../../../../components/FooterButton/footerButton';

import { ReactComponent as IconSave } from '../../../../assets/images/icons/save.svg'
import Spinner from '../../../../components/Spinner/Spinner';

const gradeReducer = (gradeState, gradeAction) => {
    const { type, key, value } = gradeAction
    switch (type) {
        case 'increment':
            return gradeState = { ...gradeState, [key]: gradeState[key] + value }
        case 'decrement':
            gradeState = gradeState[key] <= 0
                ?
                { ...gradeState, [key]: 0 }
                :
                { ...gradeState, [key]: gradeState[key] - value }
            return gradeState

        case 'typed':
            return gradeState = { ...gradeState, [key]: value }
        default:
            return gradeState
    }
}

const NfeItemEdit = () => {
    const { state, dispatch, ondivergencyNfe } = useNfeCaixaFlow();
    const { boxId, produtoId, corId, pedidoId, itemId } = useParams()

    const novoProduto = state.data?.novoProduto;
    // const pathItem = novoProduto?.PRODUTO?.trim() === produtoId?.trim() && novoProduto?.cor?.trim() === corId?.trim();

    const nfeBox = state.data?.CAIXAS.find(box => box.caixa === boxId);
    const caixa = nfeBox.caixa

    const gradeObject = novoProduto ? novoProduto.GRADE_ARRAY.reduce((acc, grade) => {
        acc[grade] = 0;
        return acc;
    }, {}) : {};

    const somaSize = (grade) => {
        let sum = 0
        Object.entries(grade).map(size => sum += size[1])
        return isNaN(sum) ? 0 : sum
    }

    const foundItem = nfeBox?.produtos.find(item => {
        return (
            item.produto.trim() === produtoId &&
            item.cor.trim() === corId &&
            item.pedido.trim() === pedidoId &&
            item.item.trim() === itemId
        )
    });

    const gradeReducerInitialValue = foundItem?.divergencia ? foundItem?.divergencia : foundItem?.grade;
    const [gradeState, gradeDispatch] = useReducer(gradeReducer, novoProduto ? gradeObject : gradeReducerInitialValue);

    const findNfeKey = (produtos, newProd) => {
        let uniqueNfes = {};
        let nfeDetails = [];
        const findProduct2Brand = produtos.find(p => p?.idMarca === newProd?.COD_MARCA.trim())

        if (findProduct2Brand === undefined) {
            for (const produto of produtos) {
                if (!uniqueNfes[produto.chave_nfe]) {
                    nfeDetails.push({
                        chave_nfe: produto.chave_nfe.trim(),
                        serie_nf: produto.serie_nf.trim(),
                        nf_saida: produto.nf_saida.trim(),
                        id_marca: produto.idMarca
                    });
                    uniqueNfes[produto.chave_nfe] = true;
                }
            }
            return nfeDetails[0];
        }
        return findProduct2Brand
    };

    let nfeBoxItem;
    if (novoProduto === undefined && foundItem) {
        nfeBoxItem = {
            chave_nfe: foundItem.chave_nfe,
            nf_saida: foundItem.nf_saida,
            serie_nf: foundItem.serie_nf,
            desc: foundItem.desc,
            produto: foundItem.produto.trim(),
            item: foundItem.item.trim(),
            pedido: foundItem.pedido,
            marca: foundItem.marca,
            idMarca: foundItem.idMarca,
            cor: foundItem.cor,
            descCor: foundItem.descCor.trim().split('_')[0],
            grade: foundItem.grade,
            gradeorig: foundItem.grade,
            divergencia: foundItem?.divergencia
                ? {
                    grid: foundItem.divergencia,
                    total: somaSize(foundItem.divergencia)
                }
                : null
        };
    } else if (foundItem === undefined && novoProduto) {
        const key2brand = findNfeKey(nfeBox.produtos, novoProduto);
        nfeBoxItem = {
            chave_nfe: key2brand.chave_nfe,
            nf_saida: key2brand.nf_saida,
            serie_nf: key2brand.serie_nf,
            desc: novoProduto.DESC_PRODUTO,
            produto: novoProduto.PRODUTO.trim(),
            pedido: '-',
            item: '-',
            marca: novoProduto.MARCA,
            idMarca: novoProduto.COD_MARCA,
            cor: novoProduto.cor,
            descCor: novoProduto.descCor.trim().split('_')[0],
            grade: novoProduto.GRADE,
            gradeorig: gradeObject,
            divergencia: gradeObject ? {
                grid: gradeObject,
                total: somaSize(gradeObject)
            } : null
        };
    }

    const findNfeKeys = (nfeData) => {
        let uniqueNfes = {};
        let nfeDetails = [];

        for (const produto of nfeData) {
            if (!uniqueNfes[produto.chave_nfe]) {
                nfeDetails.push({
                    CHAVE_NFE: produto.chave_nfe,
                    SERIE_NF: produto.serie_nf.trim(),
                    NF_SAIDA: produto.nf_saida.trim()
                });
                uniqueNfes[produto.chave_nfe] = true;
            }
        }
        return { nfeDetails }
    };

    let allBoxProducts = [];
    state.data.CAIXAS.forEach(cx => {
        if (cx.caixa === caixa) {
            allBoxProducts = allBoxProducts.concat(cx.produtos);
        }
    });

    const onClickSaveButton = () => {
        const {
            chave_nfe, nf_saida, serie_nf, produto, cor, idMarca, pedido, descCor, desc, item
        } = nfeBoxItem;
        const { nfeDetails } = findNfeKeys(allBoxProducts);

        ondivergencyNfe({
            nfeDetails,
            chave_nfe,
            nf_saida,
            serie_nf,
            romaneio: state.data.ROMANEIO,
            pedido,
            item,
            caixaViolada: nfeBox.caixaViolada,
            incluirNaCaixa: novoProduto ? true : false,
            caixa: caixa, produto, cor, idMarca: parseInt(idMarca),
            grade: gradeState, gradeorig: nfeBoxItem.gradeorig,
            descCor, desc

        });
    }

    const propsDetailsItem = {
        title: {
            id: nfeBoxItem.produto.trim(),
            description: nfeBoxItem.desc,
            Icons: [],
        },
        blocksInfos: {
            block1: [
                [
                    { key: 'Pedido: ', value: `${nfeBoxItem.pedido ? nfeBoxItem.pedido : '-'}` },
                    nfeBoxItem.divergencia &&
                        somaSize(nfeBoxItem.grade) !== somaSize(nfeBoxItem.divergencia) ?
                        { key: 'Qtde total: ', value: somaSize(nfeBoxItem.divergencia), oldValue: somaSize(nfeBoxItem.grade) }
                        :
                        { key: 'Qtde total: ', value: somaSize(nfeBoxItem.grade) },
                ],
                [
                    { key: 'Marca: ', value: nfeBoxItem.marca },
                    { key: 'Cor: ', value: `${nfeBoxItem.cor} - ${nfeBoxItem.descCor?.trim().split('_')[0]}` },
                ],
            ]
        },
        grid: novoProduto === undefined ? {
            grid: nfeBoxItem.grade,
            total: somaSize(nfeBoxItem.grade)
        } : {
            grid: gradeObject,
            total: 0
        }
        ,
        divergency: nfeBoxItem.divergencia,
        gridReducer: { state: gradeState, dispatch: gradeDispatch },
        editItemButton: null
    };

    return (
        state.loading ?
            <Spinner />
            :
            <>
                <div className='container containerNfeItemEdit'>
                    <ButtonBack onClick={() => dispatch({ type: 'goBack', value: { screen: `nfeBoxItems/${boxId}#${produtoId}` } })} />
                    <DetailsItem {...propsDetailsItem} />
                </div>
                <FooterButton onClick={onClickSaveButton}>
                    <div className='divContainerButton'>
                        <IconSave />
                        SALVAR MUDANÇAS
                    </div>
                </FooterButton>
            </>
    )
}

export default NfeItemEdit