import useUser from "../../../Hooks/UserContext";
const Show = (props) => {
  const user = useUser();
  const { to } = props;
  let show = false;

  if (user?.features) {
    show = true;

    if (typeof to == "string") {
      show = user.features[to];
    }
    if (typeof to == "function") {
      show = to(user.features);
    }
    if (typeof to == "boolean") {
      show = to;
    }
  }

  return show ? props.children : null;
};
export default Show;
