import React from 'react'

import { Button } from '@mui/material';
import GetAppIcon from "@mui/icons-material/GetApp";

import { exportToExcel } from '../../../../components/ExportExcel/ExportExcel';
import { DataExcelDivergencia } from './DataExcelDivergencia';

const ButtonExport = ({ results, products }) => {

    const handleExportToExcel = () => {
        const exportData = DataExcelDivergencia(results, products);
        exportToExcel(exportData, 'divergencias.xlsx');
    };


    return (
        <Button data-testid="Button" variant="contained" fullWidth onClick={handleExportToExcel}
            style={{
                margin: "5px 0", width: 175, height: 33, border: '2px solid #AD9078', backgroundColor: 'transparent', color: '#AD9078', fontWeight: 550, fontFamily: 'flama-basic', fontSize: '.80rem', lineHeight: '11px'
            }}
        >
            <GetAppIcon style={{ width: '1.25rem', height: '1.25rem', marginRight: 5 }} />
            EXPORTAR EXCEL
        </Button>
    )
}

export default ButtonExport;
