import React from 'react';
import { Navigate } from 'react-router-dom';
import useUser from '../../Hooks/UserContext';
import { backgroundImages, backgroundImagesResponsive, randomNumber } from '../../Utils/RandomBackgroundLogin';
import './LoginPage.css';
import LoginForm from './LoginForm/LoginForm';

const LoginPage = () => {
  const user = useUser();

  if (user) return (<Navigate replace={true} to="/" />);

  return (
    <main className="main__loginpage--conteiner">
      <div className="div__leftside--conteiner" style={{ backgroundImage: `url(${backgroundImages[randomNumber]})` }}></div>
      <div className="div__leftsideresponsive--conteiner" style={{ backgroundImage: `url(${backgroundImagesResponsive[randomNumber]})` }}></div>
      <div className="div__rightside--conteiner">
        <LoginForm />
        <footer className="footer__rightside--conteiner">
          <small>2021</small>
          <span>SomaLabs</span>
        </footer>
      </div>
    </main>
  );
};

export default LoginPage;
