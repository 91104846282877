import React, { useState,  } from 'react';
import { Divider, TextField, Grid, Typography } from '@mui/material';
import Modal from '../../../components/Modal';


const EditMeta = (props) => {
    const { meta: oldMeta } = props
    const [meta, setMeta] = useState(oldMeta);


    return (
   <div style={{ margin: "10px", padding: "15px" }}>
     <Modal actionName='EDITAR' modalName='META DO CLIENTE' open={props.open} onClose={() => props.cancel()} action={() => {props.save(meta)}} >
      <Grid container direction='row' justifyContent="center" spacing={3} style={{ paddingTop:'15px' ,paddingBottom:'15px'}}>
          <Grid item style={{backgroundColor: 'rgba(255, 255, 255, 0.22)', paddingTop:'10px', paddingBottom:'20px', paddingLeft:'20px', paddingRight:'20px'}}>
            <Typography style={{ textAlign: "left", color: "#8B8B8B", fontSize: '16px', fontFamily: 'flama-basic', fontWeight: 400, lineHeight: '20px', whiteSpace:'nowrap', paddingBottom:'15px'}}>Insira aqui o valor desejado: </Typography>
                    <Grid container direction={"column"} spacing={2}>
                            <TextField 
                                value={meta.VALOR_META}
                                color="secondary"
                                size="small"
                                variant="outlined"
                                onChange={(evt) => { 
                                    setMeta({
                                        ...meta, 
                                        VALOR_META: evt.target.value
                                    })
                                }} 

                            />
                    </Grid>
          </Grid>
     
      </Grid>
      <Divider style={{ marginTop: "10px", marginBottom: "10px" }} />
      </Modal>
     
    </div>
               
    )

}

export default EditMeta;