import React, { useState, useEffect } from 'react';
import SomaTable from '../../../components/SomaStandard/SomaTableOld';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import { pageList } from '../../../helpers/webHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';

const ListaMetas = (props) => {
    const [result, setResult] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [status, setStatus] = useState('result');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (props.show) {
            setData(props.line.metas);
            setStatus(props.line.metas ? (props.line.metas.length ? 'result' : 'empty') : 'empty');
        }
    }, [props.show, props.line.metas, data])

    useEffect(() => {
        setResult(data ? pageList(data, page, rowsPerPage) : [])
    }, [data, page, rowsPerPage])

    return (
        <SomaStatus status={status}>
            <SomaTable size="small" results={result} columns={[
                // { propName: "CLIFOR", propText: "CLIFOR" },
                { propName: "DESC_REDE_LOJAS", propText: "Marca"},
                { propName: "TABELA", propText: "Tabela" },
                { propName: "VALOR_ATINGIR", propText: "Valor", component:ConverteDinheiro },
                // { propName: "CLIFOR", propText: "", component: (p) => <Button onClick={()=>props.editMeta(p.line, p.children)}>Editar</Button> }
            ]} {...{ page, setPage, rowsPerPage, setRowsPerPage, total: data ? data.length : 0 }} />
        </SomaStatus>
    );
};

export default ListaMetas;