import React, { useState, useEffect } from 'react';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import NfeGroup from '../Components/NFEButtonGroup/NFEButtonGroup';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import ConverteData from '../../../components/SomaStandard/TableComponents/ConverteData';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import useUser from '../../../Hooks/UserContext';
import useApi from '../../../Hooks/useApi';
import MaisAtacado from '../../../Services/MaisAtacado';
import TableSettings from '../../../components/TableSettings/TableSettings';


const getStateFromApi = (api) => {
  if (api.status === 'ready') {
    if (api.error) {
      return 'error';
    }
    if (api.data?.results.length > 0) {
      return 'result';
    }
    else {
      return 'empty';
    }
  }
  else {
    return 'wait';
  }
};

const SViaNotaFiscal = (props) => {
  const user = useUser();
  const hasButton = true;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState({});

  const faturamento = useApi(MaisAtacado.Faturamento.getFaturamento);

  const total = faturamento.data?.total || 0;
  const results = faturamento.data?.results || [];
  const status = getStateFromApi(faturamento);

  let Opts = {
    columns: [
      {
        propName: "FILIAL",
        componentProps: {
          colunas: [
            { propName: "NF_SAIDA", propText: "Nº da nota", highlighted: true },
            { propName: "NOME_CLIFOR", propText: "Cliente", show: user.features.repre === true  },
            { propName: "TIPO_VENDA", propText: "Tipo pedido"},
            { propName: "MARCA", propText: "Marca"},
            { propName: "SERIE_NF", propText: "Serie" },
            { propName: "DATA_SAIDA", propText: "Data de emissão", component: ConverteData },
            { propName: "VALOR_TOTAL", propText: "Valor total", component: ConverteDinheiro },
            {
              propName: "FILIAL",
              component: NfeGroup,
              componentProps: {}
            }
          ]
        }
      }
    ],
    hasButton,
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      {
        propName: "NOME_CLIFOR[lk]", propText: "Cliente",
        format: val => `%${val}%`, show: { filter: user.features.repre === true }
      }, {
        propName: "clienteAtacado.CLIFOR[lk]", propText: "Clifor",
        format: val => `%${val}%`, show: { filter: user.features.repre === true }
      },
      {
        propName: "Faturamento.NF_SAIDA[lk]", propText: "Nota Fiscal",
        format: val => `%${val}%`
      },
      { propName: "Faturamento.SERIE_NF[eq]", propText: "Série" },
      { propName: "DATA_SAIDA", component: ToFrom, propText: "Emissão", componentProps: { component: DatePicker } },
      { propName: "VALOR_TOTAL", component: ToFrom, propText: "Valor" },
    ],

    updateFilter: (e) => {
      if (e.DATA_SAIDA.lte == null) { delete e.DATA_SAIDA.lte; };
      if (e.DATA_SAIDA.gte == null) { delete e.DATA_SAIDA.gte; };
      setFilter(e);
      setPage(0);
    }
  };

  useEffect(() => {
    const dispatch = faturamento.dispatch;
    dispatch(filter, { sz: rowsPerPage, pg: page });
  }, [faturamento.dispatch, page, rowsPerPage, filter]);

  return (
    <>
      <TableSettings>
        <div></div>
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <SomaTable {...Opts} />
      </SomaStatus>
    </>
  );
};

export default SViaNotaFiscal;
