import React from 'react'

import AnimationDots from '../../../../../../components/AnimationDots'

import './HomeHeaderItem.css'

const HomeHeaderItem = props => (
    <div className="div__HomeHeaderItem--container" >
        <h3 className="h3__HomeHeaderItem--title"> {props.title} </h3>
        <h1 className="h1__HomeHeaderItem--value" > {props.loading ? <AnimationDots color="white" /> : props.value} </h1>
    </div>
)

export default HomeHeaderItem