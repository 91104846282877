import React from "react";
import Routes from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import theme from "./Hooks/themeContext";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import { UserContextProvider } from "./Hooks/UserContext";
import { AffiliateContextProvider } from "./Hooks/AffiliateContext";
import { BrowserRouter } from "react-router-dom";
import Demo from "./components/Feedback";

const App = (props) => (

  <div className="App" style={{ height: "100lvh" }}>
    <ToastContainer
      position={"bottom-center"}
      autoClose={5000}
      hideProgressBar={false}
      closeOnClick={true}
      pauseOnHover={true}
      draggable={true}
      theme="colored"
    />
    <BrowserRouter>
      <UserContextProvider>
        <ThemeProvider theme={theme}>
          <AffiliateContextProvider>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Routes />
              <Demo />
            </LocalizationProvider>
          </AffiliateContextProvider>
        </ThemeProvider>
      </UserContextProvider>
    </BrowserRouter>
  </div>
);

export default App;
