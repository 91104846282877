import React from 'react'
import './TableItems.css'

import TableBodyRow from './TableBodyRow'

const TableItems = (props) => {
    const {
        isEditItem,
        grid,
        divergency,
        gridReducer,
        isSearchPage
    } = props.table

    let rows = Object
        .entries(grid)
        .map(([size, quantity]) => {
            return {
                size: isEditItem ?
                    { key: size, value: gridReducer?.state[size] } :
                    { key: size, value: quantity },

                gridReducerDispatch: gridReducer ? gridReducer.dispatch : gridReducer,
                divergency: divergency && { key: size, value: divergency?.[size] },
                isEditItem: isEditItem,
                col1Name: 'Tam: ',
                col2Name: 'Qtde: ',
            }
        })

    return (
        <table className='tableRows'>
            <tbody className='tableBody'>
                {
                    isEditItem ?
                        rows.map((row) => (<TableBodyRow key={row.size.key} row={row} />))
                        :
                        !isSearchPage &&
                        rows
                            .filter((row) => row.size.value > 0 || row.divergency?.value > 0)
                            .map((row) => (<TableBodyRow key={row.size.key} row={row} />))
                }
            </tbody>
        </table>
    )
}

export default TableItems