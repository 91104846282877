import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import { encodeFilter } from '../../../helpers/webHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import useAffiliate from '../../../Hooks/AffiliateContext';
import twoValues from '../../../components/SomaStandard/TableComponents/twoValues/twoValues';
import TableSettings from '../../../components/TableSettings/TableSettings';


const VendasMarca = (props) => {
  const hasButton = true;
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState('');
  const [soldBrands, setSoldBrands] = useState([])

  const [upload, setUpload] = useState(false);
  const { brands } = useAffiliate()

  const brandsName = soldBrands.map(soldBrand => {
    const atelierBrand = brands.find(brand => brand.id_marca_linx === +soldBrand)
    return { propName: soldBrand, propText: atelierBrand?.marca, component: ConverteDinheiro }
  })

  const Opts = {
    endpoint: "/v1/minhasvendasnewer/adm",
    columns: [
      {
        propName: "Minhas Vendas",
        componentProps: {
          colunas: [
            { propName: "ano_mes", propText: "período da venda", highlighted: true, money: true, propName2: "valor", component: twoValues },
            ...brandsName,
          ],
        }
      }
    ],
    hasButton,
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      { propName: "data", component: ToFrom, propText: "Período:", componentProps: { component: DatePicker } },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  useEffect(() => {
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?${filter}`)).json();
        let obj = {};
        json.results.forEach(data => {
          obj[data.ano_mes] = {
            selected: false,
            id_profiles: data?.id_profiles
          };
        });

        const getBrandsMultimarca = json.results.map(j => {
          return [...new Set(j.interna.map(vendor => Object.keys(vendor.groupedByBrand)).flatMap(vendor => vendor))]
        })
        const codeBrands = [...new Set(getBrandsMultimarca.flat())];

        const brandsSales =
          json.results.map(j => {
            return codeBrands.flat().reduce((obj, code) => {
              const totalBrand = j.interna.reduce((accumulator, currentValue) => {
                return currentValue.groupedByBrand[code] ?
                  currentValue.groupedByBrand[code] + accumulator
                  :
                  0 + accumulator
              }, 0)
              return { ...obj, [code]: totalBrand }
            }, {})
          })

        for (let index = 0; index < json.results.length; index++) {
          // json.results[index]['salesBrand'] = brandsSales
          json.results[index] = { ...json.results[index], ...brandsSales[index] };
        }

        setSoldBrands(codeBrands)
        setResults(json.results);
        setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
        setUpload(false);
      }
      catch (err) {
        setResults(undefined);
        console.log(err);
        setStatus('error');
      }
    })();
  }, [Opts.endpoint, page, rowsPerPage, filter, upload]);

  return (
    <div >
      <TableSettings>
        <SomaFilter {...FilterOpts} />
      </TableSettings>

      <SomaStatus status={status}>
        <SomaTable {...Opts} />
      </SomaStatus>

    </div>
  );
};


export default VendasMarca;
