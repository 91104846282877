import React, { useState, useEffect } from 'react'
import './Nfe.css'

import { useParams } from "react-router-dom";
import { TextField, Checkbox, FormControl, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Link } from 'react-router-dom'

import { ReactComponent as InsertIcon } from '../../../../assets/images/icons/moreIcon.svg'
import { ReactComponent as Search } from '../../../../assets/images/icons/magnifyingGlass.svg'
import { ReactComponent as Inventory } from '../../../../assets/images/icons/inventory.svg';
import { ReactComponent as Download } from '../../../../assets/images/icons/download.svg';
import { ReactComponent as IconDivergencyRed } from '../../../../assets/images/icons/IconDivergencyRed.svg'
import { ReactComponent as IconDivergencyWhite } from '../../../../assets/images/icons/IconDivergencyWhite.svg'

import Modal from "../../../../components/Modal/Modal.js"
import CardBox from '../../../../components/CardBox/Card.js';
import DetailsBox from '../../../../components/DetailsBox/DetailsBox';
import SecondaryButton from '../../../../components/SecondaryButton/SecondaryButton';
import ButtonBack from './ButtonBack';
import pdfItems from './pdfItems'

import useNfeCaixaFlow from "../../../../Hooks/useNfeReducer";
import DivergencyTag from '../../../../components/Tag/Tag';
import FooterButton from '../../../../components/FooterButton/footerButton';
import useUser from '../../../../Hooks/UserContext.js';

const NfeBoxItems = () => {
    const user = useUser();
    const { boxId } = useParams()
    const { state, dispatch, finishBoxNfe, updateViolatedBox } = useNfeCaixaFlow();
    const [modalFinishBox, setModalFinishBox] = useState(false)
    const [modalCaixaViolada, setModalCaixaViolada] = useState(false)

    const caixas = state.data?.CAIXAS || null;
    const [nfeBox, setNfeBox] = useState(caixas?.find(box => box.caixa === boxId))
    const [textSearched, setTextSearched] = useState('')
    const [caixaViolada, setCaixaViolada] = useState(nfeBox?.caixaViolada || false);
    const caixa = nfeBox?.caixa;
    const boxVerified = user?.features?.recebimento ? state.data.VERIFICADO : true;

    useEffect(() => {
        const currentItemId = window.location.href.split('#')[1];
        if (currentItemId) window.location.href = '#' + currentItemId
    }, [])

    let allProdutos = [];
    let allBoxProducts = [];
    state.data.CAIXAS.forEach(cx => {
        allProdutos = allProdutos.concat(cx.produtos);
        if (cx.caixa === caixa) {
            allBoxProducts = allBoxProducts.concat(cx.produtos);
        }
    });

    const submitSearchProduct = (e) => {
        e.preventDefault()
        const text = e.target[0].value
        if (text !== '') {
            const result = nfeBox.produtos.filter(item => item.produto.trim().split('.').join("") === text.trim().split('.').join(""))

            setNfeBox({ ...nfeBox, produtos: result })
            setTextSearched(text)
            e.target[0].value = ''
        }
    }

    const onClearText = () => {
        setNfeBox(caixas.find(box => box.caixa === boxId))
        setTextSearched('')
    }

    const findNfeKey = (nfeData) => {
        let uniqueNfes = {};
        let nfeDetails = [];

        for (const produto of nfeData) {
            if (!uniqueNfes[produto.chave_nfe]) {
                nfeDetails.push({
                    CHAVE_NFE: produto.chave_nfe,
                    SERIE_NF: produto.serie_nf.trim(),
                    NF_SAIDA: produto.nf_saida.trim()
                });
                uniqueNfes[produto.chave_nfe] = true;
            }
        }
        return { nfeDetails }
    };

    const finishBox = () => {
        const allNfeDetails = findNfeKey(allProdutos);
        const { nfeDetails } = findNfeKey(allBoxProducts);
        const chavesNfes = { chave_nfe: allNfeDetails.nfeDetails.map(nf => nf.CHAVE_NFE) };

        finishBoxNfe({
            entrega: state.data?.ROMANEIO,
            nfeDetails,
            caixa,
            chavesNfes,
            caixaViolada
        });
    }

    const onCheckIsViolated = () => {
        const allNfeDetails = findNfeKey(allProdutos);
        const { nfeDetails } = findNfeKey(allBoxProducts);
        const chavesNfes = { chave_nfe: allNfeDetails.nfeDetails.map(nf => nf.CHAVE_NFE) };

        updateViolatedBox({
            entrega: state.data?.ROMANEIO,
            nfeDetails,
            caixa,
            chavesNfes,
            caixaViolada: !caixaViolada
        });
    }

    const verifyBox = () => {
        setModalFinishBox(false);
        if (nfeBox.divergenciaTag.includes("falta") && !caixaViolada) {
            setModalCaixaViolada(true);
        } else {
            finishBox();
        }
    }

    const handleGoBack = () => {
        if (!boxVerified && !nfeBox?.lockedAt && nfeBox.divergenciaTag.some(tag => ["extra", "sobra", "falta"].includes(tag))) {
            setModalFinishBox(true);
        } else {
            dispatch({ type: 'goBack' })
        }
    }

    const somaSize = (grade) => {
        let sum = 0
        if (grade) {
            Object.entries(grade).map(size => sum += size[1])
            return sum
        }
    }

    const inputLabelContent = (
        <div className='textSearch'>
            <Search />
            <span>Buscar cod. do produto</span>
        </div>
    )

    const checkboxContent = (
        <div style={{ marginLeft: "-10px" }}>
            <Checkbox color="default" size="small" defaultChecked={caixaViolada} onChange={async () => { onCheckIsViolated() }} disabled={state.data?.VERIFICADO} />
            Caixa/Lacre violado
        </div>
    )

    const lastEditionContent = (
        <span>
            <p style={{ margin: '2px', textAlign: 'end' }}>
                {new Date(nfeBox.ultimaEdicao).toLocaleDateString('pt-BR')}<br />

                <span style={{ fontSize: '0.9rem' }}>
                    {new Date(nfeBox.ultimaEdicao).toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo', hour: '2-digit', minute: '2-digit' })}
                </span>
            </p>
        </span>
    )

    const divergencyTagContent = (
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {nfeBox.divergenciaTag.includes("falta") && (<DivergencyTag name="Falta" color="#F3AF00" />)}
            {nfeBox.divergenciaTag.some(tag => ["extra", "sobra"].includes(tag)) && (<DivergencyTag name="Sobra" color="#2596DA" />)}
        </div>
    )

    const propsCardBox = {
        title: {
            name: 'Caixa',
            Icon: <Inventory />,
            id: nfeBox.caixa,
        },
        download: {
            Icon: <Download />,
            name: 'Baixar Romaneio',
            click: () => pdfItems(nfeBox)
        },
        infos: [
            { left: 'Qtde. de referências', right: nfeBox.qtdeProdutos },
            { left: 'Qtde. total de itens', right: nfeBox.qtdeItems },
            nfeBox.ultimaEdicao && { left: 'Ultima edição', right: lastEditionContent },
            nfeBox?.divergenciaTag?.length && { left: 'Divergência na caixa', right: divergencyTagContent },
            user?.features?.recebimento && { left: checkboxContent, right: "" },
        ],
    }

    const list = {
        name: 'Produtos',
        items: nfeBox.produtos.map(item => {
            return {
                title: {
                    id: item.produto.trim(),
                    description: item.desc,
                    key: item.produto.trim() + item.pedido + item.cor,
                    Icons: [
                        item?.divergenciaTag?.includes("falta") && <DivergencyTag name="Falta" color="#F3AF00" />,
                        item?.divergenciaTag?.some(tag => ["extra", "sobra"].includes(tag)) && <DivergencyTag name="Sobra" color="#2596DA" />,
                    ],
                },
                blocksInfos: {
                    block1: [
                        [
                            { key: 'Pedido: ', value: `${item.pedido ? item.pedido : '-'}` },
                            item.divergencia &&
                                somaSize(item.grade) !== somaSize(item.divergencia) ?
                                { key: 'Qtde total: ', value: somaSize(item.divergencia), oldValue: somaSize(item.grade) }
                                :
                                { key: 'Qtde total: ', value: somaSize(item.grade) },
                        ],
                        [
                            { key: 'Marca: ', value: item.marca },
                            { key: 'Cor: ', value: `${item.cor} - ${item.descCor?.trim().split('_')[0]}` },
                        ],
                    ]
                },
                warning: {
                    componentProps: { className: 'boxDetailsText' },
                    message: item?.divergenciaTag?.includes("sobra") && 'Obs: Peça fora do romaneio'
                },
                grid: {
                    grid: item.grade,
                    total: somaSize(item.grade)
                },
                divergency: item.divergencia ?
                    {
                        grid: item.divergencia,
                        total: somaSize(item.divergencia)
                    } : null,
                gridReducer: null,
                editItemButton: !boxVerified ? {
                    text: item?.divergenciaTag.length > 0 ?
                        "Editar divergência" : "Apontar divergência",
                    Icon: item?.divergenciaTag.length > 0 ?
                        <IconDivergencyWhite /> : <IconDivergencyRed />,
                    link: `./nfeBoxItems/${caixa}/edit/${item.produto.trim()}`,
                    click: () => dispatch({
                        type: 'showNfeItemEdit',
                        boxId: caixa,
                        produtoId: item.produto.trim(),
                        corId: item.cor.trim(),
                        pedidoId: item.pedido.trim(),
                        itemId: item.item.trim(),
                    }),
                    componentProps: {
                        additionalClassName: item?.divergenciaTag.length > 0 ?
                            "divergencyButtonColorRed" : ""
                    },
                } : null,
                componentProps: item?.divergencia ? { additionalClassName: 'itemInfo_highlighted' } : {},
            }
        })
    };

    return (
        <div className='containerBoxItems'>
            <Modal
                modalName="CAIXA OU LACRE VIOLADO?"
                closeName="Voltar"
                onClose={() => { setModalCaixaViolada(false); setCaixaViolada(false) }}
                actionName="Continuar"
                open={modalCaixaViolada}
                action={() => { setModalCaixaViolada(false); finishBox() }}
            >
                <p>A caixa <span style={{ fontWeight: "600" }}>{caixa}</span> veio violada ou com o lacre violado? </p>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="opcao"
                        name="opcao"
                        value={caixaViolada}
                        onChange={(e) => setCaixaViolada(e.target.value === "true")}
                    >
                        <FormControlLabel value={true} control={<Radio color="default" className='formCheckboxRadio' />} label="Sim" />
                        <FormControlLabel value={false} control={<Radio color="default" className='formCheckboxRadio' />} label="Não" />
                    </RadioGroup>
                </FormControl>
            </Modal>

            <Modal
                modalName={`FINALIZAR CAIXA ${caixa}?`}
                closeName="SAIR SEM FINALIZAR"
                onClose={() => dispatch({ type: 'goBack' })}
                actionName="Finalizar Caixa"
                open={modalFinishBox}
                action={verifyBox}
            >
                <p>
                    Você apontou divergência em um ou mais itens. Deseja salvar
                    essas alterações  e finalizar a conferência dessa caixa?
                </p>
            </Modal>

            <div>
                <ButtonBack onClick={handleGoBack} />
                <div className='containerNfeBoxItems'>
                    <CardBox {...propsCardBox} />

                    {!boxVerified &&
                        <div className='insertProductButton'>
                            <SecondaryButton onClick={() => dispatch({ type: 'showNfeBoxInsertItem', boxId: nfeBox.caixa })}>
                                <Link to={`./nfeBoxItems/${boxId}/insert`}>
                                    <InsertIcon />
                                    ADICIONAR PRODUTO
                                </Link>
                            </SecondaryButton>
                        </div>
                    }

                    <form onSubmit={(e) => submitSearchProduct(e)}>
                        <TextField className='textInfoNfe' label={inputLabelContent} fullWidth variant="standard" color="secondary" />
                    </form>

                    {textSearched.length > 0 &&
                        <div className='clearSearch'>
                            <span>Resultados para "{textSearched}"</span>
                            <button onClick={onClearText}>
                                x<span>Limpar</span>
                            </button>
                        </div>
                    }
                </div>
            </div>
            <DetailsBox list={list} />
            {(!state.data.VERIFICADO && !nfeBox?.lockedAt) && <FooterButton onClick={verifyBox}>FINALIZAR CAIXA</FooterButton>}
        </div>
    )
}

export default NfeBoxItems