import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from "@mui/material";

const Dropdown = (props) => <FormControl variant="standard" fullWidth disabled={props.disabled}>
    <InputLabel shrink={true} error={props.error}>{props.label}</InputLabel>
    <Select
        multiple={props.multiple}
        disabled={props.disabled}
        error={props.error}
        value={props.value || ''}
        onChange={({ target: { value } }) => props.onChange(value)}
        fullWidth>
        {props.options.map((o, i) => <MenuItem value={o.value} key={`dp-${i}`}>{o.text}</MenuItem>)}
    </Select>
    {props.helperText ? <FormHelperText error={props.error} >{props.helperText}</FormHelperText> : ''}
</FormControl>

export default Dropdown