import React from "react";
import { authFetch } from "../../../helpers/authHelper";
import pdfItems from './FormMultiStepRecebimento/pdfItems'

import GetAppIcon from "@mui/icons-material/GetApp";
import TableButtonDownload from "../../../components/SomaStandard/TableComponents/TableButtonDownload/TableButtonDownload";

const ButtonExportOrders = (props) => {
  const handleDownloadClick = async () => {
    const result = await authFetch(`/v1/MeusPedidos/${props.line.PEDIDO}?sz=0`);
    const data = await result.json();
    const transformedData = data.map(item => ({
      produto: item.PRODUTO,
      desc: item.DESC_PRODUTO,
      pedido: item.PEDIDO,
      cor: item.COR_PRODUTO,
      descCor: item.DESC_COR_PRODUTO,
      grade: item.GRADE,
      preco: item.PRECO,
      marca: props.line.MARCA,
      qntOriginal: item.QTDE_ORIGINAL
    }));

    pdfItems({ produtos: transformedData, pedido: props.line, pdfPedido: true });
  };

  return (
    <TableButtonDownload clicked={handleDownloadClick} width="80px">
      <GetAppIcon />
      <span>pdf</span>
    </TableButtonDownload>
  );
};

export default ButtonExportOrders;
