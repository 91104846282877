import React from "react";
import "./ToggleFilter.css";
import { v4 } from "uuid";

import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import ConverteParaIcone from "./TableComponents/ConverteParaIcone";

export default function ToggleFilter(props) {
  const toggleButtonGroupProps = {
    value: props.valorAtivo,
    color: "primary",
    size: "small",
    exclusive: true,
    onChange: props.handleChange,
    "aria-label": "Platform",
  };

  const converteParaIconeProps = {
    className: "sizeIcone_small",
    options: props.opts,
  };

  return (
    <ToggleButtonGroup {...toggleButtonGroupProps}>
      {props.opts.map((opt) =>
        <ToggleButton disabled={props.disabled} size="small" value={opt.key} key={v4()}>
          <ConverteParaIcone {...converteParaIconeProps}>
            {opt.key}
          </ConverteParaIcone>
          {opt.value}
        </ToggleButton>
      )}
    </ToggleButtonGroup>
  );
}
