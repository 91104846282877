import React, { useContext, useEffect, useState } from 'react';

import MaisAtacado from '../Services/MaisAtacado';

import useApi from './useApi';
import useUser from './UserContext';

const AffiliateContext = React.createContext();

const useAffiliate = () => {
  return useContext(AffiliateContext);
}

const AffiliateContextProvider = (props) => {
  const user = useUser();

  const [acceptedNewContract, setAcceptedNewContract] = useState(false)
  const brands = useApi(MaisAtacado.Afiliados.getBrands)
  const affiliateFetch = useApi(MaisAtacado.Afiliados.fetchInfo)

  const updateAffiliate = affiliateFetch.dispatch;
  const fetchBrands = brands.dispatch;

  useEffect(() => {
    if (user) {
      updateAffiliate();
      fetchBrands()
    }
    //  const interval = setInterval(updateAffiliate, 30000);
    //  return () => clearInterval(interval);
  }, [user, updateAffiliate, fetchBrands]);

  useEffect(() => {
    if (affiliateFetch.status === 'ready' && affiliateFetch.data?.affiliate) {
      const acceptedContracts = affiliateFetch.data.affiliate.acceptedContracts.filter(item => item.id_contracts > 10).length > 0 ? true : false
      setAcceptedNewContract(acceptedContracts)
    } else {
      setAcceptedNewContract(true)
    }
  }, [affiliateFetch.data, affiliateFetch.status])

  return (
    <AffiliateContext.Provider value={{
      affiliate: affiliateFetch.data?.affiliate,
      status: affiliateFetch.data?.status,
      profiles: affiliateFetch.data?.profiles,
      fetchStatus: affiliateFetch.status,
      updateAffiliate,
      acceptedNewContract,
      brands: brands.data
    }} >
      {props.children}
    </AffiliateContext.Provider>
  )
}

export { AffiliateContextProvider };
export default useAffiliate;
