import React from "react";
import LogoAtacado from "../../assets/images/icons/LogoMaisAtacado.svg";

import styles from "./Logo.module.css";

const LogoMaisAtacado = (props) => (
  <div className={styles.logoPortal}>
    <img data-testid="logoMaisAtacado" src={LogoAtacado} alt="Logo +Atacado" />
  </div>
);

export default LogoMaisAtacado;
