import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import SomaTable from '../../../components/SomaStandard/SomaTableOld';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import { pageList } from '../../../helpers/webHelper';

const ListaProdutos = (props) => {
    const [result, setResult] = useState(undefined);
    const [data, setData] = useState(undefined);
    const [status, setStatus] = useState('wait');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if (props.show && !data) {
            setStatus('wait');
            (async () => {
                try {
                    const json = await (await authFetch(`/v1/MeusPedidos/${props.line.PEDIDO}?sz=0`)).json();
                    setData(json); setStatus(json.length ? 'result' : 'empty');
                }
                catch (err) {
                    console.log(err);
                    setData(undefined); setStatus('error');
                }
            })();
        }
    }, [props.show, props.line.PEDIDO, data])

    useEffect(() => {
        setResult(data ? pageList(data, page, rowsPerPage) : [])
    }, [data, page, rowsPerPage])

    return (
        <SomaStatus status={status}>
            <SomaTable size="small" results={result} columns={[
                { propName: "PRODUTO", propText: "Codigo Produto" },
                { propName: "DESC_PRODUTO", propText: "Descrição Produto" },
                { propName: "QTDE_ORIGINAL", propText: "Quantidade Original" },
                { propName: "QTDE_ENTREGUE", propText: "Quantidade Entregue" }
            ]} {...{ page, setPage, rowsPerPage, setRowsPerPage, total: data ? data.length : 0 }} />
        </SomaStatus>
    );
};

export default ListaProdutos;
