import React, { useState } from 'react';
import { AppBar, Toolbar, Grid, Button } from '@mui/material';
import { NavLink } from 'react-router-dom'
import logoSoma from '../../assets/images/logos/logoAtacado.svg'
import HamburguerIcon from '../../assets/images/icons/hamburguer3.svg'
import useUser, { useAuth } from '../../Hooks/UserContext';
import Show from '../SomaStandard/AuthComponents/Show';
import SideMenuResponsive from "../SideMenu/SideMenuResponsive/SideMenuResponsive";
import Backdrop from "../Backdrop/index";
import './BasePage.css'


const BasePage = (props) => {
  const [showResponsiveMenu, setShowReponsiveMenu] = useState(false)
  const user = useUser();
  const { logoff } = useAuth();

  return (
    <Grid style={Object.assign(props.style || {}, { height: "100vh", display: "flex", flexDirection: "column" })}>
      <AppBar position="relative" style={{ backgroundColor: "#FFFFFF"}}>
        <Toolbar>
          <Grid container alignItems="center" justifyContent="space-between" direction="row" >
            <Grid item style={{ display: "flex", alignItems: "center" }}>
              <div data-testid="div__hamburguer--button" className="div__hamburguer--button" onClick={() => setShowReponsiveMenu(prevState => (!prevState))}>
                <img src={HamburguerIcon} alt="Botão Hamburguer" />
              </div>
              <NavLink to="/" className="div__header--logo">
                <img src={logoSoma} style={{ marginLeft: '10px', height: '32px', width: "95px" }} alt="Logo do Soma" />
              </NavLink>
            </Grid>
            <Show>
              <Grid item xs container justifyContent="flex-end" spacing={2} alignItems="center">
                <Grid item style={{ margin: "0 32.31px" }} className="div__header--userName">
                  <Grid container justifyContent="center" direction='column'>
                    <Grid item>
                      <span style={{ color: "#000000", fontFamily: 'flama-medium', fontSize: '12px' }}>OLÁ {user.cliente},</span>
                    </Grid>
                    <Grid style={{ color: "#000000", fontFamily: 'flama-medium', fontSize: '12px' }}>TUDO BOM?</Grid>
                  </Grid>
                </Grid>
                <Grid>
                  <Button style={{ color: "#747474", fontFamily: 'flama-medium', textDecoration: 'underline', fontSize: '13px', fontWeight: '600' }} size="small" onClick={logoff.dispatch}>
                    SAIR
                  </Button>
                </Grid>
              </Grid>
            </Show>
          </Grid>
        </Toolbar>
      </AppBar>
      <div>
        <Backdrop show={showResponsiveMenu} setShow={setShowReponsiveMenu} />
        <SideMenuResponsive show={showResponsiveMenu} setShow={setShowReponsiveMenu} />
      </div>
      <Grid id="basePage" style={{ height: "100%", overflow: "hidden" }}>
        {props.children}
      </Grid>
    </Grid>
  )

}

export default BasePage