import React from 'react'
import './PrimaryButton.css'



const PrimaryButton = props => {
    const { additionalClassName, children, ...rest } = props;

    return (
        <button className={`button__PrimaryButton--conteiner ${additionalClassName}`} {...rest} >
            {children}
        </button>
    )
}

export default PrimaryButton