import axios from "axios";
import MaisAtacado from "./MaisAtacado";

const decodeUser = (tok) => {
  if (!tok) return undefined;
  try {
    const [, data,] = tok.split('.');
    return JSON.parse(atob(data.replaceAll('-', '+').replaceAll('_', '/')));
  } catch (e) {
    return undefined;
  }
};

const login = async (login, senha) => {
  try {
    return await MaisAtacado.post('/v1/auth/login', { login, senha },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const retoken = async (code) => {
  try {
    return await MaisAtacado.post('/v1/auth/retoken', { code },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const startPasswordChange = async (clifor) => {
  try {
    return await MaisAtacado.post('/v1/auth/change-password/start', { clifor },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const checkPasswordChange = async (code, clifor) => {
  try {
    return await MaisAtacado.post('/v1/auth/change-password/check', { code, clifor },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const endPasswordChange = async (code, clifor, senha, confirma) => {
  try {
    return await MaisAtacado.post('/v1/auth/change-password/end', { code, clifor, senha, confirma },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const retokenSomaAuth = async (code) => {
  try {
    return await MaisAtacado.post('/v1/auth/retoken-soma-auth', { code },
      {
        responseType: "text",
        transformRequest: axios.defaults.transformRequest,
      }
    );
  } catch (err) {
    throw err;
  }
};

const impersonate = async (clifor, banco) => {
  try {
    return await MaisAtacado.post('/v1/auth/impersonate', { clifor, banco }, { responseType: "text" });
  } catch (err) {
    throw err;
  }
};

const suggestPasswordReset = async (login, banco) => {
  try {
    return await MaisAtacado.post(`/v1/auth/password-reset/suggest`, { login, banco }, {
      transformRequest: axios.defaults.transformRequest,
    });
  } catch (err) {
    throw err.response;
  }
};

const acceptPasswordReset = async (login, banco) => {
  try {
    return await MaisAtacado.post(`/v1/auth/password-reset/accept`, { login, banco }, {
      transformRequest: axios.defaults.transformRequest,
    });
  } catch (err) {
    throw err.response;
  }
};

const logoff = async () => {
  try {
    return await MaisAtacado.post('/v1/auth/logoff', {}, { responseType: "text" });
  } catch (err) {
    throw err.response;
  }
};

const Auth = {
  login,
  logoff,
  suggestPasswordReset,
  acceptPasswordReset,
  decodeUser,
  impersonate,
  retoken,
  retokenSomaAuth,
  startPasswordChange,
  checkPasswordChange,
  endPasswordChange
};
export default Auth;
