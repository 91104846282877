import React from 'react'
import './TableItems.css'
import { ReactComponent as More } from '../../../assets/images/icons/selectorMore.svg'
import { ReactComponent as Less } from '../../../assets/images/icons/selectorLess.svg'


const TableBodyRow = (props) => {
    const { size, divergency, isEditItem, gridReducerDispatch, col1Name, col2Name } = props.row

    const valueTyped = (e) => {
        const number = parseInt(e.target.value)

        if (!isNaN(number) && number !== null) {
            gridReducerDispatch({ type: 'typed', key: size.key, value: number })
        }
        else {
            gridReducerDispatch({ type: 'typed', key: size.key, value: 0 })
        }
    }

    const changeValue = (type) => {
        gridReducerDispatch({ type, key: size.key, value: 1 })
    }

    return (
        <tr className='tableRow'>
            <td>{col1Name} {size.key}</td>

            <td className={isEditItem ? 'quantityProduct' : ''}>
                {col2Name}
                {isEditItem ?
                    <>
                        <Less onClick={() => changeValue('decrement')} className={`selectorButton ${!size.value ? 'buttonDesabled' : ''}`} />
                        <input autoFocus={true} onChange={e => valueTyped(e)} type='number' value={size.value} />
                        <More onClick={() => changeValue('increment')} className='selectorButton' />
                    </>
                    :

                    divergency && divergency.value !== size.value ?
                        <>
                            <span className={'strikethrough gradeNumber'}>{size.value}</span>
                            <span >{divergency.value}</span>
                        </>
                        :
                        <span className={'gradeNumber'}>
                            {size.value}
                        </span>

                }
            </td>
        </tr>
    )
}

export default TableBodyRow