const CompararGrades = (divergency, grid) => {
    const result = [];
    if (divergency) {
      Object.keys(divergency).forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(grid, key)) {
          const diff = divergency[key] - grid[key];
          if (diff > 0) {
            result.push(['sobra', key, Math.abs(diff)]);
          } else if (diff < 0) {
            result.push(['falta', key, Math.abs(diff)]);
          }
        }
      });
    }
    return result;
};

export default CompararGrades;