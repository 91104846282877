import React from "react"
import s from './Products.module.css'

import SeparateLine from '../SeparateLine/SeparateLine';
import { Link } from "react-router-dom";

const ProductItem = (props) => {
    const { title, infos, quantity, linkColumn } = props

    return (
        <li onClick={linkColumn?.click} className={s.product}>
            <Link to={linkColumn?.route}>
                <div className={s.leftContent}>
                    <div>{title?.Icon}</div>
                    <div>
                        <h4 className={s.productTitle}>{title?.name} {title?.id}</h4>
                        {infos?.map((inf, index) => <div key={index}>{inf.value}</div>)}
                    </div>
                </div>

                <div className={s.rightContent}>
                    <small> {quantity?.number} {quantity?.name} </small>
                    {quantity?.Icon}
                </div>
            </Link>
            <SeparateLine />
        </li>
    )
}

export default ProductItem