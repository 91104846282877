import React from 'react';
import { Grid } from '@mui/material';
import { useFontStyles } from '../helpers/styleHelper';

const TabelaResumo = (props) => {

    const classes = useFontStyles();

    return <Grid container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        xs={12}
        style={{ paddingBottom: '20px' }}
    >
        <Grid container direction="column" alignContent="flex-start" justifyContent="left" alignItems="flex-start" style={{ backgroundColor: 'rgba(198, 164, 137, 0.28)', width: 'auto', paddingTop: '10px', paddingBottom: '10px', paddingRight: '10%', paddingLeft: '10%' }}>
            <Grid item style={{ paddingLeft: '50px' }}>
                <span className={`${classes.flamaM} ${classes.s8}`} style={{ backgroundColor: '#C6A489', color: '#ffffff', padding: '10px' }}>{props.title}</span>
            </Grid>

            {props.indicatorsList.map(i => <>
                <Grid item style={{ marginTop: '10px' }}>
                    <span className={`${classes.flamaM} ${classes.s6}`} style={{ color: '#808080' }}>{i.indicator}</span>
                </Grid>
                <Grid item>
                    <span className={`${classes.flamaBook} ${classes.s5} `} style={{ color: '#808080' }}>{i.value}</span>
                </Grid>
            </>)}

        </Grid>
    </Grid>
}

export default TabelaResumo;