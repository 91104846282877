import React, { useCallback, useEffect } from 'react';
import TableButton from '../../../../components/SomaStandard/TableComponents/TableButton/TableButton'
import DeleteIcon from '@mui/icons-material/Delete';
// import EditIcon from '@mui/icons-material/Edit';
import { toast } from 'react-toastify';
import useApi from '../../../../Hooks/useApi';
import MaisAtacado from '../../../../Services/MaisAtacado';
import EditIcon from '@mui/icons-material/Edit';
import './AffiliateButtonGroup.css'

const AffiliateButtonGroup = (props) => {

  const { setOpenModalEdit, setEditInfo, line } = props;
  const { dispatch: removeVendedor, data, error } = useApi(MaisAtacado.Afiliados.removeVend);

  const onClickHandler = useCallback(() => {
    setOpenModalEdit(true)
    setEditInfo(line.info)
  }, [setOpenModalEdit, setEditInfo, line])

  useEffect(() => {
    const reload = props.reload;
    if (data && data.success) {
      toast.success("Vendedor removido com sucesso", { theme: "colored" });
      reload()
    }
  }, [data, props.reload]);

  useEffect(() => {
    if (error) {
      toast.error("Encontramos algum erro", { theme: "colored" });
    }
  }, [error]);

  return (
    <div className="div__affiliatebuttonsgroupd--conteiner">
      <div style={{marginRight:"16px"}}>
        <TableButton clicked={onClickHandler}>
          <EditIcon></EditIcon>
          <span>editar</span>
        </TableButton>
      </div>

      <TableButton clicked={
        async () => {
          const newConfirm = () => async () => {
            props.setLoadingRemoveVendor(true)
            await removeVendedor(props.line.affiliate_code);
            props.setLoadingRemoveVendor(false)
            toast.success("Vendedor removido com sucesso", { theme: "colored" });
            props.setOpen(false);
            props.reload();
            
          }
          props.setOnConfirm(newConfirm);
          props.setOpen(true);
        }
      }>
        <DeleteIcon></DeleteIcon>
        <span>excluir</span>
      </TableButton>
      {/* <Button onClick={
      console.log('treta')
    }>
        <EditIcon></EditIcon>
    </Button> */}
    </div>
  );
};

export default AffiliateButtonGroup;