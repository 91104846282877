import React, { useRef, useState } from "react";

import { toast } from "react-toastify";

import CloseBtnImg from '../../../assets/images/icons/close_btn.svg';

import { ReactComponent as PDF } from '../../../assets/images/icons/pdf.svg';
import { ReactComponent as Upload } from '../../../assets/images/icons/upload.svg';

import './SomaFileUploader.css';

const SomaFileUploader = ({ setFile, setUploadStatus, maxSize, accepts, extraInfoText }) => {
    const fileInputRef = useRef(null);
    const [sent, setSent] = useState(false);
    const [fileInfo, setFileInfo] = useState({
        name: 'ARQUIVO.PDF',
        size: "0 MB"
    });

    const handleUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        const fileSize = file.size;
        const fileSizeFormatted = fileSize >= (1024 * 1024) ?
            `${(fileSize / (1024 * 1024)).toFixed(2)} MB` :
            `${(fileSize / 1024).toFixed(2)} KB`;

        if (fileSize > (maxSize || 10) * 1024 * 1024) {
            setSent(false);
            toast.error(`Tamanho do arquivo é superior a ${maxSize || "10"}MB.`, { theme: "colored" });
            fileInputRef.current.value = null;
            setUploadStatus(false);
        } else {
            setFileInfo({
                name: file.name,
                size: fileSizeFormatted
            });
            setUploadStatus(true);
            setSent(true);
            setFile(file);
        }
    }

    return (
        <div>
            {sent ?
                <div>
                    <div className="div__somafileuploader--sent-container">
                        <div className="div__somafileuploader--sent-info-container">
                            <div>
                                <PDF className='div__somafileuploader--pdf-icon' />
                            </div>
                            <div className="div__somafileuploader--sent-info">
                                <h4>{fileInfo.name}</h4>
                                <p>{fileInfo.size}</p>
                                <div className="div__somafileuploader--completed" />
                            </div>
                        </div>
                        <div>
                            <img src={CloseBtnImg} alt="Botão de fechar" className="img__somafileuploader--close-icon" onClick={() => { setSent(false); setUploadStatus(false) }} />
                        </div>
                    </div>
                    <p>{extraInfoText || `Somente documentos com tamanho inferior a ${maxSize || "10"}MB.`}</p>
                </div>
                :
                <div>
                    <div className="div__somafileuploader--container" onClick={handleUploadClick}>
                        <label htmlFor="file" className="label__somafileuploader-container">
                            <Upload className='icon__somafileuploader--upload-icon' />
                            <div> {"Clique aqui para anexar seu documento"} </div>
                        </label>
                        <input
                            type="file"
                            id="upload"
                            accept={accepts}
                            className='input__somafileuploader--hideUpload'
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                    </div>
                    <p>{extraInfoText || `Somente documentos com tamanho inferior a ${maxSize || "10"}MB.`}</p>
                </div>
            }
        </div>
    )
}

export default SomaFileUploader