import React, { useContext, useReducer } from 'react';
import MaisAtacado from '../Services/MaisAtacado';
import GerarEtiquetaDevolucao from "../Utils/GerarEtiquetaDevolucao";
import handleApi from '../helpers/apiHelper'

/** @typedef { 'nfeScanner' | '' | 'nfeBoxes' | 'nfeBoxItems/:boxId' |'nfeBoxItems/:boxId/insert/:itemId'  |'nfeBoxItems/:boxId/edit/:itemId' | 'nps'  }  Screen */
/** @typedef {'scannerNfe' | 'typeNfe' | 'showNfeBoxes' | 'showNfeBoxItems' |'showNfeBoxInsertItem'| 'showNfeItemEdit' | 'loading' | 'getDataNfe' | 'getErrorNfe' | 'goBack' | 'done' | 'nps'} ActionType */

/** @typedef {{ type: ActionType }}  Action */
/** @typedef {{ screen: Screen, data: object, loading: boolean, error: string, prevState: State}} State */

/** @param {State} state @param {Action} action @returns {State } */
const formReducer = (state, action) => {
	switch (action.type) {
		case 'statusEntrega':
			return state = { ...state, screen: action.statusId, prevState: state }
		case 'showNfeBoxes':
			return state = { ...state, screen: 'nfeBoxes', prevState: state }
		case 'showNfeBoxItems':
			return state = { ...state, screen: `nfeBoxItems/${action.boxId}`, prevState: state }
		case 'showNfeBoxInsertItem':
			return state = { ...state, screen: `nfeBoxItems/${action.boxId}/insert`, prevState: state }
		case 'showNfeItemEdit':
			return state = {
				...state,
				screen: `nfeBoxItems/${action.boxId}/edit/${action.produtoId}/${action.corId}/${action.pedidoId}/${action.itemId}`,
				prevState: state,
				data: { ...state.data, novoProduto: action.value }
			}
		case 'showDivergenciasEntrega':
			return state = { ...state, screen: `divergenciasEntrega`, prevState: state }
		case 'nps':
			return state = { ...state, data: { ...state.data, nps: action.value }, screen: `nps`, prevState: state }

		case 'loading':
			return state = { ...state, loading: action.loading }
		case 'atualizarState':
			return state = {
				...state,
				loading: action.loading,
				error: action.error,
				data: action.data
			}

		case 'goBack':
			return state = { ...state.prevState, data: state.data, ...action.value }
		case 'done':
			return state = formReducerInitialValue

		default:
			return state
	}
}

/** @type { State } */
//nfeBoxItems/04973033/edit/300281
const formReducerInitialValue = { screen: '3', data: null, loading: false, error: '' }

const NfeProvider = React.createContext();

const NfeCaixaFlowProvider = (props) => {

	const [state, dispatch] = useReducer(formReducer, formReducerInitialValue);

	const typeStatusEntrega = (statusId) => dispatch({ type: 'statusEntrega', statusId: statusId.toString() })

	const onResultsNfe = async (nfes) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Recebimento.getBoxNfe, ({ chave_nfe: nfes }));
		dispatch({ type: 'atualizarState', loading: false, data, error })
		dispatch({ type: 'showNfeBoxes' });
	}

	const ondivergencyNfe = async (product) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Recebimento.setDivergencyNfe, (product));
		dispatch({ type: 'atualizarState', loading: false, data, error })
		dispatch({ type: 'goBack', value: { screen: `nfeBoxItems/${product.caixa}#${product.produto}` } })
	}

	const finishBoxNfe = async (nfe) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Recebimento.finishBoxNfe, (nfe));
		dispatch({ type: 'atualizarState', loading: false, data, error })
		dispatch({ type: 'goBack' })
	}

	const updateViolatedBox = async (nfe) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Recebimento.updateViolatedBox, (nfe));
		dispatch({ type: 'atualizarState', loading: false, data, error })
	}

	const finishRecebimento = async () => {
		const boxesToFinish = [];
		const nfs = new Set();

		state.data.CAIXAS.forEach(caixa => {
			caixa.produtos.forEach(produto => {
				const CHAVE_NFE = produto.chave_nfe;
				const NF_SAIDA = produto.nf_saida.trim();
				const SERIE_NF = produto.serie_nf.trim();

				nfs.add(`${CHAVE_NFE}-${NF_SAIDA}-${SERIE_NF}`);

				if (caixa.lockedAt === null || caixa.lockedAt === undefined) {
					const indexOfExistingBox = boxesToFinish.findIndex(b => b.caixa === caixa.caixa);

					const nfeDetails = [{ CHAVE_NFE, NF_SAIDA, SERIE_NF }];
					const newBoxData = { nfeDetails, caixa: caixa.caixa, entrega: state.data.ROMANEIO, caixaViolada: caixa.caixaViolada || 0 };

					if (indexOfExistingBox !== -1) {
						const isChaveNfeAlreadyAdded = boxesToFinish[indexOfExistingBox].nfeDetails.some(e => e.CHAVE_NFE === CHAVE_NFE)
						if (!isChaveNfeAlreadyAdded) {
							boxesToFinish[indexOfExistingBox].nfeDetails.push(nfeDetails[0]);
						}
					} else {
						boxesToFinish.push(newBoxData);
					}
				}
			});
		});

		const chavesNfes = Array.from(nfs).map(nf => {
			const [chaveNfe, nfSaida, serieNf] = nf.split('-');
			return { chaveNfe, nfSaida, serieNf };
		});

		const dataNfs = { entrega: state.data.ROMANEIO, nfs: chavesNfes, boxesToFinish };

		dispatch({ type: 'loading', loading: true })
		const { data } = await handleApi(MaisAtacado.Recebimento.finishRecebimento, (dataNfs));
		dispatch({ type: 'loading', loading: false })
		dispatch({ type: 'nps', value: data, loading: false, });
	};

	const registerNps = (user, { q1, q2, q3, q4, comment }) => {
		const nfs = new Set();

		state.data.CAIXAS.forEach(caixa => {
			caixa.produtos.forEach(produto => {
				const chaveNfe = produto.chave_nfe;
				nfs.add(chaveNfe);
			});
		});
		const chavesNfes = Array.from(nfs).map(chave => chave);
		const dataNps = {
			id_question: [2, 3, 4, 5],
			score: [q1, q2, q3, q4],
			comments: comment,
			infos: { entrega: state.data.ROMANEIO, chaves_nfes: chavesNfes }
		};

		handleApi(MaisAtacado.Recebimento.registerNps, (dataNps));
		dispatch({
			type: 'atualizarState',
			loading: false,
			data: { ...state.data, nps: null },
			error: undefined
		})
	};

	const goNextPageAfterNps = () => {
		dispatch({ type: 'done' });
	}

	const registerKeepProduct = async (products) => {
		const { error } = await handleApi(MaisAtacado.Recebimento.registerKeepProduct, (products));
		error && dispatch({ type: 'goBack' })
	}

	const salvarMensagem = async (entrega, mensagem, clifor, destinatario, first) => {
		const { data, error } = await handleApi(MaisAtacado.Tratativas.salvarMensagem, { entrega, mensagem, clifor, destinatario, first });
		!first && data && dispatch({ type: 'atualizarState', data, error })
	}

	const tratativaMultimarca = async (entrega, clifor) => {
		const { data, error } = await handleApi(MaisAtacado.Tratativas.tratativaMultimarca, { entrega: entrega.toString(), clifor });
		dispatch({ type: 'atualizarState', data, error })
		data && dispatch({ type: 'showDivergenciasEntrega' })
	}

	const sendFile = async (entrega, clifor, files, divergencia) => {
		dispatch({ type: 'loading', loading: true });

		let res = await handleApi(MaisAtacado.Tratativas.uploadFile, { entrega, clifor, files });
		if (!res.error) {
			const { data, error } = await handleApi(MaisAtacado.Tratativas.atualizarStatus, { ...divergencia, ...res.data });
			data && dispatch({ type: 'atualizarState', data, error })
		}

		dispatch({ type: 'loading', loading: false })
	}

	const getEtiquetaDevolucao = async (clifor, entrega) => {
		dispatch({ type: 'loading', loading: true });

		let res = await handleApi(MaisAtacado.Tratativas.getEtiquetaDevolucao, { clifor });
		if (!res.error) {
			await GerarEtiquetaDevolucao(res.data, entrega)
		}

		dispatch({ type: 'loading', loading: false })
	}

	const download = async (entrega, clifor, downloadFileType, idArquivo) => {
		dispatch({ type: 'loading', loading: true })
		const { data } = await handleApi(MaisAtacado.Tratativas.downloadFile, { entrega, clifor, idArquivo });
		if (data) {
			const blob = new Blob([new Uint8Array(data[0].data)], { type: 'application/pdf' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `Entrega-${entrega}.${downloadFileType}`;
			link.click();
			URL.revokeObjectURL(url);
		}
		dispatch({ type: 'loading', loading: false })
	}

	return (
		<NfeProvider.Provider value={{
			state,
			dispatch,
			typeStatusEntrega,
			onResultsNfe,
			ondivergencyNfe,
			registerNps,
			goNextPageAfterNps,
			finishBoxNfe,
			finishRecebimento,
			updateViolatedBox,
			registerKeepProduct,
			salvarMensagem,
			tratativaMultimarca,
			sendFile,
			getEtiquetaDevolucao,
			download
		}} >
			{props.children}
		</NfeProvider.Provider>
	);
};

/** @returns {{ state: State, dispatch: React.Dispatch<Action> }} */
const useNfeCaixaFlow = () => useContext(NfeProvider);

export { NfeCaixaFlowProvider };
export default useNfeCaixaFlow;
