
import React from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';

const SomaConfirm = (props) => {
    return <Dialog open={props.open}>
        <DialogTitle>
            {props.title || 'Tem Certeza?'}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                {props.message || 'Tem certeza disso?'}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                if (props.onCancel)
                    props.onCancel()
            }}>
                {props.cancelText || 'Cancelar'}
            </Button>
            <Button onClick={() => {
                if (props.onConfirm)
                    props.onConfirm()
            }}>
                {props.wait ? <CircularProgress color='secondary' size={24} /> :props.confirmText || 'OK'}
            </Button>
        </DialogActions>
    </Dialog>
}

export default SomaConfirm