import React from "react";

import BackgroundDesktop from "../../../../../assets/images/HomeConteudos/BackgroundDesktop.svg";
import BackgroundMobileText from "../../../../../assets/images/HomeConteudos/BackgroundMobileText.svg";
import BackgroundMobileImage from "../../../../../assets/images/HomeConteudos/BackgroundMobileImage.svg";

import "./HomeConteudos.css";

const HomeConteudos = () => (
  <div className="div__homeconteudos--container">
    <div className="div__homeconteudos--infocontainer">
      <button className="div__homeconteudos--button" onClick={()=>window.location.replace("/Material/saiba-mais")}>
        IR PARA CONTEÚDOS
      </button>
      <img src={BackgroundDesktop} alt="Mais atacado menu"  className="img__homeconteudos--background"/>
      <img src={BackgroundMobileText} alt="Mais atacado menu"  className="img__homeconteudos--background-mobile"/>
      <img src={BackgroundMobileImage} alt="Mais atacado menu"  className="img__homeconteudos--background-mobile"/>
    </div>
  </div>
);

export default HomeConteudos;
