import React from 'react'
import s from './Historic.module.css'

const Historic = (props) => {
    const { title, infos } = props

    return (
        <section className={s.Historic} >
            <div className={s.Detail} >
                {title?.Icon}
                <h4>{title?.name} {title?.id}</h4>
            </div>

            <article>
                {infos?.map((inf, index) => (
                    <div key={index} className={s.groupInfo}>
                        <span className={s.topic}>{inf.title}</span>
                        <span className={s.message}>
                            {inf.message}
                            <button className={s.buttonMessage} onClick={inf.button}>{inf.buttonText}</button>
                        </span>
                    </div>
                ))}
            </article>

        </section>
    )
}

export default Historic