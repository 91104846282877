import React from 'react'
import "./twoValues.css"
import ConverteDinheiro from '../ConverteDinheiro'

const TwoValues = props =>{

    const classes = []


    if(props.col.highlighted){
        classes.push("span__twovaluehighlighted--value")
    } else{
        classes.push("span__twovalue--value")
    }
    
    return(
        <div className="div__twovalue--conteiner">
        {props.col.value ? <props.col.value line={props.line}/> : props.children}
        <span className={classes.join(' ')}>
        {props.col.money ? <ConverteDinheiro>{props.line[props.col.propName2]}</ConverteDinheiro> : props.col.underValue ? <props.col.underValue line={props.line}/> : props.line[props.col.propName2]}
        </span>
        </div>
    )
}

export default TwoValues