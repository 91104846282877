import React, { useContext, useEffect, useState, useMemo } from "react";
import MaisAtacado from "../Services/MaisAtacado";
import useApi from "./useApi";

const UserContext = React.createContext();

const UserContextProvider = (props) => {
  const storedUser = localStorage.getItem("CurUser");
  const [user, setUser] = useState(MaisAtacado.Auth.decodeUser(storedUser));

  const login = useApi(MaisAtacado.Auth.login);
  const retoken = useApi(MaisAtacado.Auth.retoken);
  const retokenSomaAuth = useApi(MaisAtacado.Auth.retokenSomaAuth);
  const logoff = useApi(MaisAtacado.Auth.logoff);
  const impersonate = useApi(MaisAtacado.Auth.impersonate);

  const suggestReset = useApi(MaisAtacado.Auth.suggestPasswordReset);
  const acceptReset = useApi(MaisAtacado.Auth.acceptPasswordReset);

  const startPasswordChange = useApi(MaisAtacado.Auth.startPasswordChange);
  const endPasswordChange = useApi(MaisAtacado.Auth.endPasswordChange);
  const checkPasswordChange = useApi(MaisAtacado.Auth.checkPasswordChange);

  useEffect(() => {
    if (retoken.data) {
      localStorage.setItem("CurUser", retoken.data);
      setUser(MaisAtacado.Auth.decodeUser(retoken.data))
    }
  }, [retoken.data]);

  useEffect(() => {
    if (login.data) {
      localStorage.setItem("CurUser", login.data);
      setUser(MaisAtacado.Auth.decodeUser(login.data))
    }
  }, [login.data]);

  useEffect(() => {
    if (impersonate.data) {
      localStorage.setItem("CurUser", impersonate.data);
      setUser(MaisAtacado.Auth.decodeUser(impersonate.data))
    }
  }, [impersonate.data]);

  useEffect(() => {
    const decode = MaisAtacado.Auth.decodeUser(storedUser);

    if (!decode?.features) {
      localStorage.removeItem("CurUser");
      setUser(undefined);
    }
    else if (user?.iat !== decode?.iat) {
      setUser(decode);
    }
  }, [storedUser, user]);

  useEffect(() => {
    if (logoff.data || logoff.error) {
      localStorage.removeItem("CurUser");
      localStorage.removeItem("userAnswered");
      setUser(undefined);
    }
  }, [logoff.data, logoff.error]);

  useEffect(() => {
    const logoffDispatch = logoff.dispatch;
    const checkExpiry = () =>
      user && user.exp <= new Date().getTime() / 1000 && logoffDispatch();
    checkExpiry();
    const interval = setInterval(checkExpiry, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [user, logoff.dispatch]);

  const userCtxData = useMemo(
    () => ({
      retoken,
      retokenSomaAuth,
      user,
      login,
      impersonate,
      logoff,
      suggestReset,
      acceptReset,
      startPasswordChange,
      endPasswordChange,
      checkPasswordChange,
    }),
    [
      retoken,
      retokenSomaAuth,
      user,
      login,
      impersonate,
      logoff,
      suggestReset,
      acceptReset,
      startPasswordChange,
      endPasswordChange,
      checkPasswordChange,
    ]
  );

  useEffect(() => {
    window.addEventListener('storage', () => window.location.reload());
    return () => window.removeEventListener('storage', () => window.location.reload())
  }, [])

  return (
    <UserContext.Provider value={userCtxData}>
      {props.children}
    </UserContext.Provider>
  );
};

const useUser = () => {
  const { user } = useContext(UserContext);
  return user;
};

const useAuth = () => {
  const { login, logoff, impersonate, retoken, retokenSomaAuth } =
    useContext(UserContext);
  return { login, logoff, impersonate, retoken, retokenSomaAuth };
};

const usePasswordReset = () => {
  const { suggestReset, acceptReset } = useContext(UserContext);
  return {
    suggestReset, acceptReset
  };
};

const usePasswordChange = () => {
  const { startPasswordChange, endPasswordChange, checkPasswordChange } = useContext(UserContext);
  return {
    startPasswordChange, endPasswordChange, checkPasswordChange
  };
};

export { UserContextProvider, useAuth, usePasswordReset, usePasswordChange };
export default useUser;
