import React from "react";
import LogoSoma from "../../assets/images/icons/logoSoma.svg";

import styles from "./Logo.module.css";

const LogoGrupoSoma = (props) => (
  <div className={styles.logoSoma}>
    <img data-testid="logoGrupoSoma" src={LogoSoma} alt="Grupo Soma logo" />
  </div>
);

export default LogoGrupoSoma;
