import { useState } from 'react';
import styles from './Item.module.css';

import Modal from '../../Modal/Modal';
import Tag from '../../Tag/Tag';
import PrimaryButton from '../../PrimaryButton/PrimaryButton';
import SecondaryButton from '../../SecondaryButton/SecondaryButton';

import DownloadIcon from '@mui/icons-material/Download';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';

const Item = ({ item, widthCard }) => {
    const [open, setOpen] = useState(false)
    const [buttonLeft, setButtonLeft] = useState({ loading: false, disabled: item.leftBtn.disabled })
    const [buttonRight, setButtonRight] = useState({ loading: false, disabled: item.rightBtn.disabled })

    const onClickButtonLeft = async () => {
        setButtonLeft({ loading: true, disabled: true })
        await item.leftBtn.onClick()
        setButtonLeft({ loading: false, disabled: item.leftBtn.disabled })
    }
    const onClickButtonRight = async () => {
        setButtonRight({ loading: true, disabled: true })
        await item.rightBtn.onClick()
        setButtonRight({ loading: false, disabled: item.leftBtn.disabled })
    }
    return (
        <>
            <div
                onClick={() => setOpen(true)}
                className={styles["ItemsRow--item"]}
                style={{ width: widthCard, backgroundColor: item.infos.thumb }}
            >
                {item.tag.Show && <Tag name={item.tag.name} color={item.tag.color} style={{ position: 'absolute', zIndex: 1, margin: '5px 10px' }} />}
                <span>{item.infos.name}</span>

            </div>

            <Modal
                style={{ width: window.innerWidth > 480 ? '65%' : '100%' }}
                modalName={
                    <div className={styles["ItemsRow--modal"]} style={{ backgroundColor: item.infos.thumb }}>
                        <span>{item.infos.name}</span>
                    </div>
                }
                open={open}
                onClose={() => setOpen(false)}
                notInputs={true}
            >
                <div className={styles["ItemsRow--modal_divButton"]}>
                    <PrimaryButton
                        additionalClassName={styles["button"]}
                        {...item?.leftBtn}
                        onClick={onClickButtonLeft}
                        disabled={buttonLeft.disabled}
                    >
                        {buttonLeft.loading ?
                            <div className={styles["loader"]} /> :
                            <>
                                <OpenInFullIcon fontSize='small' />
                                <span style={{ verticalAlign: 'super' }}>{item.leftBtn.name}</span>
                            </>
                        }
                    </PrimaryButton>

                    <SecondaryButton
                        additionalClassName={`${styles["button"]} ${styles["button_secondary"]}`}
                        {...item?.rightBtn}
                        onClick={onClickButtonRight}
                        disabled={buttonRight.disabled}
                    >
                        {buttonRight.loading ?
                            <div className={`${styles["loader"]} ${styles["loader_secondary"]}`} /> :
                            <>
                                <DownloadIcon fontSize='small' />
                                <span style={{ verticalAlign: 'super' }}>{item.rightBtn.name}</span>
                            </>
                        }
                    </SecondaryButton>
                </div>
                <div dangerouslySetInnerHTML={{ __html: item.infos.description }} />
                <div>{item.rodape}</div>
            </Modal >
        </>
    )
}

export default Item