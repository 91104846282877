import s from './InputRange.module.css';

const InputRange = ({ range, handleChange, value }) => {
    return (
        <div className={s.container}>
            <span className={s.current_value}>{value}</span>
            <input
                className={s.input_range}
                type="range"
                min={range.min}
                max={range.max}
                value={range.value}
                onChange={(e) => handleChange(e.target.value)}
            />

            <div className={s.container_range}>
                <span>{range.minFormated}</span>
                <span>{range.maxFormated}</span>
            </div>

        </div>
    );
};

export default InputRange;
