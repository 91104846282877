import React from 'react';

import { Table, TableBody, TableCell, TableRow, TableHead, TableFooter, TablePagination } from '@mui/material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';

const SomaTableOld = (props) => {
  const isDefined = (val) => val !== null && val !== undefined;

  const handleChangePage = (event, newPage) => {
    props.setPage(newPage);
  };
  
  const handleChangeRowsPerPage = event => {
    props.setRowsPerPage(parseInt(event.target.value, 10));
    props.setPage(0);
  };
  
  return (
    props.results ?
      <Table stickyHeader size={props.size ? props.size : "medium"} style={props.style}>
        {props.columns.map(col => col.propText).reduce((a, b) => a || isDefined(b)) ?
          <TableHead>
            <TableRow hover role="checkbox">
              {props.columns.map((col, i) => <TableCell key={"col-" + i}>{col.propText}</TableCell>)}
            </TableRow>
          </TableHead>
          : null
        }
        <TableBody align="center">
          {
            props.results
            .filter(props.filter || (()=>1))
            .sort(props.sort || (()=>0))
            .map((res, i) =>
              <TableRow hover role="checkbox" key={"line-" + i} >
                {props.columns.map((col, j) => (
                  col.component ?
                    <TableCell key={"line-" + i + "-col-" + j} padding="none">
                      <col.component {...col.componentProps} line={res}>{res[col.propName]}</col.component>
                    </TableCell>
                    :
                    <TableCell key={"line-" + i + "-col-" + j}>{res[col.propName]}</TableCell>
                ))}
              </TableRow>)
          }
        </TableBody>
        {[props.total, props.rowsPerPage, props.page, props.setRowsPerPage, props.setPage].reduce((a, b) => a && isDefined(b)) ?
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                count={props.total}
                rowsPerPage={props.rowsPerPage}
                page={props.page}
                SelectProps={{
                  inputProps: { 'aria-label': 'rows per page' },
                  native: true,
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions} />
            </TableRow>
          </TableFooter>
          : null
        }
      </Table>
      :
      null
  )
}
export default SomaTableOld;