import React from 'react'
import s from './TitleBox.module.css'

const TitleBox = (props) => {
    const { title } = props

    return (
        <>
            <div className={s.titleIcon}>
                {title?.Icons?.map((Icon, i) => (<React.Fragment key={i}>{Icon}</React.Fragment>))}
            </div>
            <div className={s.itemTitle}>
                <h5>{title?.id} {title?.description}</h5>
            </div>
        </>

    )
}

export default TitleBox