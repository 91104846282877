import React, { useRef, useState } from 'react';
import { Box, Grid, Button, Typography } from '@mui/material';
import Modal from "../../../components/Modal";
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import MaisAtacado from '../../../Services/MaisAtacado';
import { toast } from 'react-toastify';

const GerarPagamentoButton = (props) => {
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState('');
  const [uploading, setUploading] = useState(false);
  const el = useRef();
  let hasProfile2 = Object.values(props.options).some(i => i.selected && i.id_profiles === 2)

  const handleOpen = () => {
    let periodo = [];
    let opts = props.options;

    Object.keys(opts).forEach(item => {
      if (opts[item].selected === true) {
        periodo.push(item);
      }
    });

    if (periodo.length === 0) {
      toast.error("Selecione um periodo.", { theme: "colored" });
      setFile('');
      setOpen(false);
      return;
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleVoltar = () => {
    let opts = props.options;
    props.setComissaoTotal(0);
    props.setCredito(0);
    props.setDeposito(0);

    Object.keys(opts).forEach(item => {
      opts[item] = {
        ...opts[item],
        selected: false
      };
    });

    props.setCheckBox(false);
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    if (file.type === "application/pdf") {
      setFile(file);
    } else {
      toast.error("Formato do arquivo inválido. Verifique se o formato é PDF.");
    }
  };

  const uploadFile = async () => {

    if (!file && hasProfile2) {
      toast.error("Anexe um arquivo.", { theme: "colored" });
      return;
    }

    let opts = props.options;
    let periodo = [];

    Object.keys(opts).forEach(item => {
      if (opts[item].selected === true) {
        periodo.push(item);
      }
    });

    if (periodo.length === 0) {
      toast.error("Selecione um periodo.", { theme: "colored" });
      setFile('');
      setOpen(false);
      return;
    }

    setOpen(false)
    setUploading(true)
    toast.warning("Gerando Comissão!", { theme: "colored" })

    await MaisAtacado.Afiliados.minhasVendasUploadFile(file, periodo).then(res => {
      console.log(res);
      setOpen(false);
      setUploading(false);
      setFile('');
      toast.success("Sucesso!", { theme: "colored" });

      props.upload(true);
      props.setComissaoTotal(0);
      props.setCredito(0);
      props.setDeposito(0);
      Object.keys(opts).forEach(item => {
        opts[item].selected = false;
      });
      props.setCheckBox(false);

    }).catch(err => {
      console.log(err.message);
      setOpen(false);
      setUploading(false);
      if (err && err.errorId && err.errorId !== 'unhandled-error') {
        toast.error(err.message, { theme: "colored" });
      } else {
        toast.error("Erro.", { theme: "colored" });
      }

      setFile('');
    });
  };

  return (
    <div>
      <Button onClick={handleVoltar} fullWidth style={{ marginRight: '2%', width: 70, height: 45, border: '4px solid white', backgroundColor: "white", color: '#AD9078', fontWeight: 600, fontFamily: 'flama-medium', fontSize: 14 }}> VOLTAR </Button>
      {(hasProfile2) ?
        <Button onClick={handleOpen} fullWidth style={{ width: 225, height: 45, border: '4px solid #AD9078', backgroundColor: "#AD9078", color: 'white', fontWeight: 600, fontFamily: 'flama-medium', fontSize: 14 }}>
          Gerar Pagamento
        </Button>
        :
        <Button onClick={uploadFile} disabled={uploading} fullWidth style={{ width: 225, height: 45, border: '4px solid #AD9078', backgroundColor: "#AD9078", color: 'white', fontWeight: 600, fontFamily: 'flama-medium', fontSize: 14 }}>
          Gerar Pagamento
        </Button>
      }
      <Modal
        open={open}
        modalName={"GERAR PAGAMENTO"}
        actionName={"ENVIAR"}
        action={uploadFile}
        onClose={handleClose}
        isUploading={uploading}
      >

        <Box width={"475px"}>
          <Typography style={{ color: "#8B8B8B" }}>
            Estamos quase lá! Para iniciar o processo de pagamento
          </Typography>
          <Typography style={{ color: "#8B8B8B" }}>
            da(s) vendas(s) selecionada(s), é preciso anexar a NOTA FISCAL
          </Typography>
          <Typography style={{ color: "#8B8B8B" }}>
            no valor de <ConverteDinheiro children={props.comissaoTotal} />
          </Typography>
        </Box>

        <Grid container>
          <Grid item xs={12} align='center' style={{ padding: '15px' }}>
            <label align="center" htmlFor="upload-file">
              <input
                style={{ display: 'none' }}
                id="upload-file"
                name="file"
                type="file"
                ref={el}
                onChange={handleChange}
              />

              <Button color="secondary" variant="contained" component="span" style={{ color: 'white' }}>
                {(file) === '' ?
                  "ANEXAR ARQUIVO"
                  :
                  file.name
                }
              </Button>

            </label>
          </Grid>
        </Grid>

      </Modal>

    </div>
  );

};

export default GerarPagamentoButton;
