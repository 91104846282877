import React, { useState } from 'react'
import { Checkbox } from '@mui/material';

import s from './Styles.module.css';

const TitlesSelector = ({ resume, clickCheckbox, faturas }) => {

    const [selectedItems, setSelectedItems] = useState(faturas.map(item => item.id)); // Todos começam selecionados

    const handleCheckboxChange = (id) => {
        const newSelectedItems = selectedItems.includes(id)
            ? selectedItems.filter(itemId => itemId !== id)
            : [...selectedItems, id]

        setSelectedItems(newSelectedItems);
        clickCheckbox(newSelectedItems)
    };

    return (
        <div>
            <div className={s['div__titlesSelector--checkboxes-container']}>
                <div className={s['div__titlesSelector--checkboxes-containeraaaaaa']}>
                    {faturas.map((item) => (
                        <div key={item.id} className={s['div__titleSelector--checkbox']}>
                            <Checkbox
                                checked={selectedItems.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                            />
                            <p>{item.idFormated} {item.valueFormated}</p>
                        </div>
                    ))}
                </div>
                <div className={s['div__titlesSelector--resume']} >
                    <p>{resume?.title}</p>
                    <p>{resume?.value}</p>
                </div>
            </div>
        </div>
    )
}

export default TitlesSelector;