import React, { useState } from 'react';
import { Grid, TextField, CircularProgress, Box } from '@mui/material';

import SecondaryButton from '../SecondaryButton/SecondaryButton'
import PrimaryButton from '../PrimaryButton/PrimaryButton'
import Show from './AuthComponents/Show';

const SomaForm = (props) => {
  const { columns, onSubmit, submitText, preload, onCancel, cancelText } = props;
  const [state, setState] = useState(preload || {});

  const [errors, setErrors] = useState({});
  const [wait, setWait] = useState(false);

  const validateState = () => {
    let newE = { ...errors }
    const valid = columns.map(c => {
      if (c.validate) {
        return newE[c.propName] = c.validate.map(v => v.test(state[c.propName]) ? false : v.message).filter(v => v)[0]
      }
      else {
        return false
      }
    }).reduce((a, b) => a || b)
    setErrors({ ...errors, ...newE })
    return valid;
  }

  const onFormSubmit = (e) => {
    setWait(true)
    e.preventDefault();
    if (!validateState()) {
      onSubmit(state).then(() => { setState({}) }).finally(e => setWait(false))
    } else {
      setWait(false)
    }
  }

  return (
    <form onSubmit={onFormSubmit} style={{ padding: "20px" }} >
      <Grid container direction='row' spacing={3}>
        {columns.map((c, i) =>
          <Grid key={`field-${i}`} item xs={c.size || 6} style={{ alignSelf: 'center' }}>
            <Show to={c.show}>
              <Box flex={1} padding='10px'
              // bgcolor="rgba(207, 188, 173, 0.20)"
              >
                {c.component ?
                  <c.component
                    error={errors[c.propName] ? true : false}
                    helperText={errors[c.propName]}
                    {...c.componentProps}
                    label={c.propText}
                    value={state[c.propName]}
                    disabled={c.disable?.[c.propName]}
                    onChange={(value) => setState({ ...state, [c.propName]: c.parse ? c.parse(value) : value })} />
                  :
                  <TextField
                    error={errors[c.propName] ? true : false}
                    helperText={errors[c.propName]}
                    label={c.propText}
                    value={state[c.propName] || ""}
                    onChange={({ target: { value } }) => setState({ ...state, [c.propName]: c.parse ? c.parse(value) : value })}
                    InputLabelProps={{ shrink: true }}
                    variant="standard"
                    disabled={c.disable?.[c.propName]}
                    color="secondary" fullWidth />
                }
              </Box>
            </Show>
          </Grid>
        )}
      </Grid>
      <br /><br />
      <Grid container justifyContent="flex-end" spacing={2}>
        {
          onCancel ?
            <Grid item>
              <SecondaryButton disabled={wait} onClick={onCancel} type="button">
                {cancelText || 'voltar'}
              </SecondaryButton>
            </Grid>
            : null
        }

        <Grid item>
          <PrimaryButton disabled={wait} type="submit" >
            {wait ? <CircularProgress color='secondary' size={24} /> : (submitText || 'Salvar')}
          </PrimaryButton>
        </Grid>
      </Grid>
    </form>
  )
}
export default SomaForm;