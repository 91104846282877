const checks = {
  length1: (pwd) => pwd.length >= 6,
  length2: (pwd) => pwd.length >= 8,
  upperCase: (pwd) => /[A-Z]/g.test(pwd),
  lowerCase: (pwd) => /[a-z]/g.test(pwd),
  numbers: (pwd) => /[0-9]/g.test(pwd),
  specialChars: (pwd) => /[^A-Za-z0-9]/g.test(pwd),
  matchConfirm: (pwd, conf) => pwd === conf,
};

const PasswordCheck = (password, confirm) => {
  const t = Object.fromEntries(Object.entries(checks).map(([n, c]) => [n, c(password, confirm)]));
  let pass = t.specialChars && t.lowerCase && t.upperCase && t.length1 && t.matchConfirm;
  if (!t.length2) pass = pass && t.numbers;

  return { pass, tests: t };
};

const ValidationMessages = {
  length1: 'ao menos 6 caracteres',
  length2: 'ao menos 8 caracteres',
  upperCase: 'ao menos uma letra maiúscula',
  lowerCase: 'ao menos uma letra minúscula',
  numbers: 'ao menos um digito',
  specialChars: 'ao menos um caracter especial',
  matchConfirm: 'senhas batem'
};

export default PasswordCheck;
export { ValidationMessages };
