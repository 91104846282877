import React, { useContext, useReducer } from 'react';
import MaisAtacado from '../Services/MaisAtacado';
import GerarEtiquetaDevolucao from "../Utils/GerarEtiquetaDevolucao";
import handleApi from '../helpers/apiHelper'

const formReducer = (state, action) => {
	switch (action.type) {
		case 'statusDivergencias':
			return state = { ...state, screen: action.statusId, prevState: state }
		case 'listarDivergencias':
			return state = { ...state, screen: '', prevState: state }
		case 'divergenciasEntrega':
			return state = { ...state, screen: `divergenciasEntrega`, prevState: state }

		case 'loading':
			return state = { ...state, loading: action.loading }
		case 'atualizarState':
			return state = {
				...state,
				loading: action.loading,
				error: action.error,
				data: action.data
			}

		case 'voltar':
			return state = { ...state.prevState, data: state.data, ...action.value }
		case 'terminar':
			return state = formReducerInitialValue

		default:
			return state
	}
}

const formReducerInitialValue = { screen: 'AGUARDANDO ANALISE', data: null, loading: false, error: '' }

const DivergenciaNfeProvider = React.createContext();

const DivergenciaFlowProvider = (props) => {
	const [state, dispatch] = useReducer(formReducer, formReducerInitialValue);

	const typeStatusDivergencias = (statusId) => dispatch({ type: 'statusDivergencias', statusId: statusId.toString() })

	const voltar = () => dispatch({ type: 'voltar' })

	const iniciarAtendimento = async (entrega, clifor) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Tratativas.iniciarAtendimento, { entrega, clifor });
		dispatch({ type: 'atualizarState', data, loading: false, error })

		dispatch({ type: 'divergenciasEntrega' })
	}

	const tratativaDivergencias = async (entrega, clifor) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Tratativas.mostrarDivergencias, { entrega, clifor });
		dispatch({ type: 'atualizarState', data, loading: false, error })

		dispatch({ type: 'divergenciasEntrega' });
	}

	const salvarMensagem = async (entrega, mensagem, clifor) => {
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Tratativas.salvarMensagem, { entrega, mensagem, clifor, destinatario: clifor });

		dispatch({ type: 'atualizarState', data, loading: false, error })
	}

	const atualizarStatus = async (divergencia) => {
		const { entrega, clifor, ...item } = divergencia
		dispatch({ type: 'loading', loading: true })
		const { data, error } = await handleApi(MaisAtacado.Tratativas.atualizarStatus, { entrega, clifor, ...item });

		dispatch({ type: 'atualizarState', data, loading: false, error })
	}

	const finalizarAtendimento = async (entrega, clifor) => {
		dispatch({ type: 'loading', loading: true })
		await handleApi(MaisAtacado.Tratativas.finalizarAtendimento, { entrega, clifor });
		dispatch({ type: 'terminar' })
	}

	const download = async (entrega, clifor, downloadFileType, idArquivo) => {
		dispatch({ type: 'loading', loading: true })
		const { data } = await handleApi(MaisAtacado.Tratativas.downloadFile, { entrega, clifor, idArquivo });
		if (data) {
			const blob = new Blob([new Uint8Array(data[0].data)], { type: 'application/pdf' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = `Entrega-${entrega}.${downloadFileType}`;
			link.click();
			URL.revokeObjectURL(url);
		}
		dispatch({ type: 'loading', loading: false })
	}

	const getEtiquetaDevolucao = async (clifor, entrega) => {
		dispatch({ type: 'loading', loading: true });

		let res = await handleApi(MaisAtacado.Tratativas.getEtiquetaDevolucao, { clifor });
		if (!res.error) {
			await GerarEtiquetaDevolucao(res.data, entrega)
		}

		dispatch({ type: 'loading', loading: false })
	}

	return (
		<DivergenciaNfeProvider.Provider value={{
			state,
			voltar,
			typeStatusDivergencias,
			iniciarAtendimento,
			tratativaDivergencias,
			salvarMensagem,
			atualizarStatus,
			finalizarAtendimento,
			download,
			getEtiquetaDevolucao
		}} >
			{props.children}
		</DivergenciaNfeProvider.Provider>
	);
};

const useDivergenciaFlow = () => useContext(DivergenciaNfeProvider);

export { DivergenciaFlowProvider };
export default useDivergenciaFlow;
