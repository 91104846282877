const status = [
    {
        "id_status": 1,
        "type": "Finalizado com divergência",
        "created_at": "2023-10-10 11:37:52",
        "id_status_parent": 1
    },
    {
        "id_status": 2,
        "type": "Finalizado",
        "created_at": "2023-10-10 11:39:21",
        "id_status_parent": 1
    },
    {
        "id_status": 3,
        "type": "sobra",
        "created_at": "2023-11-21 14:07:51",
        "id_status_parent": 2
    },
    {
        "id_status": 4,
        "type": "falta",
        "created_at": "2023-11-21 14:07:51",
        "id_status_parent": 2
    },
    {
        "id_status": 5,
        "type": "sobra/falta",
        "created_at": "2023-11-21 14:07:51",
        "id_status_parent": 2
    },
    {
        "id_status": 6,
        "type": "Aguardando conferência",
        "created_at": "2023-12-06 11:01:32",
        "id_status_parent": 1
    },
    {
        "id_status": 7,
        "type": "Novo",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 8,
        "type": "Dados insuficientes",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 9,
        "type": "Dados ajustados",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 19,
        "type": "Enviar NF de espelho",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta']
    },
    {
        "id_status": 20,
        "type": "NF de espelho enviada",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta']
    },
    {
        "id_status": 10,
        "type": "Enviar NF de devolução",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta']
    },
    {
        "id_status": 11,
        "type": "NF de devolução enviada",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta']
    },
    {
        "id_status": 16,
        "type": "Enviar imagem da ressalva",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta']
    },
    {
        "id_status": 17,
        "type": "Imagem da ressalva enviada",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta']
    },
    {
        "id_status": 18,
        "type": "Recontagem",
        "autoMessage": "<i>Prezado cliente,<i><p>Solicitamos ao estoque a recontagem da(s) peça(s) em breve daremos retorno.<p>",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 12,
        "type": "Cancelado",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 13,
        "type": "Concluído",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 14,
        "type": "Faturar",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    },
    {
        "id_status": 15,
        "type": "Devolver",
        "created_at": "2023-12-18 10:57:29",
        "id_status_parent": 4,
        "show_to_divergency": ['falta', 'sobra']
    }
]

export default status