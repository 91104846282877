import React, { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { authFetch } from '../../../helpers/authHelper';
import { toast } from 'react-toastify';

import Templater from '../../../components/Templater';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';
import SecondaryButton from '../../../components/SecondaryButton/SecondaryButton'

const Contrato = (props) => {
    const [status, setStatus] = useState('wait');
    const [clientData, setClientData] = useState({})
    const [contrato, setContrato] = useState({})

    useEffect(() => {
        const perfil = props.perfil;
        const onCancel = props.onCancel;
        setStatus('wait');
        (async () => {
            const { contrato, cadastro } = await (await authFetch(`/v1/Afiliados/Contrato/${perfil}`)).json();
            if(!contrato){
                toast.error("Cadastro Não Realizado - Contrato indisponivel", { theme: "colored" })
                onCancel()
            }
            setClientData(cadastro);
            setContrato(contrato);
            setStatus('result');
        })()
    }, [props.perfil, props.onCancel])

    return <SomaStatus status={status}>
        <Grid container direction={'column'} justifyContent="center" alignItems="flex-end">
            <Grid item style={{ margin: '10px' }}>
                <Templater template={contrato?.contract_template} params={clientData} />
            </Grid>
            <div style={{width:"100%",display:"flex",justifyContent:"flex-end"}}>
                <div style={{width:"190px",display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                    <SecondaryButton onClick={() => props.onCancel()}>Voltar</SecondaryButton>
                    <PrimaryButton onClick={() => props.aceito(contrato)}>Li e Aceito</PrimaryButton>
                </div>
            </div>
        </Grid>
    </SomaStatus>
}

export default Contrato;