import React, { useState } from 'react';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import "./SomaAutocomplete.css";

const SomaAutocomplete = (props) => {
  const [selectedOptions, setSelectedOptions] = useState(props.selected);

  const handleSelect = (event, value, reason) => {    
    reason === 'clear' ? props.setSelected([{COD_CLIFOR:'TODOS',NOME_CLIFOR:''}]) : setSelectedOptions([value]);
  };  

  return (
    <div className='div__somaautocomplete'>
        <p>Cliente</p>
        <Autocomplete
            options={props.options.slice(1)}
            disableCloseOnSelect
            getOptionLabel={(option) => `${option.COD_CLIFOR} ${option.NOME_CLIFOR.trim()}`}
            value={selectedOptions[0]}
            onChange={handleSelect}
            onClose={()=>props.setSelected(selectedOptions)}
            ChipProps={{ onDelete: null }}
            style={{ width: 500 }}
            renderInput={(params) => <TextField {...params}/>}
        />
    </div>
  );
}

export default SomaAutocomplete;
