import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import useUser, { useAuth, usePasswordReset } from '../../Hooks/UserContext';
import useQuery from '../../Hooks/useQuery';
import ForgotPasswordModal from './ForgotPasswordModal';
import ConfirmPasswordResetModal from './ConfirmPasswordResetModal';
import Background from './Background';
import LoginForm from './RightSideConteiner/LoginForm/LoginForm';
import Footer from '../../components/Footer/Footer';
import ChangePasswordModal from './ChangePasswordModal';

const LoginPage = () => {
  const [credentials, setCredentials] = useState({ login: '', senha: '' });
  const [forgotPassword, setForgotPassword] = useState(false);
  const [confirmReset, setConfirmReset] = useState(false);
  const [changePassword, setChangePassword] = useState(false);

  const user = useUser();
  const query = useQuery();
  const { login, retoken } = useAuth();
  const { suggestReset, acceptReset } = usePasswordReset();

  useEffect(() => {
    if (suggestReset.data) {
      if (suggestReset.data.features.changePassword) {
        setForgotPassword(false);
        setChangePassword(true);
      } else {
        setForgotPassword(false);
        setConfirmReset(true);
      }
    }
  }, [suggestReset.data]);

  useEffect(() => {
    if (acceptReset.data) {
      setConfirmReset(false);
      toast.success('Email com senha enviado', { theme: "colored" });
    }
  }, [acceptReset.data]);

  useEffect(() => {
    const retokenDispatch = retoken.dispatch;
    if (query.code) {
      retokenDispatch(query.code);
    }
  }, [query.code, retoken.dispatch]);

  const credentialsHandler = (field, value) => setCredentials({ ...credentials, [field]: value });

  if (user) return (<Navigate replace={true} to="/" />);

  return (
    <>
      <ForgotPasswordModal
        open={forgotPassword}
        credentials={credentials}
        onChange={credentialsHandler}
        onConfirm={() => suggestReset.dispatch(credentials.login, credentials.banco)}
        onClose={() => suggestReset.status === 'ready' && setForgotPassword(false)} />
      <ConfirmPasswordResetModal
        open={confirmReset}
        confirmEmail={suggestReset.data?.email}
        onConfirm={() => acceptReset.dispatch(credentials.login, credentials.banco)}
        onClose={() => { acceptReset.status === 'ready' && setConfirmReset(false); }} />
      <ChangePasswordModal
        open={changePassword}
        confirmEmail={suggestReset.data?.email}
        clifor={credentials.login}
        onClose={() => setChangePassword(false)} />
      <Background>
        <LoginForm
          credentials={credentials}
          onChange={credentialsHandler}
          onConfirm={login.dispatch}
          onForgotPassword={() => setForgotPassword(true)}
          status={login.status} />
        <Footer style={{
          position: 'absolute',
          bottom: '0',
          width: '100%'
        }} end />
      </Background>
    </>
  );
};

export default LoginPage;
