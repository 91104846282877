import React from 'react';
import { Grid, TextField, Typography } from "@mui/material";
import Modal from "../../components/Modal";

const ForgotPasswordModal = (props) => {

  return (
    <Modal
      open={props.open}
      modalName={"NÃO SABE SUA SENHA?"}
      actionName={"CONTINUAR"}
      action={props.onConfirm}
      onClose={props.onClose}
    >
      <Grid item style={{ paddingBottom: "10px" }}>
        <Typography style={{ color: "#6E6E6E" }}>
          Para acessar o portal, primeiro insira
        </Typography>
        <Typography style={{ color: "#6E6E6E" }}>
          seu CLIFOR no campo abaixo:
        </Typography>
      </Grid>

      <TextField data-testid="inputForgotPassword" variant="standard" style={{ backgroundColor: "rgba(255, 255, 255)", padding: "10px" }} onChange={(event) => props.onChange('login', event.target.value)} value={props.credentials.login} />
    </Modal>
  );
};

export default ForgotPasswordModal;
