import React from 'react'
import s from './Card.module.css'

const CardBox = (props) => {
    const { title, footer, download, infos, infoHighlighted } = props

    return (
        <section style={{ paddingBottom: infoHighlighted ? '0' : '20px' }} className={s.CardBox} >
            <div className={s.boxDetail} >
                <div className={s.leftSideName} >
                    {title?.Icon}
                    <h4 style={title?.style}>{title?.name} {title?.id}</h4>
                </div>

                <button onClick={download?.click} className={s.rightSideName} >
                    {download?.Icon}
                    <span>{download?.name}</span>
                </button>
            </div>

            <div className={s.boxInfo}>
                {infos?.map((inf, index) => (
                    <div key={index} className={s.boxInfoItem}>
                        {inf?.left &&
                            <div className={`${s[inf.componentProps?.addClassName]}`}>
                                {inf.left}
                            </div>
                        }
                        {inf?.right &&
                            <div className={`${s[inf.componentProps?.addClassName]}`}>
                                {inf.right}
                            </div>
                        }
                    </div>
                ))}
            </div>
            <div className={s.boxDetail} >
                <div className={s.leftSideName} >
                    {footer?.Icon}
                    <h4 style={footer?.style}>{footer?.name} {footer?.id}</h4>
                </div>
            </div>

            {infoHighlighted &&
                <div className={s.boxHighlighted}>
                    {infoHighlighted?.left && <div>{infoHighlighted.left}</div>}
                    {infoHighlighted?.right && <div>{infoHighlighted.right}</div>}
                </div>
            }

        </section>
    )
}

export default CardBox