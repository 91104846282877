import React from "react";
import { Grid, Fab, Button } from "@mui/material";
import { Navigate, Routes, Route } from "react-router-dom";
import BasePage from "./BasePage/BasePage";
import SideMenu from "./SideMenu/SideMenu";
import PrivateRoute from "./SomaStandard/AuthComponents/PrivateRoute";
import SomaTitlePage from "./SomaTitlePage/SomaTitlePage";
import MessageIcon from '@mui/icons-material/Message';
import Footer from "./Footer/Footer";
import { ErrorBoundary } from "react-error-boundary";

const FallbackError = (props) => {
  const button = () => window.open(`${window.location.origin}${props.redirect}`, '_self')

  return <Grid container justifyContent="center" direction="column" alignItems="center" style={{ marginTop: "10vh" }}>
    <Grid item>
      <span style={{ fontSize: "600%", fontFamily: "flama-medium", fontWeight: "900", color: "#707070", textShadow: "1px 0px #707070, 9px 0px #E4D3C7", whiteSpace: "nowrap" }}> Deu <span role="img" aria-label="zebra">🦓</span></span>
    </Grid>
    <Grid item style={{ fontSize: "150%", fontFamily: "flama-medium", fontWeight: "bolder" }}>
      Ocorreu um erro inesperado!
    </Grid>
    <Grid item style={{ fontSize: "150%", fontFamily: "flama-medium", fontWeight: "bolder" }}>
      <Button onClick={button} variant="contained">Recarregar Página</Button>
    </Grid>
  </Grid>
}

const FullPage = (props) => {
  return (
    <BasePage>
      <Grid
        container
        style={{ height: "100%", flexWrap: "nowrap" }}
        alignItems={"stretch"}
      >
        <SideMenu />
        <Grid
          id="mainPage"
          style={{
            height: "100%",
            width: "100%",
            backgroundColor: "#FBFBFB",
            overflow: "hidden auto",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <main id="fullPage" style={{ padding: "0 31px", flex: "1" }}>
            <Routes>
              <Route
                exact="true"
                path={'/'}
                key={0}
                element={<props.parentComponent />}
              />
              {props.routes
                .filter((r) => !r.link)
                .map((r, i) => (
                  <Route
                    key={`priv-route-${i}`}
                    path={`${r.route}/*`}
                    element={
                      <PrivateRoute to={r.show} redirect={props.parentUrl} >
                        <>
                          <SomaTitlePage>{r.text}</SomaTitlePage>
                          <ErrorBoundary fallback={<FallbackError redirect={`${props.parentUrl}`} />}>
                            <r.component {...r.props} />
                          </ErrorBoundary>
                        </>
                      </PrivateRoute>
                    }
                  />
                ))}
              <Route path="*" element={<Navigate replace={true} to={''} />} />
            </Routes>
          </main>
          <Footer />
          <Fab
            id="chat"
            color="primary"
            style={{
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
              zIndex: "1",
            }}
            href="http://chamados.somagrupo.com.br/"
            target="_blank"
          >
            <MessageIcon />
          </Fab>
        </Grid>
      </Grid>
    </BasePage>
  );
};

export default FullPage;
