import React from 'react';
import TableButton from '../../../../components/SomaStandard/TableComponents/TableButton/TableButton'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import GetAppIcon from '@mui/icons-material/GetApp';
import GenerateCsv from '../../../Afiliado/Components/GenerateCsv';
import { useFontStyles } from '../../../../helpers/styleHelper';
import './GroupButtonsMinhasVendas.css'

const GroupButtonsMinhasVendas = props => {

  const classes  = useFontStyles()

  return (
    <div className="div__GroupButtonsMinhasVendas--conteiner">
      <div style={{ marginRight: "8px" }}>
        <TableButton clicked={() => props.setShow(!props.show)}>
          {!props.show ?
            (<>
              <AddIcon />
              <span>detalhar</span>
            </>) : (
              <>
                <RemoveIcon />
                <span>recolher</span>
              </>
            )}
        </TableButton>
      </div>

      <GenerateCsv line={props.line} classes={classes}>
        <TableButton>
          <GetAppIcon />
          <span>relatório</span>
        </TableButton>
      </GenerateCsv>
      {/* <Button onClick={
      console.log('treta')
    }>
        <EditIcon></EditIcon>
    </Button> */}
    </div>
  );
};

export default GroupButtonsMinhasVendas;