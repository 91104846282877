import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { TextField } from '@mui/material';

import Modal from '../Modal/Modal'

import './ConfirmCredentialsModal.css'

const ConfirmCredentialsModal = props => {

    const { open, onClose, title, subtitle, notInputs, mainClassName, buttonClassName, actionName, actionButton } = props;
    const [inputsValues, setInputsValues] = useState({})
    const [errors, setErrors] = useState({})

    const parseCnpj = useCallback((cnpj) => cnpj.match(/\d/g)?.slice(0, 14).map((c, i) => { if (i === 2 || i === 5) return '.' + c; if (i === 8) return '/' + c; if (i === 12) return '-' + c; return c; }).join(''), []);
    const validateCNPJ = useCallback((value) => {
        if (!value) return false

        // Aceita receber o valor como string, número ou array com todos os dígitos
        const isString = typeof value === 'string'
        const validTypes = isString || Number.isInteger(value) || Array.isArray(value)

        // Elimina valor em formato inválido
        if (!validTypes) return false

        // Filtro inicial para entradas do tipo string
        if (isString) {
            // Limita ao máximo de 18 caracteres, para CNPJ formatado
            if (value.length > 18) return false

            // Teste Regex para veificar se é uma string apenas dígitos válida
            const digitsOnly = /^\d{14}$/.test(value)
            // Teste Regex para verificar se é uma string formatada válida
            const validFormat = /^\d{2}.\d{3}.\d{3}\/\d{4}-\d{2}$/.test(value)

            // Se o formato é válido, usa um truque para seguir o fluxo da validação
            // eslint-disable-next-line no-unused-expressions
            if (digitsOnly || validFormat) true
            // Se não, retorna inválido
            else return false
        }

        // Guarda um array com todos os dígitos do valor
        const match = value.toString().match(/\d/g)
        const numbers = Array.isArray(match) ? match.map(Number) : []

        // Valida a quantidade de dígitos
        if (numbers.length !== 14) return false

        // Elimina inválidos com todos os dígitos iguais
        const items = [...new Set(numbers)]
        if (items.length === 1) return false

        // Cálculo validador
        const calc = (x) => {
            const slice = numbers.slice(0, x)
            let factor = x - 7
            let sum = 0

            for (let i = x; i >= 1; i--) {
                const n = slice[x - i]
                sum += n * factor--
                if (factor < 2) factor = 9
            }

            const result = 11 - (sum % 11)

            return result > 9 ? 0 : result
        }

        // Separa os 2 últimos dígitos de verificadores
        const digits = numbers.slice(12)

        // Valida 1o. dígito verificador
        const digit0 = calc(12)
        if (digit0 !== digits[0]) return false

        // Valida 2o. dígito verificador
        const digit1 = calc(13)
        return digit1 === digits[1]
    }, [])

    const validationValuesHandler = useCallback(() => {
        let errors = {}
        if (!validateCNPJ(inputsValues.cnpj)) errors.cnpj = 'Cnpj inválido!'
        if (!inputsValues.clifor || inputsValues.clifor.length !== 6) errors.clifor = 'Clifor inválido!'

        return errors
    }, [validateCNPJ, inputsValues])

    const onInputsChangeHandler = useCallback((inputType, value, parse) => {
        setInputsValues(prevState => ({
            ...prevState,
            [inputType]: parse ? parse(value) : value
        }))
    }, [])

    const onHandleSubmit = useCallback(() => {
        setErrors(validationValuesHandler(inputsValues))

    }, [validationValuesHandler, inputsValues])

    const onSubmitData = useCallback(() => {
        //função para fazer a requisição
    }, [])

    const inputsFormItems = useMemo(() => [
        {
            error: errors.cnpj ? true : false,
            helperText: errors.cnpj,
            name: 'cnpj',
            label: 'CNPJ',
            value: inputsValues.cnpj || '',
            onChange: ({ target: { value, name } }) => onInputsChangeHandler(name, value, parseCnpj),
            InputLabelProps: { shrink: true },
            variant: "standard",
            color: "secondary",
            fullWidth: true,
            className: "div__inputconfirmcredentialsmodal--container"
        },
        {
            error: errors.clifor ? true : false,
            helperText: errors.clifor,
            name: "clifor",
            label: 'Clifor',
            value: inputsValues.clifor || '',
            onChange: ({ target: { value, name } }) => onInputsChangeHandler(name, value, (clifor) => clifor.match(/\d/g)?.slice(0, 6).join('')),
            InputLabelProps: { shrink: true },
            variant: "standard",
            color: "secondary",
            fullWidth: true
        }
    ], [errors, inputsValues, onInputsChangeHandler, parseCnpj])


    useEffect(() => {
        if (Object.keys(errors).length === 0) {
            onSubmitData()
        }
    }, [errors, onSubmitData])


    return (
        <Modal size="small-medium" mainClassName={mainClassName} buttonClassName={buttonClassName} modalName={title || "ACESSO AO PORTAL +ATACADO"} closeName="cancelar" actionName={actionName ||"prosseguir"} open={open} onClose={onClose} action={actionButton || onHandleSubmit} isUploading={false} notInputs={notInputs}>
            <h4 className="h4__warning--text"> {subtitle || 'Para visualizar todas as funcionalidades do Portal, confirme seus dados abaixo:'} </h4>
            <div className='div__confirmcredentialsmodal--content'>
                {
                    !notInputs && 
                    inputsFormItems.map(input => (
                        <TextField key={Math.random()}
                            {...input}
                        />
                    ))
                }
            </div>
        </Modal>
    )
}

export default ConfirmCredentialsModal
