import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import { encodeFilter } from '../../../helpers/webHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import ListaVendas from '../../Afiliado/Components/ListaVendas';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import SelecionarPagamentoButton from '../../Afiliado/Components/SelecionarPagamentoButton';
import CheckboxVendas from '../../Afiliado/Components/CheckboxVendas';
import BarraFlutuante from '../../Afiliado/Components/BarraFlutuante';
import useAffiliate from '../../../Hooks/AffiliateContext';
import twoValues from '../../../components/SomaStandard/TableComponents/twoValues/twoValues';
import GroupButtonsMinhasVendas from '../Components/GroupButtonsMinhasVendas/GroupButtonsMinhasVendas';
import TableSettings from '../../../components/TableSettings/TableSettings';


const MinhasVendas = (props) => {
  const hasButton = true;
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState('');
  const [valorTotal, setValorTotal] = useState(0.0);
  const [comissaoTotal, setComissaoTotal] = useState(0.0);
  const [credito, setCredito] = useState(0.0);
  const [deposito, setDeposito] = useState(0.0);
  const [checkbox, setCheckBox] = useState(false);
  const [optionSelected, setOptionSelected] = useState([]);
  const [startPeriod, setStartPeriod] = useState();
  const [endPeriod, setEndPeriod] = useState();
  const [upload, setUpload] = useState(false);
  const { affiliate, /*status:statusAffilate*/ } = useAffiliate();


  const handleChange = (e) => {
    optionSelected[e.target.name].selected = e.target.checked;
    setOptionSelected(optionSelected);
  };

  const updateCheckBox = (value) => {
    setCheckBox(value);
  };

  const Opts = {
    endpoint: "/v1/minhasvendasnewer",
    columns: [
      {
        propName: "Minhas Vendas",
        componentProps: {
          colunas: [
            checkbox ?
              {
                propName: "mes_ano", propText: "", component: CheckboxVendas,
                componentProps: {
                  checkbox: checkbox,
                  valorTotal: valorTotal,
                  setValor: setValorTotal,
                  comissaoTotal: comissaoTotal,
                  setComissao: setComissaoTotal,
                  credito: credito,
                  setCredito: setCredito,
                  deposito: deposito,
                  setDeposito: setDeposito,
                  opcoes: optionSelected,
                  change: handleChange
                }
              } : null,
            { propName: "ano_mes", propText: "período da venda", highlighted: true, money: true, propName2: "valor", component: twoValues },
            { propName: "comissao", propText: "Comissão", component: ConverteDinheiro },
            { propName: "credito", propText: "crédito", component: ConverteDinheiro },
            { propName: "deposito", propText: "depósito", component: ConverteDinheiro },
            { propName: "chamado", propText: "nº do chamado" },
            { propName: "num_credito", propText: "nº de crédito" },
            { propName: "data_pgnt", propText: "previsão de pgto." },
            { propName: "status", propText: "Status" },
            { propName: "ano_mes", propText: "", component: GroupButtonsMinhasVendas }
          ],
          infoList: { component: ListaVendas }
        }
      }
    ],
    hasButton,
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage, checkbox
  };

  const FilterOpts = {
    filterFields: [
      { propName: "data", component: ToFrom, propText: "Período:", componentProps: { component: DatePicker } },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  useEffect(() => {
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?${filter}`)).json();
        // const json = await (await authFetch(`${Opts.endpoint}?${filter}`)).json();
        let obj = {};
        json.results.forEach(data => {
          obj[data.ano_mes] = {
            selected: false,
            id_profiles: data?.id_profiles
          };
        });
        setOptionSelected(obj);
        setResults(json.results);
        setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
        setStartPeriod(json.start);
        setEndPeriod(json.end);
        setUpload(false);
      }
      catch (err) {
        setResults(undefined);
        console.log(err);
        setStatus('error');
      }
    })();
  }, [Opts.endpoint, page, rowsPerPage, filter, upload]);

  return (
    <div >
      <TableSettings>
        {(checkbox === true) ?
          <div></div>
          :
          results.length ? <SelecionarPagamentoButton updateCheckBox={updateCheckBox} statusBox={checkbox} startPeriod={startPeriod} endPeriod={endPeriod} />
          : <span></span>
        }
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>

        <SomaTable {...Opts} />

        {(checkbox === true) ?
          <div>
            <BarraFlutuante id_profiles={affiliate.id_profiles} updateCheckBox={updateCheckBox} setCheckBox={setCheckBox} optionSelected={optionSelected} setComissaoTotal={setComissaoTotal} setCredito={setCredito} setDeposito={setDeposito} comissaoTotal={comissaoTotal} credito={credito} deposito={deposito} valorTotal={valorTotal} setStatus={setStatus} upload={setUpload} />
          </div>
          :
          ""
        }
      </SomaStatus>

    </div>
  );
};


export default MinhasVendas;
