import React from 'react';
import { Typography, Grid } from '@mui/material';

const Contrato = (props) => {
    return <>
    <Grid container alignItems="center">
    <Typography style={{fontFamily:'flama-medium', fontSize:'19px', color:'#707070'}}>
        Você ainda não está cadastrado no programa Afiliados. Para cadastrar seus Vendedores, primeiro insira seus dados. 
        </Typography>
    </Grid>
    </>

}

export default Contrato;