import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";

import SideMenuListItems from "./SideMenuListItems/SideMenuListItems";

import "./SideMenu.css";

const SideMenu = (props) => {
  const currentURL = useLocation();
  const divConteinerVariants = {
    open: {
      display: "flex",
      x: 0,
      transition: {
        duration: 0.5,
        type: "tween",
      },
    },
    closed: {
      display: "none",
      x: "-100%",
    },
  };

  const [menuState, setMenuState] = useState({
    admin: false,
    financeiro: false,
    comercial: false,
    afiliado: false,
    material: false
  });

  const closeOpenWindow = useCallback(
    (type) => {
      let newState;
      Object.keys(menuState).forEach((item) => {
        if (item !== type) {
          newState = {
            ...newState,
            [item]: false,
          };
        } else {
          newState = {
            ...newState,
            [item]: !menuState[item],
          };
        }
      });
      setMenuState(newState);
    },
    [menuState]
  );

  const [isMenuVisible, setIsMenuVisible] = useState(true);

  const onClickMenuItemHandler = useCallback(
    (type) => {
      setMenuState((prevState) => ({ ...prevState, [type]: !prevState[type] }));
      closeOpenWindow(type);
    },
    [closeOpenWindow]
  );

  useEffect(() => {
    Object.keys(menuState).map(
      (i) =>
        currentURL.pathname.toLowerCase().includes(i) &&
        setMenuState((prevState) => ({ ...prevState, [i]: true }))
    );
    currentURL.pathname !== '/' && setIsMenuVisible(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentURL]);

  const handleMouseEnter = () => setIsMenuVisible(true);

  return (
    <>
      <motion.div
        className={`div__sidemenu--conteiner ${!isMenuVisible ? 'no-min-width' : ''}`}
        variants={divConteinerVariants}
        initial="closed"
        animate={"open"}
        onMouseEnter={handleMouseEnter}
      >
        <button onClick={() => setIsMenuVisible(!isMenuVisible)} className="button_hide_aside">
          {isMenuVisible ? "<" : ">"}
        </button>
        <SideMenuListItems state={menuState} setState={onClickMenuItemHandler} isMenuVisible={isMenuVisible} shouldShow={true} />
      </motion.div>

    </>
  );
};

export default SideMenu;
