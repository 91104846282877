import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import StarBorderIcon from "@mui/icons-material/StarBorder";

import { toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useNfeCaixaFlow from "../../../../Hooks/useNfeReducer";
import useUser from '../../../../Hooks/UserContext';

import "./nps.css";
import FooterButton from "../../../../components/FooterButton/footerButton";


const Nps = () => {
  const [questionScore1, setQuestionScore1] = useState(0);
  const [questionScore2, setQuestionScore2] = useState(0);
  const [questionScore3, setQuestionScore3] = useState(0);
  const [questionScore4, setQuestionScore4] = useState(0);
  const [comment, setComment] = useState(null);

  const { state, registerNps, goNextPageAfterNps } = useNfeCaixaFlow();

  const user = useUser();

  const handleRegister = () => {
    registerNps(user, {
      q1: questionScore1,
      q2: questionScore2,
      q3: questionScore3,
      q4: questionScore4,
      comment
    });

    toast.success("Seu feedback foi recebido com sucesso!",
      {
        theme: "light",
        position: "bottom-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        pauseOnFocusLoss: false,
        draggable: true,
        progress: undefined,
        toastId: "custom-id-yes",
        transition: Slide,
      }
    );

    goNextPageAfterNps()
  }

  useEffect(() => {
    try {
      const h1Title = document.getElementById('title')
      h1Title.style.display = 'none'
      return () => {
        h1Title.style.display = ''
      }
    } catch (error) {
      console.error(error)
    }
  }, [])

  const classificationText = () => {
    return (
      <small className="classification">
        <div> Muito <p> insatisfeito</p></div>
        <div> Muito <p> satisfeito </p></div>
      </small>
    )
  }



  let scores = () => {
    (questionScore1 > 0) &&
      (questionScore2 > 0) &&
      (questionScore3 > 0) &&
      (questionScore4 > 0)
      ? scores = true
      : scores = false
  };

  scores();

  return (
    <>
      <div className="Nps">
        <Box
          component="fieldset"
          mb={4}
          borderColor="transparent"
        >
          {state.data?.nps?.nfSaida ?
            <h4>Olá, você deixou de responder a uma pesquisa de satisfação!<br />Como foi a entrega da nota: <u>{state.data.nps.nfSaida}</u>?</h4>
            : <h4>Qual o seu nível de satisfação em relação a esta entrega:</h4>
          }

          <form required className="form">
            <Typography component="legend" children={state.data?.nps?.Questions[0].question} />
            <div className="rating">
              <Rating
                name="customized-empty1"
                value={questionScore1}
                size="large"
                onChange={(event, newValue) => {
                  setQuestionScore1(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              {classificationText()}
            </div>

            <Typography component="legend" children={state.data?.nps?.Questions[1].question} />
            <div className="rating">
              <Rating
                name="customized-empty2"
                value={questionScore2}
                size="large"
                onChange={(event, newValue) => {
                  setQuestionScore2(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              {classificationText()}
            </div>

            <Typography component="legend" children={state.data?.nps?.Questions[2].question} />
            <div className="rating">
              <Rating
                name="customized-empty3"
                value={questionScore3}
                size="large"
                onChange={(event, newValue) => {
                  setQuestionScore3(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              {classificationText()}
            </div>

            <Typography component="legend" children={state.data?.nps?.Questions[3].question} />
            <div className="rating">
              <Rating
                name="customized-empty4"
                value={questionScore4}
                size="large"
                onChange={(event, newValue) => {
                  setQuestionScore4(newValue);
                }}
                emptyIcon={<StarBorderIcon fontSize="inherit" />}
              />
              {classificationText()}
            </div>

            <div>
              <Typography component="legend">
                Deixe seu comentário.
                <span style={{ color: "#BDBDBD" }}>&nbsp;(opcional)</span>
              </Typography>
            </div>
            <textarea className="textarea_nps" rows="6" onChange={(e) => setComment(e.target.value)} />

          </form>
        </Box>

      </div>

      <FooterButton onClick={handleRegister} disabled={scores === false}>
        ENVIAR
      </FooterButton>


    </>
  );
};

export default Nps;
