import React, { useState, useEffect } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import ConverteData from '../../../components/SomaStandard/TableComponents/ConverteData';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import ListaProdutos from '../Components/ListaProdutos';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import { encodeFilter } from '../../../helpers/webHelper';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import useUser from '../../../Hooks/UserContext';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import ButtonProduct from '../Components/ButtonProducts';
import TwoValues from '../../../components/SomaStandard/TableComponents/twoValues/twoValues';
import TableSettings from '../../../components/TableSettings/TableSettings';
import ButtonExportOrders from '../Components/ButtonExportOrders';

const FatRealizados = (props) => {
  const user = useUser();
  const hasButton = true;

  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState('');

  let Opts = {
    endpoint: "/v1/meuspedidos",
    columns: [
      {
        componentProps: {
          colunas: [
            { propName: "PEDIDO", propText: "nº do Pedido ", highlighted: true },
            { propName: "CLIENTE_ATACADO", propText: "Cliente", show: user.features.repre === true },
            { propName: "COLECAO", propText: "Coleção" },
            { propName: "TIPO_VENDA", propText: "Tipo pedido" },
            { propName: "MARCA", propText: "Marca" },
            { propName: "EMISSAO", propText: "data de Emissão", component: ConverteData },
            { propName: "TOT_QTDE_ORIGINAL", propText: "Entregue/Total", component: TwoValues, value: (props) => <>{props.line.TOT_QTDE_ENTREGUE + "/" + props.line.TOT_QTDE_ORIGINAL}</>, underValue: (props) => <>{(props.line.TOT_QTDE_ENTREGUE / props.line.TOT_QTDE_ORIGINAL * 100).toPrecision(3) + "%"}</> },
            { propName: "TOT_VALOR_ORIGINAL", propText: "Valor original", component: ConverteDinheiro },
            { propName: "TOT_VALOR_ENTREGUE", propText: "Valor entregue", component: ConverteDinheiro },
            { propName: "EXPORTAR", propText: "", component: ButtonExportOrders },
            { propName: "PEDIDO", propText: "", component: ButtonProduct }
          ],
          infoList: { component: ListaProdutos }
        }
      }
    ],
    hasButton,
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      { propName: "CLIENTE_ATACADO[lk]", propText: "Cliente", format: val => `%${val}%`, show: { filter: user.features.repre === true } },
      { propName: "ClientesAtacado.CLIFOR[lk]", propText: "Clifor", format: val => `%${val}%`, show: { filter: user.features.repre === true } },
      { propName: "Vendas.PEDIDO[lk]", propText: "Pedido", format: val => `%${val}%` },
      { propName: "Vendas.COLECAO[lk]", propText: "Coleção", format: val => `%${val}%` },
      { propName: "Vendas.EMISSAO", component: ToFrom, propText: "Emissão", componentProps: { component: DatePicker } }
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  useEffect(() => {
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`)).json();
        setResults(json.results);
        setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
      }
      catch (err) {
        setResults(undefined);
        console.log(err);
        setStatus('error');
      }
    })();
  }, [Opts.endpoint, page, rowsPerPage, filter]);

  return (
    <>
      <TableSettings>
        <div></div>
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <SomaTable {...Opts} />
      </SomaStatus>
    </>
  );
};


export default FatRealizados;
