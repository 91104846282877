import { useEffect, useState } from 'react';
import ConverteDinheiroFunction from '../../../Utils/ConverteDinheiroFunction.js'
import SomaStatus from '../../../components/SomaStandard/SomaStatus.js';
import { authFetch } from '../../../helpers/authHelper.js';
import ConverteDataFunction from '../../../Utils/ConverteDataFunction.js';
import { useNavigate } from 'react-router-dom';
import SomaTableFooter from '../../../components/SomaStandard/SomaTable/SomaTableFooter/SomaTableFooter.js';
import SomaFilter from '../../../components/SomaStandard/SomaFilter.js';
import DetailsItem from '../../../components/DetailsBox/DetailsBoxItem.js';
import { encodeFilter } from '../../../helpers/webHelper.js';
import s from './Negociacoes.module.css'

const Negociacoes = () => {
    const [status, setStatus] = useState('wait');
    const [results, setResults] = useState([])
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [total, setTotal] = useState(0);
    const [filter, setFilter] = useState('');

    const navigate = useNavigate();

    const [showFaturas, setShowFaturas] = useState(false)

    const widthScreen = () => {
        if (window.innerWidth < 481) return { widthCss: '100%', qtdColumns: 2 }
        else if (window.innerWidth < 769) return { widthCss: '80%', qtdColumns: 2 }
        else return { widthCss: '50%', qtdColumns: 4 }
    }
    const { qtdColumns } = widthScreen()

    const blocosFaturas = (negociacao) => {
        return negociacao.Faturas?.reduce((acc, curr, index) => {
            const blockIndex = Math.floor(index / qtdColumns) + 1; // Calcule o índice do bloco
            const blockKey = `block${blockIndex + 4}`;//nomes começarem com "block5"

            const newBlock = [
                {
                    key: 'Fatura: ',
                    value: `${curr.fatura.trim()}-${curr.parcela.trim()}`,
                    componentProps: { style: { fontSize: '0.6875rem', lineHeight: 1.3 } }
                },
                {
                    key: 'Valor: ',
                    value: ConverteDinheiroFunction(curr.saldoPrincipalDevidoComJuros),
                    componentProps: { style: { fontSize: '0.6875rem', lineHeight: 1.3 } }
                },
                {
                    key: 'Venc.: ',
                    value: ConverteDataFunction(curr.vencimentoReal),
                    componentProps: { style: { fontSize: '0.6875rem', lineHeight: 1.3, } }
                }
            ]

            // Se o bloco ainda não existe, inicialize-o
            if (!acc[blockKey]) {
                acc[blockKey] = [];
            }

            // Adiciona o elemento atual em um array e insere no bloco correspondente
            if (acc[blockKey].length < qtdColumns) {
                acc[blockKey].push(newBlock);
            } else {
                acc[`block${blockIndex + 1}`] = [newBlock];
            }

            return acc;
        }, {});

    }

    const list = {
        name: '',
        items: results.map(negociacao => {
            return {
                title: {
                    id: <p>Negociação {negociacao.idNegociacao} - {ConverteDataFunction(negociacao.lockedAt)}</p>,
                    description: <span style={{ color: '#D66157' }}><br />Valor inicial: {ConverteDinheiroFunction(negociacao.valorInicial)}</span>,
                    key: negociacao.idNegociacao,
                },
                footer: {
                    id: ``,
                    description: <span style={{ color: '#196621' }}>Valor final: {ConverteDinheiroFunction(negociacao.valorFinal)}</span>,
                    key: '',
                },
                blocksInfos: {
                    block1: [
                        [{ key: 'Forma de pagamento: ', value: negociacao.formaPagamento }],
                    ],
                    block2: [
                        [{ key: 'Parcela: ', value: ConverteDinheiroFunction(negociacao.valorParcela) || '-' }],
                        [{ key: 'Nº de parcelas: ', value: negociacao.numeroParcelas || '-' },],
                        [{ key: 'Entrada: ', value: ConverteDinheiroFunction(negociacao.entrada) || '-' }],
                        [{ key: 'Intervalo: ', value: negociacao.boleto || '-' }],
                    ],
                    block3: [[{
                        key: 'Faturas Selecionadas: ',
                        value:
                            <span
                                style={{ cursor: 'pointer', color: ' #AD9078', textDecoration: 'underline' }}
                                onClick={() => setShowFaturas(prev => !prev)}>
                                {showFaturas ? 'esconder' : 'mostrar'}
                            </span>

                    }]],
                    ...(showFaturas ? blocosFaturas(negociacao) : {})
                }

            }
        })
    };

    const FilterOpts = {
        filterFields: [
            { propName: "id_negociacao", propText: "Negociação" },
            { propName: "forma_pagamento", propText: "Forma de Pagamento" },
        ],
        updateFilter: (e) => setFilter(encodeFilter(e))
    };

    useEffect(() => {
        try {
            const chat = document.getElementById('chat')
            chat.style.display = 'none'
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        setStatus('wait');
        (async () => {
            try {
                const endpoint = `/v1/negociacao/enviada`
                const json = await (await authFetch(`${endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`)).json();
                setResults(json.results);
                setTotal(json.total)
                setStatus(json.results.length || filter ? 'result' : 'empty');
            } catch (err) {
                setResults(undefined);
                setStatus('error');
            }
        })();
    }, [page, rowsPerPage, total, filter]);

    const emptyResults = () => {
        return (
            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', width: '60%', margin: 'auto' }}>
                <span style={{ fontSize: '20px', color: 'black', textAlign: 'center' }}>
                    VOCÊ AINDA NÃO POSSUI NENHUMA NEGOCIAÇÃO. DESEJA CRIAR UMA AGORA?<br />
                </span>
                <button
                    className={s['div__somadataoverview--to-negotiate']}
                    onClick={() => { navigate('/Financeiro/negociar') }}
                >
                    CRIAR NEGOCIAÇÃO
                </button>
            </div>
        )
    }

    return (
        <SomaStatus emptyTitle={' '} emptyText={emptyResults()} status={status}>
            <div className={s.buttonsGroup}>
                <button
                    className={s['div__somadataoverview--to-negotiate']}
                    onClick={() => { navigate('/Financeiro/negociar') }}
                >
                    CRIAR NEGOCIAÇÃO
                </button>
                <SomaFilter {...FilterOpts} />
            </div >
            <SomaStatus status={!results.length && filter ? 'empty' : 'result'}>
                <div>
                    {list?.items?.map(item => (
                        <DetailsItem key={item.title.key} {...item} />
                    ))}
                </div>
                <SomaTableFooter
                    total={total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    setRowsPerPage={setRowsPerPage}
                    setPage={setPage}
                    labelRowsPerPage={"Items:"}
                />
            </SomaStatus>
        </SomaStatus>

    )
}

export default Negociacoes