import React from 'react';
import { backgroundImages, backgroundImagesResponsive, randomNumber } from '../../Utils/RandomBackgroundLogin';
import styles from './Background.module.css';

const Background = (props) => {
  const { children } = props;
  return (
    <main className={styles.main}>
      <div data-testid="background" className={styles.background} style={{
        backgroundImage: `url(${backgroundImages[randomNumber]})`
      }}></div>
      <div data-testid="backgroundResponsive" className={styles.backgroundResponsive} style={{
        backgroundImage: `url(${backgroundImagesResponsive[randomNumber]})`
      }}></div>
      <div className={styles.children}>
        {children}
      </div>
    </main>
  );
};

export default Background;
