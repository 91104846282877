import React from 'react'

import './InputsLoginForm.css'

const InputsLoginForm = props =>(
    <div className="div__inputsloginform--conteiner">
        <input type="text" placeholder="Clifor" onChange={(event) => props.onChange('login', event.target.value)}  value={props.login}/>
        <input type="password" placeholder="Senha" onChange={(event) => props.onChange('senha', event.target.value)} value={props.senha}/>
    </div>

)

export default InputsLoginForm