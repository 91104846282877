import React, { useState, useEffect } from "react";

import useAffiliate from "../../Hooks/AffiliateContext";
import MaisAtacado from "../../Services/MaisAtacado/MaisAtacado";

import Modal from "../../components/Modal/Modal";
import HomeNPS from "./Components/HomeNPS/HomeNPS";
import BasePage from "../../components/BasePage/BasePage";
import SideMenu from "../../components/SideMenu/SideMenu";
import HomeInfoConteiner from "./Components/HomeInfoContainer/HomeInfoContainer";

import "./Home.css";

const Home = () => {
  const [NPS, setNPS] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { affiliate, acceptedNewContract } = useAffiliate();

  const handleSendNPS = (answer) => {
    MaisAtacado.post("v1/nps/answer", answer).then(() => {
      setIsSent(true);
    });
  };

  const handleCloseNPS = () => {
    setNPS(null);
    localStorage.setItem("userAnswered", true);
  };
  const dadosCadastrais = document.getElementById("afiliadosDADOS CADASTRAIS")
  const propsModal = {
    modalName: "ATUALIZAÇÕES NO CONTRATO AFILIADOS",
    closeName: "VER DEPOIS",
    onClose: () => setOpenModal(false),
    actionName: "ASSINAR CONTRATO",
    open: openModal,
    action: () => dadosCadastrais.click()
  }

  useEffect(() => {
    if (affiliate && !acceptedNewContract) setOpenModal(true)
    else setOpenModal(false)
  }, [affiliate, acceptedNewContract]);

  return (
    <BasePage>
      {NPS && !NPS?.Questions[0]?.Answer && (
        <HomeNPS
          nps={NPS}
          isSent={isSent}
          onSend={handleSendNPS}
          onClose={handleCloseNPS}
        />
      )}

      <Modal {...propsModal}>
        <div>
          Modificamos alguns detalhes no contrato.
          Por favor, atualize sua assinatura para
          garantir que esteja sempre por dentro das mudanças.
        </div>
      </Modal>

      <main data-testid="home" className="main__home--container">
        <SideMenu />
        <HomeInfoConteiner />
      </main>
    </BasePage>
  );
};

export default Home;
