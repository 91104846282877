import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { TextField } from '@mui/material';

import { ReactComponent as Search } from '../../../../assets/images/icons/magnifyingGlass.svg'
import { ReactComponent as InsertIcon } from '../../../../assets/images/icons/moreIcon.svg'

import { authFetch } from '../../../../helpers/authHelper';
import useNfeCaixaFlow from '../../../../Hooks/useNfeReducer';
import ButtonBack from './ButtonBack';
import DetailsBox from '../../../../components/DetailsBox/DetailsBox';

const SearchProduct = () => {
    const { dispatch, state } = useNfeCaixaFlow();
    const { boxId } = useParams();

    const [infos] = state.data?.CAIXAS.filter(caixa => caixa.caixa === boxId);
    const [nfeBox, setNfeBox] = useState({ ...infos });
    const caixa = nfeBox.caixa;

    const [inputValue, setInputValue] = useState('');
    const [findProductInBox, setFindProductInBox] = useState('');
    const [data, setData] = useState([]);
    const [status, setStatus] = useState('wait');

    const inputLabelContent = (
        <div className='textSearch'>
            <Search />
            <span>Buscar cod. do produto</span>
        </div>
    )
    const onClickBackButton = () => {
        dispatch({ type: 'goBack' })
    }

    const submitSearchProduct = useCallback(async (e) => {
        e.preventDefault()
        setStatus('wait');
        const text = e.target[0].value
        if (text !== '') {
            setInputValue(text)
            e.target[0].value = ''
        }
        try {
            const json = await (await authFetch(`/v1/recebimento/produto/${text}`)).json();
            setData(json);
            setStatus(json.length ? 'result' : 'empty');
            const findProductBoxes = state.data?.CAIXAS.flat().filter(items => items.produtos) || [];
            const foundBox = findProductBoxes.find(item =>
                item.produtos.some(item => json.some(prod => prod.PRODUTO.trim() === item.produto.trim()))
            );
            setFindProductInBox(foundBox);
            setNfeBox({ ...nfeBox, produtos: json })

        }
        catch (err) {
            console.log(err);
            setData([]);
            setStatus('error');
        }
    }, [nfeBox, state.data])

    useEffect(() => {
        if (inputValue.trim() === '') {
            return;
        }
    }, [data, inputValue]);

    const divergencyButtonContent = (
        <span className='insertButtonContent'>
            <InsertIcon />
            <span>Adicionar</span>
        </span>
    )

    const list = {
        name: '',
        items: data.map(item => {
            const isProductInBox = findProductInBox && findProductInBox.produtos.some(
                boxItem => boxItem.produto.trim() === item.PRODUTO.trim() && boxItem.cor.trim() === item.cor.trim()
            );
            return {
                isSearchPage: true,
                title: {
                    id: item.PRODUTO.trim(),
                    description: item.DESC_PRODUTO.trim(),
                    key: item.PRODUTO.trim() + item.pedido + item.cor,
                    Icons: [],
                },
                blocksInfos: {
                    block1: [
                        [
                            { key: 'Marca: ', value: item.MARCA },
                            { key: 'Cor: ', value: `${item.cor} - ${item.descCor.trim().split('_')[0]}` },
                        ],
                    ]
                },
                warning: isProductInBox ? {
                    componentProps: { className: 'productInBoxAlert' },
                    message: `Produto com esta cor já consta na caixa: `,
                    link: {
                        link: findProductInBox && `./nfeBoxItems/${findProductInBox.caixa}`,
                        text: findProductInBox && findProductInBox.caixa,
                        click: () => dispatch({ type: 'showNfeBoxItems', boxId: findProductInBox.caixa })
                    },
                } : null,
                grid: {
                    grid: item.GRADE,
                    total: 0
                },
                divergency: null,
                gridReducer: null,
                editItemButton: !isProductInBox ? {
                    content: divergencyButtonContent,
                    componentProps: { className: 'insertButtonContent' },
                    text: "Adicionar",
                    Icon: <InsertIcon />,
                    link: `./nfeBoxItems/${caixa}/edit/${item.PRODUTO.trim()}`,
                    click: () => dispatch({
                        type: 'showNfeItemEdit',
                        value: { ...item, nfeBox },
                        boxId: caixa,
                        produtoId: item.PRODUTO.trim(),
                        corId: item.cor.trim(),
                        pedidoId: '-',
                        itemId: '-'

                    })
                } : null,
            }
        }
        )
    };

    return (
        <div className='container'>
            <ButtonBack onClick={onClickBackButton} />
            <form onSubmit={(e) => submitSearchProduct(e)}>
                <TextField
                    className='textInfoNfe'
                    label={inputLabelContent}
                    fullWidth
                    variant="standard"
                    color="secondary"
                />
            </form>

            <DetailsBox list={list} />

            {status === 'empty' &&
                <small>
                    Produto não encontrado.
                </small>
            }
        </div>
    );
};

export default SearchProduct;
