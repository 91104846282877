import React from "react";
import HomeHeader from "./HomeHeader/HomeHeader";
import HomeConteudos from "./HomeConteudos/HomeConteudos";

import "./HomeInfoContainer.css";

const HomeInfoContainer = () => {

  return (
    <div className="div__HomeInfo--container" data-testid="homeInfoContainer">
      <HomeHeader />
      <HomeConteudos/>
    </div>
  );
};

export default HomeInfoContainer;
