import MaisAtacado from "./MaisAtacado";

const tratativaMultimarca = (args) =>
    MaisAtacado.post(`/v1/divergencias/tratativaMultimarca`, args);

const iniciarAtendimento = (args) =>
    MaisAtacado.post(`/v1/divergencias/iniciarAtendimento`, args);

const mostrarDivergencias = (args) =>
    MaisAtacado.get(`/v1/divergencias/tratativaDivergencias/${args.entrega}/${args.clifor}`, args);

const salvarMensagem = (args) =>
    MaisAtacado.post(`/v1/divergencias/salvarMensagem`, args);

const atualizarStatus = (args) =>
    MaisAtacado.patch(`/v1/divergencias/atualizarStatusTratativa`, args);

const finalizarAtendimento = (args) =>
    MaisAtacado.post(`/v1/divergencias/finalizarAtendimento`, args);

const uploadFile = (args) =>
    MaisAtacado.post(`/v1/divergencias/uploadFile/${args.entrega}/${args.clifor}`, args.files);

const downloadFile = (args) =>
    MaisAtacado.get(`/v1/divergencias/downloadFile/${args.entrega}/${args.clifor}`, { params: { ...args } });

const getEtiquetaDevolucao = (args) =>
    MaisAtacado.get(`/v1/divergencias/getEtiquetaDevolucao/${args.clifor}`);

const TratativaDivergencias = {
    iniciarAtendimento,
    mostrarDivergencias,
    salvarMensagem,
    atualizarStatus,
    finalizarAtendimento,
    tratativaMultimarca,
    uploadFile,
    downloadFile,
    getEtiquetaDevolucao
};
export default TratativaDivergencias;