import React from 'react'
import HeaderRows from './HeaderRows'
import s from './HeaderItems.module.css'

const HeaderItems = (props) => {
    const { items } = props

    return (
        <div className={s.productsDetails}>
            {items.map((rows, i) => (<HeaderRows key={i} rows={rows} />))}
        </div>

    )
}

export default HeaderItems