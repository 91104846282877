import React from 'react';
import { toast } from 'react-toastify';

import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton'

const SelecionarPagamentoButton = (props) => {
  const handleOnClick = () => {
    if (props.statusBox === false){
      props.updateCheckBox(true)
    } else if (props.statusBox === true) {
      props.updateCheckBox(false)
    }
  }

  const handleOnClickOutDate = () => {
    toast.error(`Período de pagamento: do dia ${props.startPeriod} até o dia ${props.endPeriod}.`, { theme: "colored" })
  }

  const day = new Date().getDate();

  return (
    <div style={{margin:"5px 0"}}>
      {(props.statusBox === false && (day >= props.startPeriod && day <= props.endPeriod)) ?
        <PrimaryButton  onClick={handleOnClick} > Selecionar Para Pagamento </PrimaryButton>
        :
        <PrimaryButton  onClick={handleOnClickOutDate} > Selecionar Para Pagamento </PrimaryButton>
      }
    </div>
  )

}
export default SelecionarPagamentoButton;