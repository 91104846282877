import React, { useEffect } from 'react';

import { Routes, Route, useNavigate } from "react-router-dom";

import NfeBoxes from '../Components/FormMultiStepRecebimento/NfeBoxes';
import NfeBoxItems from '../Components/FormMultiStepRecebimento/NfeBoxItems';
import NfeItemEdit from '../Components/FormMultiStepRecebimento/NfeItemEdit';
import Deliveries from '../Components/FormMultiStepRecebimento/Deliveries';
import Acompanhamento from '../Components/FormMultiStepRecebimento/Acompanhamento';

import useNfeCaixaFlow, { NfeCaixaFlowProvider } from "../../../Hooks/useNfeReducer";
import Nps from '../Components/FormMultiStepRecebimento/Nps';
import SearchProduct from '../Components/FormMultiStepRecebimento/SearchProduct';
import Spinner from '../../../components/Spinner/Spinner';


const NfeProviderHOC = (Component) => (props) => {
    return <NfeCaixaFlowProvider>
        <Component {...props} />
    </NfeCaixaFlowProvider>;
};

const Recebimento = () => {
    const { state } = useNfeCaixaFlow();
    const navigate = useNavigate()

    useEffect(() => {
        try {
            const mainFullPage = document.getElementById('fullPage')
            mainFullPage.style.padding = '0'
            mainFullPage.style.zIndex = 1

            const chat = document.getElementById('chat')
            chat.style.display = 'none'
        } catch (error) {
            console.error(error)
        }
    }, [])

    useEffect(() => {
        navigate(`/Comercial/recebimento/${state.screen}`)
    }, [state.screen, navigate])

    return (
        <>
            {state.loading ? <Spinner /> :
                <Routes>
                    <Route exact="true" path="/:statusId" element={<Deliveries />} />

                    {state.data &&
                        <>
                            <Route exact="true" path="/divergenciasEntrega" element={<Acompanhamento />} />
                            <Route exact="true" path="/nfeBoxes" element={<NfeBoxes />} />
                            <Route exact="true" path="//nfeBoxItems/:boxId" element={<NfeBoxItems />} />
                            <Route exact="true" path="/nfeBoxItems/:boxId/insert" element={<SearchProduct />} />
                            <Route exact="true" path="/nfeBoxItems/:boxId/edit/:produtoId/:corId/:pedidoId/:itemId" element={<NfeItemEdit />} />
                            <Route exact="true" path="/nps" element={<Nps />} />
                        </>
                    }
                </Routes>
            }
        </>
    )
}

export default NfeProviderHOC(Recebimento)