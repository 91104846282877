import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';


let Demo = () => (
  <HelmetProvider>
    <Helmet>
      <script src="https://survey.survicate.com/workspaces/e95b6651ec10b1881da34d75a568603c/web_surveys.js" type="text/javascript" />
    </Helmet>
  </HelmetProvider>
)

export default Demo;