const encodeFilter = (filter) => (Object.entries(filter).filter(([, val]) => val === 0 ? true : val)
  .reduce((total, [f, val]) =>
    total + "&" + (typeof (val) === 'object' ? Object.entries(val).filter(([, val]) => val === 0 ? true : val)
      .reduce((part, [mod, val]) =>
        part + "&" + f + "[" + mod + "]=" + encodeURI(val)
        , "").slice(1)
      : f + "=" + encodeURI(val))
    , "").slice(1));

const pageList = (list, page, size) => list.slice(page * size, (page * size) + size)

export { encodeFilter, pageList };