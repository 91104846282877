export const DataExcelDivergencia = (results, produtos) => {
  const produtosProcessados = new Set();

  return results.flatMap((item) =>
    item.Caixas.flatMap(caixa =>
      produtos
        .filter(produto => produto.caixa === caixa.caixa)
        .filter(({ produto, tamanho }) => {
          const chaveUnica = `${item.entrega}_${produto}_${tamanho}`;
          if (produtosProcessados.has(chaveUnica)) return false;
          produtosProcessados.add(chaveUnica);
          return true;
        })
        .map((prod) => ({
          'Nº EMBARQUE': item.entrega,
          'DIVERGÊNCIA': prod.divergencia_tag.includes('sobra') || prod.divergencia_tag.includes('extra') ? 'sobra' : prod.divergencia_tag.join(', '),
          'DATA DO APONTAMENTO': new Date(prod.data_apontamento),
          'ULTIMA ALTERAÇÂO': new Date(item.ultima_alteracao || prod.data_apontamento),
          'NOTA FISCAL': caixa.nfSaida,
          'CLIFOR': item.clifor,
          'NOME MULTIMARCA': item.nome_multimarca,
          'SITUAÇÃO': prod.situacao,
          'PRODUTO': prod.produto,
          'COR': prod.cor,
          'TAMANHO': prod.tamanho,
          'QNTD': prod.quantidade,
          'PEDIDO': prod.pedido,
          'CAIXA': caixa.caixa,
          'ATENDENTE': item.atendente,

        }))
    )
  );
};
