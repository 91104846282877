import BackgroundImage1 from '../assets/images/Background/BackgroundLoginPage/backgroundImage1.png'
import BackgroundImage2 from '../assets/images/Background/BackgroundLoginPage/backgroundImage2.png'
import BackgroundImage3 from '../assets/images/Background/BackgroundLoginPage/backgroundImage3.png'
import BackgroundImage4 from '../assets/images/Background/BackgroundLoginPage/backgroundImage4.png'
import BackgroundImage5 from '../assets/images/Background/BackgroundLoginPage/backgroundImage5.png'
import BackgroundImage6 from '../assets/images/Background/BackgroundLoginPage/backgroundImage6.png'

import BackgroundImageResponsive1 from '../assets/images/Background/BackgroundLoginPageResponsive/backgroundImageResponsive1.png'
import BackgroundImageResponsive2 from '../assets/images/Background/BackgroundLoginPageResponsive/backgroundImageResponsive2.png'
import BackgroundImageResponsive3 from '../assets/images/Background/BackgroundLoginPageResponsive/backgroundImageResponsive3.png'
import BackgroundImageResponsive4 from '../assets/images/Background/BackgroundLoginPageResponsive/backgroundImageResponsive4.png'
import BackgroundImageResponsive5 from '../assets/images/Background/BackgroundLoginPageResponsive/backgroundImageResponsive5.png'
import BackgroundImageResponsive6 from '../assets/images/Background/BackgroundLoginPageResponsive/backgroundImageResponsive6.png'


const backgroundImages = [BackgroundImage1, BackgroundImage2, BackgroundImage3, BackgroundImage4, BackgroundImage5, BackgroundImage6]
const backgroundImagesResponsive = [BackgroundImageResponsive1, BackgroundImageResponsive2, BackgroundImageResponsive3, BackgroundImageResponsive4, BackgroundImageResponsive5, BackgroundImageResponsive6]
const randomNumber = Math.floor(Math.random() * backgroundImages.length)

export {backgroundImages,backgroundImagesResponsive,randomNumber}