import React from 'react'
import { v4 } from 'uuid'
import './SomaTableTitle.css'

const SomaTableTitle = props => {
    return (
        <thead className="thead__somatabletitle--conteiner">
            <tr className="tr__somatabletitle--conteiner" >
                {props.titles.map(item => {
                    if (!item) return null
                    if (item.show === false) return null
                    const classArray = [props.hasButton ? "th__somaTable--title fixedButton" : "th__somaTable--title"]
                    if (item.highlighted) classArray.push('th__somaTable--titlehighlighted')
                    return <th key={v4()} className={classArray.join(' ')}>{item.propText}</th>
                })}
            </tr>
        </thead>

    )
}

export default SomaTableTitle