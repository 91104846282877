import React, { useEffect } from 'react'
import { Routes, Route, useNavigate } from "react-router-dom";
import '../../../components/TableSettings/TableSettings.css';

import useDivergenciaNfeFlow, { DivergenciaFlowProvider } from '../../../Hooks/useDivergenciaReducer';

import ListaDivergencias from '../Components/TratativaDivergencia/ListaDivergencias';
import DivergenciasEntrega from '../Components/TratativaDivergencia/DivergenciasEntrega';

const NfeProviderHOC = (Component) => (props) => {
  return <DivergenciaFlowProvider>
    <Component {...props} />
  </DivergenciaFlowProvider>;
};

const Divergencias = () => {
  const navigate = useNavigate()
  const { state } = useDivergenciaNfeFlow();

  useEffect(() => {
    try {
      const mainFullPage = document.getElementById('fullPage')
      mainFullPage.style.padding = '0'
      mainFullPage.style.zIndex = 1

      const chat = document.getElementById('chat')
      chat.style.display = 'none'
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    navigate(`/Admin/divergencias/${state.screen}`)
  }, [state.screen, navigate])

  return (
    <Routes>
      <Route exact="true" path="/:statusId" element={<ListaDivergencias />} />
      {state.data &&
        <Route exact="true" path="/divergenciasEntrega" element={<DivergenciasEntrega />} />
      }
    </Routes >
  )
}

export default NfeProviderHOC(Divergencias)