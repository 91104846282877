import ErrorHandler from '../Errors/ErrorHandler';

const handleApi = async (api, args) => {
  let dataApi = undefined
  let metadataApi = undefined
  let error = undefined

  try {
    const { data, ...metadata } = await api(args);
    dataApi = data;
    metadataApi = metadata;
  } catch (e) {
    const handler = ErrorHandler(e);
    metadataApi = handler.stack;
    error = handler.message;
  }
  return { data: dataApi, error, metadata: metadataApi };
}

export default handleApi;