import React, { useState, useMemo, useCallback, useEffect } from 'react';
import SomaTable from '../../../../components/SomaStandard/SomaTable/SomaTable';
import SomaFilter from '../../../../components/SomaStandard/SomaFilter';
import { pageList } from '../../../../helpers/webHelper';
import SomaConfirm from '../../../../components/SomaStandard/SomaConfirm';
import Modal from '../../../../components/Modal';
import FormVendedor from '../FormVendedor';
import FormVendedorEdit from '../FormVendedorEdit';
import Tabelas from '../../../../components/SomaStandard/TableComponents/Tabelas';
import AffiliateButtonGroup from '../AffiliateButtonGroup/AffiliateButtonGroup';
import SomaStatus from '../../../../components/SomaStandard/SomaStatus';
import './ListaAfiliados.css'
import PrimaryButton from '../../../../components/PrimaryButton/PrimaryButton';
import TableSettings from '../../../../components/TableSettings/TableSettings'


const parseCPF = (cpf) => cpf.match(/\d/g)?.slice(0, 11).map((c, i) => { if (i === 3 || i === 6) return '.' + c; if (i === 9) return '-' + c; return c; }).join('');

const ListaAfiliados = (props) => {
  const hasButton = true;
  const [status, setStatus] = useState('result')

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [onConfirm, setOnConfirm] = useState(() => 0);
  const [open, setOpen] = useState(false);
  const [loadingRemoveVendor, setLoadingRemoveVendor] = useState(false)

  const [edit, setEdit] = useState(undefined);
  const [openModal, setOpenModal] = useState(false);

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [editInfo, setEditInfo] = useState({})

  const [filter, setFilter] = useState('')
  const [filteredResults, setFilteredResults] = useState([])

  const { reload } = props;

  const FilterOpts = {
    filterFields: [
      { propName: "affiliate_code", propText: "Codigo" },
      { propName: "affiliate_name", propText: "Nome" },
      { propName: "CPF", propText: "Cpf" }
    ],
    updateFilter: (e) => setFilter(e)
  }

  const data = useMemo(() => {
    return props.data?.children
      .filter((x) => x.deactivated_at === null)
      .sort((x, y) => x.created_at < y.created_at ? 1 : -1);
  }, [props.data.children]);

  const result = useMemo(() => filteredResults.length > 0 ? pageList(filteredResults, page, rowsPerPage) : []
    , [page, rowsPerPage, filteredResults]
  )

  const onHandlerFilter = useCallback(() => {
    setPage(0)
    setFilteredResults(
      Object.entries(filter).reduce((l, [k, p]) => {
        return l.filter((o) => {
          if (k === "CPF") {
            return o.info[k].toUpperCase().includes(p.toUpperCase())
          } else {
            return o[k].toUpperCase().includes(p.toUpperCase())
          }
        })
      }, data)
    )
    if (filteredResults.length > 0) {
      setStatus('result')
    } else {
      setStatus('empty')
    }
  }, [filter, data, filteredResults.length])

  useEffect(() => {
    onHandlerFilter()
  }, [onHandlerFilter])

  return (
    <>
      <SomaConfirm wait={loadingRemoveVendor} onConfirm={onConfirm} onCancel={() => setOpen(false)} open={open} message={'Ao excluir um(a) vendedor(a), você bloqueia o código do mesmo para uso.'} confirmText="Excluir Vendedor(a)" />
      <Modal open={openModal} modalName={edit ? "Editar" : "Cadastrar"} onClose={() => { setOpenModal(false); setEdit(undefined); }} hideButtons>
        <FormVendedor pai={props.data} reload={reload} />
      </Modal>
      <Modal open={openModalEdit} modalName={"Editar"} onClose={() => { setOpenModalEdit(false) }} hideButtons>
        <FormVendedorEdit data={editInfo} reload={reload} />
      </Modal>
      <TableSettings >
        <div style={{ margin: "10px 0" }}>
          <PrimaryButton disabled={props.status.bloqueio} onClick={() => { setOpenModal(true); setEdit(undefined); }} >Cadastrar Vendedor</PrimaryButton>
        </div>
        <SomaFilter returnOne {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <SomaTable size="small" results={result} columns={[
          {
            propName: "info", component: Tabelas, componentProps:
            {
              colunas: [
                { propName: "info", propText: "Nome vendedor(a)", component: (props) => props.line.info.full_name, highlighted: true },
                { propName: "info", propText: "CPF", component: (props) => parseCPF(props.line.info.CPF) },
                { propName: "info", propText: "Email", component: (props) => props.line.info.email },
                { propName: "affiliate_code", propText: "Código vendedor" },
                { propName: "info", component: AffiliateButtonGroup, componentProps: { reload, setEdit, setOpen, onConfirm, setOnConfirm, setEditInfo, setOpenModalEdit, setLoadingRemoveVendor } }
              ]
            }
          }
        ]}
          {...{
            page, setPage, rowsPerPage, setRowsPerPage, hasButton,
            total: filteredResults.length > 0 ? filteredResults.length : 0,
          }} />
      </SomaStatus>
    </>
  );
};

export default ListaAfiliados;