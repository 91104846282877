import { Table, TableFooter, TablePagination, TableRow } from '@mui/material'
import TablePaginationActions from "@mui/material/TablePagination/TablePaginationActions";

import './SomaTableFooter.css'

const SomaTableFooter = props => {
    const handleChangePage = (event, newPage) => {
        props.setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        props.setRowsPerPage(parseInt(event.target.value, 10));
        props.setPage(0);
    };

    const isDefined = (val) => val !== null && val !== undefined;

    return (
        <Table className="div__tablefooter--conteiner">
            {[
                props.total,
                props.rowsPerPage,
                props.page,
                props.setRowsPerPage,
                props.setPage,
            ].reduce((a, b) => a && isDefined(b)) ? (
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 25]}
                            count={props.total}
                            rowsPerPage={props.rowsPerPage}
                            page={props.page}
                            SelectProps={{
                                inputProps: { "aria-label": "rows per page" },
                                native: true,
                            }}
                            labelRowsPerPage={props?.labelRowsPerPage}
                            labelDisplayedRows={props?.labelDisplayedRows}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                        />
                    </TableRow>
                </TableFooter>
            ) : null}
        </Table>
    )
}

export default SomaTableFooter