import React from "react";
import styles from './ConverteParaIcone.module.css';

const ConverteParaIcone = (props) => {
  const status = props.options.find(opt => opt.key === props.children) || props.defaultValue;

  const styleDiv = {
    backgroundColor: status.color,
    width: props?.size,
    height: props?.size
  };

  const divProps = {
    className: `${styles.icone} ${styles[props.className]}`,
    title: status.value,
    style: styleDiv,
    "data-testid": "icon"
  }

  return (
    <div {...divProps}></div>
  );
};

export default ConverteParaIcone;
