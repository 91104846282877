import { Link } from '@mui/material';
import React, { useEffect, useState, useRef } from "react";
import { CSVLink } from "react-csv";

import useAffiliate from '../../../Hooks/AffiliateContext';

const GenerateCsv = (props) => {
  const csvLink = useRef()
  const [dataForDownload, setDataForDownload] = useState([]);
  const [bDownloadReady, setDownloadReady] = useState(false);

  const { affiliate, brands } = useAffiliate()
  //console.log(affiliate.brands)

  useEffect(() => {
    const formatValue = value => parseFloat(value).toFixed(2).replace('.', ',')

    const json = props.line.interna
    const soldBrands = [...new Set(json.map(vendor => Object.keys(vendor.groupedByBrand)).flatMap(vendor => vendor))]

    let result = (json.map((attr) => {

      const venda_total = formatValue(attr.venda_total) 

      let brands_venda = {         
        "Código Vendedorx": attr.vendedor,
        "Nome Vendedorx": attr.nome_vendedor,
        "Valor de Venda": venda_total
      }

      soldBrands.forEach(soldBrand => { 
        const atelierBrand = brands.find(brand => brand.id_marca_linx === +soldBrand)
        
        let nomeCampo = atelierBrand?.marca
        let valorCampo =  attr.groupedByBrand[atelierBrand?.id_marca_linx]

        brands_venda = {
          ...brands_venda, 
          [nomeCampo]: formatValue(valorCampo ? valorCampo : 0)
        }      
      })
      //console.log(brands_venda)     

      return brands_venda
      
    }))
    setDataForDownload(result)
  }, [affiliate.brands, brands, props.line.interna])
  
  useEffect(() => {
    if (csvLink && csvLink.current && bDownloadReady) {
      csvLink.current.link.click();
      setDownloadReady(false);
    }
  }, [bDownloadReady]);
  
  const handleAction = (actionType) => {
    if (actionType === 'DOWNLOAD') {   
      setDownloadReady(true);
    }
  }

  return (
    <div>
      <Link href="#" className={`${props.classes.flama} ${props.classes.s1} ${props.classes.semibold}`} onClick={(e) => handleAction('DOWNLOAD')} style={{textDecoration:"none"}}>{props.children}</Link>
      <CSVLink 
        data={dataForDownload} 
        filename={`${props.line.ano_mes}.csv`}
        className="hidden"
        ref={csvLink}
        target="_blank"
        separator={";"} />
    </div>
  )
}

export default GenerateCsv;