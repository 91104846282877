import React, { useState, useEffect } from 'react';
import SomaTable from '../../../components/SomaStandard/SomaTableOld';
import { pageList } from '../../../helpers/webHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';

const ListaVendas = (props) => {
    const [result, setResult] = useState(undefined);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    useEffect(() => {
        if(props.show){
            setResult(props.line.interna ? pageList(props.line.interna, page, rowsPerPage) : [])
        }
    }, [props.line.interna, page, rowsPerPage,props.show])

    return (
        <SomaTable size="small" results={result} columns={[
            { propName: "vendedor", propText: "Código Vendedorx" },
            { propName: "nome_vendedor", propText: "Nome Vendedorx" },
            { propName: "venda_total", propText: "Valor de Venda", component: ConverteDinheiro },
            // { propName: "qtd_pedidos_totais", propText: "Peças Vendidas" }
        ]} {...{ page, setPage, rowsPerPage, setRowsPerPage, total: props.line.interna ? props.line.interna.length : 0 }} />
    );
};

export default ListaVendas;