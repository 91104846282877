import React from 'react';
import MicrosoftIcon from '../../../../assets/images/icons/microsoftIcon.svg';
import SomaStatus from '../../../../components/SomaStandard/SomaStatus';
import InputsLoginForm from './InputsLoginForm/InputLoginForm';
import LogoMaisAtacado from '../../../../components/Logos/LogoMaisAtacado';
import LogoGrupoSoma from '../../../../components/Logos/LogoGrupoSoma';
import styles from './LoginForm.module.css';

const LoginForm = props => {
  const { login, senha } = props.credentials;

  return (
    <div className={styles.login}>
      <LogoMaisAtacado />
      <form className={styles.form} onSubmit={(e) => { e.preventDefault(); props.onConfirm(login, senha); }}>
        <InputsLoginForm onChange={props.onChange} login={login} senha={senha} />
        <div className={styles.passwordReset}>
          <span>Não sabe sua senha?</span>
          <span data-testid="spanForm" className={styles.clickHere} onClick={props.onForgotPassword}>Clique aqui.</span>
        </div>
        <button data-testid="buttonForm">
          <SomaStatus status={props.status === 'pending' ? 'waitComponent' : 'result'} >
            Entrar
          </SomaStatus>
        </button>
        <div className={styles.orLine}>
          <div></div>
          <small>ou</small>
          <div></div>
        </div>
      </form>
      <a href="https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=04926516-1a17-43fd-b4fc-6bdcefa062f0&response_type=code&redirect_uri=https://mais-atacado.somalabs.com.br/&response_mode=query&scope=openid%20email%20profile"
        className={styles.msLogin}>
        <img src={MicrosoftIcon} alt="Microsoft logo" />
        <span>entrar com microsoft</span>
        <div></div>
      </a>
      <LogoGrupoSoma />
    </div>
  );
};

export default LoginForm;
