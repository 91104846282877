import React, { useRef, useEffect } from 'react'
import './Nfe.css'

import { ReactComponent as RightArrow } from '../../../../assets/images/icons/right-arrow-productItem.svg'

const ButtonBack = ({ onClick }) => {

    const ref = useRef()

    const positionTop = (scrollTop) => {
        try {
            ref.current.style.top = `${75 - scrollTop}px`

        } catch (error) {
            //console.error(error)
        }
    }

    useEffect(() => {
        try {
            const mainPage = document.getElementById('mainPage')
            mainPage.scrollTop = 0
            mainPage.addEventListener('scroll', () => positionTop(mainPage.scrollTop))
            return () => {
                mainPage.removeEventListener('scroll', () => positionTop(mainPage.scrollTop))
            }
        } catch (error) {
            //console.error(error)
        }
    }, [])


    return (
        <button ref={ref} onClick={onClick} className='backButton' type='button'>
            <RightArrow />
            <span>voltar</span>
        </button>
    )
}
export default ButtonBack