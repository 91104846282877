import React from "react";
import { authFetch } from "../../../../helpers/authHelper";

import GetAppIcon from "@mui/icons-material/GetApp";
import TableButtonDownload from "../../../../components/SomaStandard/TableComponents/TableButtonDownload/TableButtonDownload";

import "./NFEButtonGroup.css";

const NfeGroup = (props) => {
  const handleDownloadClick = async (type) => {
    const fetchUrl =
      type === "xml"
        ? `/v1/NotaFiscal/xml/${props.line.CHAVE_NFE}`
        : `/v1/NotaFiscal/pdf/${props.line.CHAVE_NFE}`;

    await authFetch(fetchUrl)
      .then((res) => res.blob())
      .then((blob) => {
        let a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = type === "xml" ? "NotaFiscal.xml" : "NotaFiscal.pdf";
        a.click();
      });
  };

  return (
    <div className="div__nfebuttongroup--conteiner">
      <div style={{ marginRight: "16px" }}>
        <TableButtonDownload clicked={() => handleDownloadClick("xml")}>
          <GetAppIcon />
          <span>baixar xml</span>
        </TableButtonDownload>
      </div>
      <TableButtonDownload clicked={() => handleDownloadClick("pdf")}>
        <GetAppIcon />
        <span>baixar pdf</span>
      </TableButtonDownload>
    </div>
  );
};

export default NfeGroup;
