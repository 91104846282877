import React from 'react';
import './TableSettings.css';

const TableSettings = ({ children }) => (
  <div className="div__tablefeature--conteiner">
    {children}
  </div>
);

export default TableSettings;
