import React, { useState, useEffect } from 'react'

import GetAppIcon from "@mui/icons-material/GetApp";
import CheckIcon from '@mui/icons-material/PlaylistAddCheck';

import { authFetch } from '../../../../helpers/authHelper';
import { encodeFilter } from '../../../../helpers/webHelper';

import ButtonProduct from '../ButtonProducts';
import InfosDetalhar from './InfosDetalhar';

import SomaFilter from '../../../../components/SomaStandard/SomaFilter';
import SomaStatus from '../../../../components/SomaStandard/SomaStatus';
import SomaTable from '../../../../components/SomaStandard/SomaTable/SomaTable';
import ConverteData from '../../../../components/SomaStandard/TableComponents/ConverteData';
import ConverteDinheiro from '../../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import TableSettings from '../../../../components/TableSettings/TableSettings';
import TableButtonDownload from '../../../../components/SomaStandard/TableComponents/TableButtonDownload/TableButtonDownload';
import ToggleFilter from '../../../../components/SomaStandard/ToggleFilter';
import ToFrom from '../../../../components/SomaStandard/FilterComponents/ToFrom';
import DatePicker from '../../../../components/SomaStandard/FilterComponents/DatePicker';
import DropdownMenu from '../../../../components/SomaStandard/FilterComponents/DropdownMenu';
import useNfeCaixaFlow from '../../../../Hooks/useNfeReducer';

import AddIcon from '@mui/icons-material/Add';
import Tag from '../../../../components/Tag/Tag';
import TableButton from '../../../../components/SomaStandard/TableComponents/TableButton/TableButton';
import { toast } from 'react-toastify';
import useUser from '../../../../Hooks/UserContext';
import { useParams } from 'react-router-dom';

const BaixarNotas = (props) => {
  const handleDownloadClick = async (props) => {
    await authFetch(`/v1/recebimento/baixar-notas`, 'POST', props.line.NFS)
      .then((res) => {
        if (res.status === 500) {
          throw new Error("Erro na requisição");
        }
        return res.blob();
      })
      .then((blob) => {
        let a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = 'NotasFiscais.zip';
        a.click();
      }).catch((error) => {
        toast.error("Ocorreu um erro ao baixar as notas fiscais, tente novamente.")
      });
  };

  const referenceDate = new Date('2023-07-31T00:00:00.000Z');
  const isToDownload = props.line?.EMISSAO && new Date(props.line?.EMISSAO).getTime() > referenceDate.getTime();

  return (
    isToDownload && <TableButtonDownload clicked={() => handleDownloadClick(props)} width="80px">
      <GetAppIcon />
      <span>PDF NF</span>
    </TableButtonDownload>
  );
};

const DivergencyTags = (props) => {
  return (
    <div>
      {props.line.divergenciaTag.includes("falta") && (<Tag name="Falta" color="#F3AF00" />)}
      {props.line.divergenciaTag.some(tag => ["extra", "sobra"].includes(tag)) && (<Tag name="Sobra" color="#2596DA" />)}
    </div>
  )
}

const Deliveries = () => {
  const { statusId } = useParams()
  const user = useUser();
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState('');
  const statusEntrega = parseInt(statusId);
  const { onResultsNfe, typeStatusEntrega, tratativaMultimarca } = useNfeCaixaFlow();

  const DropdownOptsDivergenceFlow = [
    { key: 3, value: "AGUARDANDO CONFERÊNCIA", color: "#E3CC6C" },
    { key: 2, value: "FINALIZADAS COM DIVERGÊNCIA", color: "#D66157" },
    { key: 1, value: "FINALIZADAS SEM DIVERGÊNCIA", color: "#148A6A" },
  ];

  const DropdownOptsOthers = [
    { key: 3, value: "AGUARDANDO CONFERÊNCIA", color: "#E3CC6C" },
    { key: 1, value: "CONFERÊNCIAS FINALIZADAS", color: "#148A6A" },
  ];

  const DropdownOpts = user?.features?.recebimento ? DropdownOptsDivergenceFlow : DropdownOptsOthers;

  const ButtonCheck = (props) => {
    const chavesNFe = (p) => p.line.NFS.map((nf) => { return nf.CHAVE_NFE.trim() });
    const isCheckButton = props.line.STATUS_RECEBIMENTO === 'aguardando conferência';

    return (
      <TableButton clicked={() => onResultsNfe(chavesNFe(props))} highlighted={isCheckButton}>
        <CheckIcon />
        {isCheckButton ? <span>Conferir</span> : <span>Revisar</span>}
      </TableButton>
    );
  };

  const ButtonMore = (props) => {
    const click = () => tratativaMultimarca(props.line?.ROMANEIO);
    const isHighlighted = props.line?.HAS_UPDATE;

    return (
      <TableButton clicked={click} highlighted={isHighlighted}>
        <AddIcon />
        <span>MAIS</span>
      </TableButton>
    )
  }

  let Opts = {
    endpoint: `/v1/recebimento/romaneios/${statusEntrega}`,
    columns: [
      {
        propName: "ROMANEIO",
        lineHighlighted: (e) => !!e.HAS_UPDATE,
        highlightedColor: "#D661571F",
        componentProps: {
          colunas: [
            {
              propName: "",
              propText: "",
              componentProps: { DropdownOpts },
              component: (e) => {
                const color = DropdownOpts.find(item => item.key === statusEntrega)?.color;
                const style = { backgroundColor: color ? color : "#E3CC6C", width: "10px", height: "10px", borderRadius: "50px" }
                return <div style={style}></div>
              },
            },
            { propName: "ROMANEIO", propText: "Nº EMBARQUE", highlighted: true },
            { propName: "EMBARQUE", propText: "DATA DE EMBARQUE", component: ConverteData },
            { propName: "QTDE_CAIXAS", propText: "N° DE CAIXAS" },
            { propName: "QTDE_NFES", propText: "QTDE. DE NF" },
            { propName: "QTDE_PRODUTOS", propText: "ITENS" },
            { propName: "VALOR_TOTAL", propText: "VALOR TOTAL", component: ConverteDinheiro },
            statusEntrega === 2
              ? {
                propName: "",
                propText: "DIVERGÊNCIA",
                component: DivergencyTags,
              }
              : null,
            statusEntrega === 2
              ? { propName: "", propText: "", component: ButtonMore, isButton: true }
              : null,
            { propName: "", propText: "", component: ButtonCheck, isButton: true },
            { propName: "", propText: "", component: ButtonProduct, isButton: true },
            {
              propName: "",
              propText: "",
              component: BaixarNotas,
              isButton: true
            },
          ],
          infoList: {
            component: (props) => {
              return <InfosDetalhar
                show={props.show}
                data={props.line.NFS}
                columns={[
                  { propName: "NF", propText: "Notas fiscais" },
                  { propName: "QTDE_PRODUTOS", propText: "Itens na Nota" },
                  { propName: "VALOR_NFE", propText: "Valor", component: ConverteDinheiro },
                ]}
                totalPage={props.line.QTDE_NFES}
              />
            }
          }
        }
      }
    ],
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      { propName: "ROMANEIO[lk]", propText: "Nº de Embarque", format: val => `%${val}%` },
      { propName: "NF", propText: "Nota Fiscal" },
      { propName: "EMBARQUE", component: ToFrom, propText: "Embarque", componentProps: { component: DatePicker } },
      {
        propName: "TIPO", propText: "Tipo", show: { filter: statusEntrega === 2 }, component: DropdownMenu, componentProps: {
          options: [
            { text: "SOBRA", value: "sobra" },
            { text: "FALTA", value: "falta" },
          ],
        },
      },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  const toggleOpts = {
    opts: DropdownOpts,
    valorAtivo: statusEntrega || "1",
    handleChange: (e, newAlignment) => {
      if (newAlignment) {
        setFilter("")
        setPage(0);
        typeStatusEntrega(newAlignment)
      }
    },
  };

  useEffect(() => {
    const abortController = new AbortController();
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(
          `${Opts.endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`,
          undefined,
          undefined,
          abortController.signal
        ))
          .json();
        setResults(json.results);
        setTotal(json.total);
        setStatus(json.results?.length ? 'result' : 'empty');
      } catch (err) {
        setResults(undefined);
        if (abortController.signal.aborted) setStatus('wait');
        else setStatus('error');
      }
    })();

    return () => {
      abortController.abort();
    };
  }, [Opts.endpoint, page, rowsPerPage, filter]);

  return (
    <div style={{ padding: "0 31px" }}>
      <TableSettings>
        <ToggleFilter {...toggleOpts} />
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <SomaTable {...Opts} />
      </SomaStatus>
    </div>
  );
};


export default Deliveries