import ErrorWithToast from "./ErrorWithToast";

class CantReachDB extends ErrorWithToast {
  constructor(error) {
    super("Ocorreu um Erro de conexão com nosso banco, tente novamente mais tarde");

  }
}

export default CantReachDB;
