import React, { useState } from "react";

import "./TableButtonDownload.css";

const TableButtonDownload = (props) => {
  const [disabled, setDisabled] = useState(props.disabled || false);

  const handleButtonClick = async () => {
    setDisabled(true);
    if (props.clicked) {
      await props.clicked();
    }
    setDisabled(false);
  };

  return (
    <button
      className="button__TableButtonDownload--container"
      disabled={disabled}
      onClick={handleButtonClick}
      style={{ width: props.width || "110px" }}
    >
      {disabled ? (
        <div className="loader__TableButtonDownload" />
      ) : (
        props.children
      )}
    </button>
  );
};

export default TableButtonDownload;
