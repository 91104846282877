import React from 'react'

import PrimaryButton from '../PrimaryButton/PrimaryButton'
import SecondaryButton from '../SecondaryButton/SecondaryButton'

import CloseBtnImg from '../../assets/images/icons/close_btn.svg'

import './Modal.css'
import { Modal as ModalMui } from '@mui/material'

const Modal = props => {

    const {
        open,
        modalName,
        modalDescription,
        onClose,
        onBack,
        action,
        actionName,
        closeName,
        backName,
        hideButtons,
        isUploading,
        mainClassName,
        buttonClassName,
        notInputs,
        finishButtonDisabled,
        current,
        total,
        style,
        children,
    } = props;

    return (
        <ModalMui open={open} className='div__backdrop--container' onClose={onClose} style={{ outline: 'none' }}>
            <div className="div__modal__container" style={style}>

                {current ? <p className='p__currentmodal--text'>{current + "/" + total}</p> : <></>}
                <img src={CloseBtnImg} alt="Botão de fechar" className="img__btnclose--container" onClick={onClose} />

                <main className={mainClassName || "main__contentmodal--container"}>
                    {modalName && <h1 className="h1__modaltitle--text"> {modalName} </h1>}
                    <div> {children} </div>
                    <h4 className="h4__warning--text">{modalDescription}</h4>
                </main>
                {
                    hideButtons ? null
                        :
                        <footer style={(!onBack && notInputs) ? { flexDirection: 'column' } : { flexDirection: 'row' }} className="footer__footermodal--container">
                            {onClose && !notInputs ? <SecondaryButton onClick={onClose}> <span> {closeName || 'fechar'} </span> </SecondaryButton> : null}
                            {onBack ? <SecondaryButton onClick={onBack}> <span> {backName || 'fechar'} </span> </SecondaryButton> : null}
                            {action ? <PrimaryButton additionalClassName={`btn__primarybuttonspacing--element ${isUploading && 'btn__primarybutton--loading'} ${buttonClassName}`} disabled={isUploading || finishButtonDisabled} onClick={action}> <span className={isUploading ? 'button__activeevent--hiddecontent' : ''}> {actionName || 'salvar'} </span> </PrimaryButton> : null}
                        </footer>
                }
            </div>
        </ModalMui>
    )
}

export default Modal