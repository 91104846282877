import React from 'react'
import s from './HeaderItems.module.css'

const HeaderRows = (props) => {
    const { rows } = props

    return (
        <div className={s.boxDetails}>
            {rows.map((r, i) => (
                <div key={r.key + r.value} className={r.highligthed ? s.productsDetailsHighlighted : ""} {...r.componentProps}>
                    <span>{r.key}</span>
                    {r.oldValue ?
                        <>
                            <span className={s.lineThrough}>{r.oldValue}</span>
                            <span>{r.value}</span>
                        </> :
                        <span>{r.value}</span>
                    }
                </div>
            ))}
        </div>
    )
}

export default HeaderRows