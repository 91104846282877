import React, { useCallback, useEffect, useMemo, useState } from 'react'

import MaisAtacado from '../../../../../Services/MaisAtacado/MaisAtacado'

import ConverteDinheiroFunction from '../../../../../Utils/ConverteDinheiroFunction'

import HomeHeaderItem from './HomeHeaderItem/HomeHeaderItem'

import './HomeHeader.css'


const HomeHeader = () => {

    const [data, setData] = useState({ totalPagar: null, creditos: null, vendasAfiliados: null })
    const [loading, setLoading] = useState(false)
    const [loadingCreditos, setLoadingCreditos] = useState(false)

    const anoMes = useMemo(() => {
        const dateObj = new Date()
        let month = dateObj.getUTCMonth() + 1
        let year = dateObj.getUTCFullYear()

        if (month < 10) month = "0" + month
        year = year.toString().substr(-2);

        return month + "/" + year
    }, [])

    const onGetHomeData = useCallback(async () => {
        try {
            setLoading(true)
            const res = await MaisAtacado.get("/v1/home")
            setLoading(false)
            setData(prevState => ({
                ...prevState,
                totalPagar: res.data.totalPagar ? ConverteDinheiroFunction(res.data.totalPagar[""]) : '-',
                vendasAfiliados: res.data.vendasAfiliados ? ConverteDinheiroFunction(res.data.vendasAfiliados) : '-'
            }))
        } catch (err) {
            setLoading(false)
            setData(prevState => ({ ...prevState, totalPagar: '-', vendasAfiliados: '-' }))
            console.log(err)
        }

    }, [])

    const onGetCreditosHome = useCallback(async () => {
        try {
            setLoadingCreditos(true)
            const res = await MaisAtacado.get("/v1/home/credito")
            setLoadingCreditos(false)
            setData(prevState => ({
                ...prevState,
                creditos: res.data.creditoDisponivel ? ConverteDinheiroFunction(res.data.creditoDisponivel.SALDO_CREDITO) : '-'
            }))
        } catch (err) {
            setLoadingCreditos(false)
            setData(prevState => ({ ...prevState, creditos: '-' }))
        }
    }, [])

    useEffect(() => {
        onGetHomeData()
        onGetCreditosHome()
    }, [onGetHomeData, onGetCreditosHome])

    return (
        <div className="div__homeHeader--conteiner">
            <HomeHeaderItem title="Total a pagar" loading={loading} value={data.totalPagar} />
            <HomeHeaderItem title="Créditos disponíveis" loading={loadingCreditos} value={data.creditos} />
            <HomeHeaderItem title={`Vendas Afiliados - ${anoMes}`} loading={loading} value={data.vendasAfiliados} />
        </div>
    )
}


export default HomeHeader