import ErrorWithToast from "./ErrorWithToast";

class CantReachBackend extends ErrorWithToast {
  constructor(error) {
    super("Ocorreu um Erro de conexão, verifique sua internet ou tente novamente mais tarde");

  }
}

export default CantReachBackend;
