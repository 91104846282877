import { useCallback, useState } from 'react';
import ErrorHandler from '../Errors/ErrorHandler';

const useApi = (api) => {
  const [status, setStatus] = useState('ready');
  const [data, setData] = useState(undefined);
  const [metadata, setMetadata] = useState(undefined);
  const [error, setError] = useState(undefined);

  const dispatch = useCallback(async (...args) => {
    setStatus('pending');
    setData(undefined);
    setMetadata(undefined);
    setError(undefined);
    try {
      const { data, ...metadata } = await api(...args);
      setStatus('ready');
      setData(data);
      setMetadata(metadata);
    } catch (e) {
      const error = ErrorHandler(e);
      setStatus('ready');
      setMetadata(error.stack);
      setError(error.message || e);
    }
  }, [api]);

  return { dispatch, status, data, error, metadata };
}
export default useApi;