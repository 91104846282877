import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import useUser, { useAuth } from "../../Hooks/UserContext";
import useQuery from "../../Hooks/useQuery";

const Auth = () => {
  const user = useUser();
  const query = useQuery();

  const { retokenSomaAuth } = useAuth();

  useEffect(() => {
    const retokenDispatch = retokenSomaAuth.dispatch;
    if (query.code) {
      retokenDispatch(query.code);
    }
  }, [query.code, retokenSomaAuth.dispatch]);

  useEffect(() => {
    if (retokenSomaAuth.data) {
      localStorage.setItem('CurUser', retokenSomaAuth.data);
    }
  }, [retokenSomaAuth.data]);

  if (user) return <Navigate replace={true} to="/" />;

  return <></>;
};

export default Auth;
