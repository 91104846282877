import React, { useState, useRef } from 'react'
import styles from './ItemsRow.module.css';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Item from './Item/Item';

const ItemsRow = ({ title, items, widthCard }) => {
    const targetRef = useRef();
    const [scrollX, setScrollX] = useState(0)

    const handleLeftArrow = () => {
        let valueScrollX = scrollX + Math.round(targetRef.current.offsetWidth / 2)

        if (valueScrollX > 0) valueScrollX = 0
        setScrollX(valueScrollX)
    }

    const handleRightArrow = () => {
        let valueScrollX = scrollX - Math.round(targetRef.current.offsetWidth / 2)
        let listMaxWidth = items.length * widthCard

        if (targetRef.current.offsetWidth - listMaxWidth > valueScrollX) {
            valueScrollX = targetRef.current.offsetWidth - listMaxWidth
        }

        setScrollX(valueScrollX)
    }

    return (
        <div ref={targetRef} className={styles.ItemsRow}>
            <h3>{title}</h3>

            <div className={styles["ItemsRow--listarea"]}>
                <div className={styles["ItemsRow--left"]} onClick={handleLeftArrow}>
                    <NavigateBeforeIcon style={{ color: '#666666', fontSize: 50 }} />
                </div>

                <div className={styles["ItemsRow--right"]} onClick={handleRightArrow}>
                    <NavigateNextIcon style={{ color: '#666666', fontSize: 50 }} />
                </div>

                <div className={styles["ItemsRow--list"]} style={{
                    marginLeft: scrollX,
                    width: items.length * widthCard
                }}>
                    {items.length > 0 && items.map((item, key) => {
                        return (<Item key={key} item={item} />)
                    })}

                </div>
            </div>
        </div>
    )
}

export default ItemsRow