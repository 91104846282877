import React from "react";
import "./SomaTable.css";

import SomaTableTitle from "./SomaTableTitle/SomaTableTitle";
import SomaTableBody from "./SomaTableBody/SomaTableBody";
import SomaTableFooter from "./SomaTableFooter/SomaTableFooter";

const SomaTable = (props) => {
  return props.results ? (
    <>
      <div className="div__somatable--conteiner">
        <table className="table__somatable--conteiner">
          <SomaTableTitle
            titles={props.columns[0].componentProps.colunas}
            hasButton={props.hasButton}
          />
          <SomaTableBody
            data={props.results}
            checked={props.optionSelectedSingle}
            hasButton={props.hasButton}
            columns={props.columns[0].componentProps.colunas}
            infoLine={props.columns[0].componentProps.infoList}
            lineHighlighted={props.columns[0].lineHighlighted}
            highlightedColor={props.columns[0].highlightedColor}
          />
        </table>
      </div>
      <SomaTableFooter
        total={props.total}
        rowsPerPage={props.rowsPerPage}
        page={props.page}
        setRowsPerPage={props.setRowsPerPage}
        setPage={props.setPage}
        labelDisplayedRows={props?.labelDisplayedRows}
      />
    </>
  ) : null;
};

export default SomaTable;
