import React, { useState, useEffect } from "react";

import Rating from "@mui/material/Rating";
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from "@mui/icons-material/StarBorder";

import { ReactComponent as CloseIcon } from '../../../../assets/images/icons/CloseIcon.svg'
import { ReactComponent as ConfirmIcon } from '../../../../assets/images/icons/ConfirmIcon.svg'
import { ReactComponent as BackArrowIcon } from '../../../../assets/images/icons/backArrow.svg'

import "./HomeNPS.css";

function StepOne({ question, score, setScore, setIsFirstStep }) {
  return (
    <>
      <p className="p__HomeNPS--text">{question}</p>
      <div className="div__HomeNPS--ratingContainer">
        <Rating
                name="customized-empty1"
                value={score}
                size="large"
                onChange={(e, newValue) => {
                  setScore(newValue);
                }}
                icon={<StarIcon style={{ fontSize: 45, color: "#E3CC6C" }} />}
                emptyIcon={<StarBorderIcon  style={{ fontSize: 45, color: "#757575"}} />}
                style={{ paddingLeft: 5, paddingRight: 5 }}
              />
        <div className="div__HomeNPS--ratingText">
          <small>Muito <p /> insatisfatória</small>
          <small>Muito <p /> satisfatória</small>
        </div>
      </div>
      <button
        className={score ? "button_HomeNPS--buttonActivated" : "button_HomeNPS--buttonDeactivated"}
        onClick={() => setIsFirstStep(false)}
        disabled={!score}
      >
        PROSSEGUIR
      </button>
    </>
  );
}

function StepTwo({ responseText, options, handleCheck, handleComments, handleSendNPS, hasChecked, setIsFirstStep }) {
    return (
    <>
      <button className="button__HomeNPS--backButton" onClick={() => setIsFirstStep(true)}>
        <BackArrowIcon className="arrow__HomeNPS--backArrow" />
        VOLTAR
      </button>
      <p className="p__HomeNPS--text">{responseText}</p>
      <div style={{ maxWidth: "360px"}}>
      {options.map((item, index) => (
        <button
          key={"category_button-"+ index}
          onClick={handleCheck}
          name={item.category}
          className={item.checked ? "button__HomeNPS--categoryButtonActivated" : "button__HomeNPS--categoryButtonDeactivated"}
        >
          {item.category}
        </button>
      ))}
      </div>
      <p className="p__HomeNPS--text">
        <span>Adicione um comentário: </span>
        <span style={{ color: "#BDBDBD" }}>(opcional)</span>
      </p>
      <textarea className="textarea_HomeNPS--text" rows="6" onChange={handleComments}/>
      <button
        className={hasChecked ? "button_HomeNPS--buttonActivated" : "button_HomeNPS--buttonDeactivated"}
        disabled={!hasChecked}
        onClick={handleSendNPS}
      >
        ENVIAR
      </button>
    </>
  );
}

function StepThree({ onClose }) {
  return (
    <div className="div__HomeNPS--sentContainer">
      <div className="div__HomeNPS--closeSentContainer">
        <CloseIcon onClick={onClose} />
      </div>
      <ConfirmIcon />
      <p className="p__HomeNPS--textSent">Sua resposta foi enviada com sucesso ao nosso time. Obrigado pelo seu feedback!</p>
      <button className="button_HomeNPS--buttonActivated" onClick={onClose}>OK</button>
    </div>
  );
}

export default function HomeNPS(props) {
  const { nps, isSent, onClose, onSend } = props;
  const [score, setScore] = useState(0);
  const [options, setOptions] = useState([]);
  const [question, setQuestions] = useState({});
  const [comments, setComments] = useState("");
  const [isFirstStep, setIsFirstStep] = useState(true);
  const [hasChecked, setHasChecked] = useState(false);


  useEffect(()=>{
      setQuestions(nps.Questions[0])
      setOptions(nps.Questions[0].Categories.map((category) => ({
        ...category,
        checked: false
      })))
  },[onClose, nps]);

  const handleCheck = (e) => {
    const name = e.target.name;
    const newOptions = options.map((item) => {
      if (item.category === name) {
        return {
          ...item,
          checked: !item.checked,
        };
      } else {
        return item;
      }
    });
    setHasChecked(newOptions.some((item) => item.checked));
    setOptions(newOptions);
  };

  const handleComments = (e) => {
    setComments(e.target.value);
  };

  const handleSendNPS = () => {
    const checkedOptions = options.filter((item) => item.checked);
    const categories = checkedOptions.map(obj => {
      const { idCategory } = obj;
      return {id_category : idCategory} ;
    });
    const data = {
      id_question: question.idQuestion, 
      score,
      comments: comments,
      Categories: categories
    };
    onSend(data)
  }

  const responseText = () => {
    switch (score) {
      case 1:
      case 2:
      case 3:
        return "Lamentamos ouvir isso. Você se importaria de nos dizer em que áreas podemos melhorar?";
      case 4:
      case 5:
        return "Uau, isso é ótimo! Nossa equipe adoraria saber quais áreas se destacam pra você:";
      default:
        return "";
    }
  };

  return (
    <div className="div__HomeNPS--backdrop">
      <div className="div__HomeNPS--container">
        {isFirstStep ? (
          <StepOne question={question.question} score={score} setScore={setScore} setIsFirstStep={setIsFirstStep} />
        ) : (
          <>
            {!isSent ? (
              <StepTwo
                responseText={responseText()}
                options={options}
                handleCheck={handleCheck}
                handleComments={handleComments}
                handleSendNPS={handleSendNPS}
                hasChecked={hasChecked}
                setIsFirstStep={setIsFirstStep}
              />
            ) : (
              <StepThree onClose={onClose} />
            )}
          </>
        )}
      </div>
    </div>
  );
}
