import React, { useState, useEffect } from "react";

import useApi from "../../../Hooks/useApi";
import useUser from "../../../Hooks/UserContext";
import MaisAtacado from "../../../Services/MaisAtacado";
import Boleto from "../../../Services/MaisAtacado/Boleto";

import GetAppIcon from "@mui/icons-material/GetApp";
import SomaStatus from "../../../components/SomaStandard/SomaStatus";
import SomaFilter from "../../../components/SomaStandard/SomaFilter";
import ToggleFilter from "../../../components/SomaStandard/ToggleFilter";
import TableSettings from "../../../components/TableSettings/TableSettings";
import SomaTable from "../../../components/SomaStandard/SomaTable/SomaTable";
import ToFrom from "../../../components/SomaStandard/FilterComponents/ToFrom";
import DatePicker from "../../../components/SomaStandard/FilterComponents/DatePicker";
import ConverteData from "../../../components/SomaStandard/TableComponents/ConverteData";
import ConverteParaIcone from "../../../components/SomaStandard/TableComponents/ConverteParaIcone";
import ConverteDinheiro from "../../../components/SomaStandard/TableComponents/ConverteDinheiro";
import TableButtonDownload from "../../../components/SomaStandard/TableComponents/TableButtonDownload/TableButtonDownload";
import VisaoInadimplencia from "../Components/VisaoInadimplencia";
import PageLoading from "../Components/PageLoading";

const getStateFromApi = (api) => {
  if (api.status === "ready") {
    if (api.error) {
      return "error";
    }
    if (api.data?.results.length > 0) {
      return "result";
    } else {
      return "empty";
    }
  } else {
    return "wait";
  }
};
const SViaBoleto = (props) => {
  const user = useUser();
  const isRepre = user.features?.repre
  const hasButton = true;

  const endDate = new Date();
  const startDate = new Date();
  startDate.setFullYear(startDate.getFullYear() - 1);

  const [dateFilter] = useState({ gte: startDate.toISOString(), lte: endDate.toISOString() });
  const [clients, setClients] = useState([{ COD_CLIFOR: 'TODOS', NOME_CLIFOR: '' }]);
  const [isUpdating, setIsUpdating] = useState(false);
  const boletos = useApi(MaisAtacado.Boleto.getBoletos);
  const state = getStateFromApi(boletos);
  const total = boletos.data?.total || 0;
  const results =
    boletos.data?.results.map((item, index) => ({
      ...item,
      id_fatura: index,
    })) || [];
  const initialValueFilter = { "A.posicao[in]": ["A VENCER", "VENCENDO HOJE"] };

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [statusBoleto, setStatusBoleto] = useState(null);
  const [filter, setFilter] = useState(initialValueFilter);

  const DropdownExtraOpt = [{ key: "VENCENDO HOJE", value: "VENCENDO HOJE", color: "#E3CC6C" }]
  const DropdownOpts = [
    { key: "A VENCER", value: "A VENCER", color: "#E3CC6C" },
    { key: "RECEBIDO", value: "PAGAS", color: "#148A6A" },
    { key: "VENCIDOS", value: "VENCIDAS", color: "#D66157" },
  ];

  const handleClick = async (props) => {
    const data = {
      cnpj: props.line.cgc_cpf.trim(),
      fatura: props.line.fatura,
      parcela: props.line.id_parcela,
      serie: props.line.serie,
    };

    await Boleto.getBoletoPdf(data);
  };

  const BaixarBoleto = (props) => {
    return (
      <TableButtonDownload clicked={() => handleClick(props)}>
        <GetAppIcon />
        <span>Baixar pdf</span>
      </TableButtonDownload>
    );
  };

  let labelDisplayedRows = ({ from, to, count }) => `${from}-${to} de ${count !== -1 ? count : `mais do que ${to}`}`

  let Opts = {
    columns: [
      {
        propName: "FILIAL",
        lineHighlighted: (colunas) => "VENCENDO HOJE" === colunas["posicao"],
        componentProps: {
          colunas: [
            {
              propName: "posicao",
              propText: "Status",
              componentProps: { options: [...DropdownOpts, ...DropdownExtraOpt] },
              component: ConverteParaIcone,
            },
            (isRepre) ?
              {
                propName: "cliente_cod_clifor",
                propText: "Clientes",
              }
              : null,
            {
              propName: "lx_tipo_documento",
              propText: "Tipo",
            },
            {
              propName: "fatura_parcela",
              propText: "Fatura",
              highlighted: true,
            },
            {
              propName: "emissao",
              propText: "Emissão",
              component: ConverteData,
            },
            {
              propName: "vencimento_real",
              propText: "Vencimento",
              columnHighlighted: (colunas) =>
                "VENCENDO HOJE" === colunas["posicao"],
            },
            { propName: "dias_atraso", propText: "D. de Atraso" },
            {
              propName: "data_pagamento",
              propText: "data de pgto",
              component: ConverteData,
            },
            {
              propName: "valor_original_padrao",
              propText: "Total",
              component: ConverteDinheiro,
            },
            {
              propName: "valor_mov_padrao",
              propText: "Pago",
              component: ConverteDinheiro,
            },
            {
              propName: "saldo_principal_devido",
              propText: "Restante",
              component: ConverteDinheiro,
            },
            statusBoleto !== "RECEBIDO"
              ? {
                propName: "baixar_boleto",
                propText: "Baixar boleto",
                component: BaixarBoleto,
              }
              : null,
          ],
        },
      },
    ],
    hasButton,
    total,
    results,
    page,
    setPage,
    rowsPerPage,
    setRowsPerPage,
    labelDisplayedRows
  };

  const updateFilter = (e) => {
    if (e['A.vencimento_real'].lte == null) delete e['A.vencimento_real'].lte;
    if (e['A.vencimento_real'].gte == null) delete e['A.vencimento_real'].gte;

    if (e['B.data_pagamento']?.lte == null) delete e['B.data_pagamento'].lte;
    if (e['B.data_pagamento']?.gte == null) delete e['B.data_pagamento'].gte;

    setFilter((prev) => ({ ...prev, ...e }));
    setPage(0);
  };

  const FilterOpts = {
    filterFields: [
      {
        propName: "A.fatura[lk]",
        propText: "Fatura",
        format: (val) => `%${val}%`,
      },
      { propName: "A.valor_original", component: ToFrom, propText: "Valor" },
      {
        propName: "A.vencimento_real",
        component: ToFrom,
        propText: "Vencimento",
        componentProps: { component: DatePicker, minDate: startDate, maxDate: endDate },
      },
      {
        propName: "B.data_pagamento",
        component: ToFrom,
        propText: "Data de Pagamento",
        componentProps: { component: DatePicker },
      },
    ],
    updateFilter,
  };

  const toggleOpts = {
    opts: DropdownOpts,
    valorAtivo: statusBoleto || "A VENCER",
    handleChange: (event, newAlignment) => {
      if (newAlignment) {
        setFilter((prev) => {
          const column = Object.keys(prev).find((key) =>
            key.includes("posicao")
          );
          delete prev[column];

          if (newAlignment === "A VENCER")
            return { ...prev, "A.posicao[in]": ["A VENCER", "VENCENDO HOJE"] };
          return { ...prev, "A.posicao[lk]": newAlignment };
        });
        setPage(0);
        setStatusBoleto(newAlignment);
      }
    },
  };

  useEffect(() => {
    const dispatch = boletos.dispatch;
    const venc = filter?.["A.vencimento_real"];
    const order = filter?.["A.posicao[lk]"] === "RECEBIDO" ? "desc" : "asc";

    let newFilter = {};

    (!statusBoleto || statusBoleto === 'A VENCER') ?
      newFilter = { ...filter } :
      newFilter = {
        ...filter, "A.vencimento_real": {
          gte: venc?.gte || dateFilter.gte,
          lte: venc?.lte || dateFilter.lte,
        }
      }

    dispatch(clients, newFilter, { sz: rowsPerPage, pg: page, order });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [boletos.dispatch, page, rowsPerPage, filter, dateFilter, clients]);

  return (
    <>
      <PageLoading isLoading={isUpdating} />
      <div style={{ display: isUpdating ? "none" : "" }}>
        <VisaoInadimplencia setClients={setClients} setIsUpdating={setIsUpdating} isRepre={user.features?.repre} />
        <TableSettings>
          <ToggleFilter {...toggleOpts} />
          <SomaFilter {...FilterOpts} />
        </TableSettings>
        <SomaStatus status={state} emptyText={"Sem resultados para essa busca :)"}>
          <SomaTable {...Opts} />
        </SomaStatus>
      </div>
    </>
  );
};

export default SViaBoleto;
