import React from "react"
import styles from './ProgressBar.module.css'

const ProgressBar = (props) => {
    const { progress, total, description } = props;

    return (
        <div>
            <div className={styles.container}>
                <div style={{ width: `${(progress * 100) / total}%` }} className={styles.progressBar}></div>
            </div>
            <p className={styles.description}>{description}</p>
        </div>
    )
}

export default ProgressBar