import React, { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Grid, Collapse, IconButton, Paper } from '@mui/material';
import { useFontStyles } from '../../../helpers/styleHelper';

const Tabelas = (props) => {
  const [open, setOpen] = useState(false)
  const classes = useFontStyles();

  return (
    <Grid container justifyContent="flex-start" alignItems="center" style={{ paddingBottom: "15px" }}>
      <Grid item xs={12} style={{ paddingLeft: "10%", paddingRight: "10%" }}>
        <Grid container justifyContent="center">
          <Grid style={{ width: '100%', borderTop: "1px solid #707070", color: "#707070", marginBottom: "15px" }}></Grid>
        </Grid>
        <Grid container>
          <Grid item xs> 
            <span className={`${classes.flama} ${classes.s8} ${classes.normal}`} style={{ color: '#707070' }}>
              {props.principal.propText}
              {' '}
              {props.line[props.principal.propName]}
            </span>
          </Grid>
          {props.gaveta2 ?
              <Grid item>
              <IconButton size="small" variant="outlined" color="primary" >
                <GetAppIcon />
                <props.gaveta2 line={props.line} classes={classes} title={props.gavetaTitle2}/>
              </IconButton>
            </Grid>
          : null}
          {props.gaveta ?
            <Grid item>
              <IconButton size="small" variant="outlined" color="primary" onClick={() => setOpen(!open)} >
                <AddIcon />
                <span className={`${classes.flama} ${classes.s1} ${classes.semibold}`} style={{ color: '#707070', textDecoration: 'underline', textDecorationColor: '##C6A489' }}>{props.gavetaTitle}</span>
              </IconButton>
            </Grid>
            : ""}
        </Grid>
        <Grid container direction="row" alignItems="flex-start" justifyContent="space-between" style={{ paddingTop: "10px" }} >
          {props.colunas.map((coluna, i) =>
            <Grid item key={`inn-col-${i}`}>
              <Grid container direction="column"  >
                <span className={`${classes.flama} ${classes.s4} ${classes.normal}`} style={{ color: '#707070' }}>{coluna.propText}</span>
                {coluna.component ?
                  <span className={`${classes.flama} ${classes.s2}`} style={{ color: '#707070' }} ><coluna.component {...coluna.componentProps} line={props.line}>{props.line[coluna.propName] || "-"}</coluna.component></span>
                  :
                  <span className={`${classes.flama} ${classes.s2}`} style={{ color: '#707070' }} > {props.line[coluna.propName] ? props.line[coluna.propName] : "-"} </span>}
              </Grid>
            </Grid>
          )}
        </Grid>
        {props.gaveta ?
          <Collapse in={open} >
            <Paper variant="outlined" style={{ backgroundColor: "lightgray", marginTop: "20px" }}>
              <props.gaveta line={props.line} {...props.gavetaProps} show={open} />
            </Paper>
          </Collapse>
          : null}
      </Grid>
    </Grid>
  )
};

export default Tabelas;