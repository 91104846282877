import MaisAtacado from "./MaisAtacado";

const getBoxNfe = (nfe) =>
  MaisAtacado.post(`/v1/recebimento/caixas-pedido`, nfe);

const setDivergencyNfe = (product) =>
  MaisAtacado.post(`/v1/recebimento/divergencia`, product);

const finishBoxNfe = (nfe) =>
  MaisAtacado.post(`/v1/recebimento/finalizar-caixa`, nfe);

const updateViolatedBox = (nfe) =>
  MaisAtacado.post(`/v1/recebimento/atualizar-caixa-violada`, nfe);

const finishRecebimento = (nfe) =>
  MaisAtacado.post(`/v1/recebimento/finish-recebimento`, nfe);

const registerNps = (score) =>
  MaisAtacado.post(`/v1/nps/answer`, score);

const registerKeepProduct = (products) =>
  MaisAtacado.post('/v1/recebimento/manter-produto', products)

const Faturamento = { getBoxNfe, setDivergencyNfe, registerNps, finishBoxNfe, updateViolatedBox, finishRecebimento, registerKeepProduct };
export default Faturamento;