import Quagga from 'quagga';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import PrimaryButton from '../../../components/PrimaryButton/PrimaryButton';

const quaggaConfig = {
  inputStream: {
    type: "LiveStream",
    target: null
  },
  locator: {
    patchSize: "medium",
    halfSample: true,
  },
  numOfWorkers: 0,
  decoder: {
    readers: ['code_128_reader']
  },
  locate: true,
};

const getUserMedia = () => {
  return new Promise((resolve, reject) => {
    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" }, audio: false })
      .then(function (stream) {
        resolve(stream);
      })
      .catch(function (err) {
        reject(err);
      });
  });
};

Quagga.onProcessed((result) => {
  const drawingCtx = Quagga.canvas.ctx.overlay;
  const drawingCanvas = Quagga.canvas.dom.overlay;

  if (result) {

    if (result.boxes) {
      drawingCtx.clearRect(0, 0, parseInt(drawingCanvas.getAttribute("width")), parseInt(drawingCanvas.getAttribute("height")));
      result.boxes.filter(function (box) {
        return box !== result.box;
      }).forEach(function (box) {
        Quagga.ImageDebug.drawPath(box, { x: 0, y: 1 }, drawingCtx, { color: "green", lineWidth: 2 });
      });
    }

    if (result.box) {
      Quagga.ImageDebug.drawPath(result.box, { x: 0, y: 1 }, drawingCtx, { color: "blue", lineWidth: 2 });
    }

    if (result.codeResult && result.codeResult.code) {
      Quagga.ImageDebug.drawPath(result.line, { x: 'x', y: 'y' }, drawingCtx, { color: 'red', lineWidth: 3 });
    }
  }
});


const BarCode = (props) => {
  const video = useRef();
  const canvas = useRef();
  const [codes, addCode] = useReducer((t, { e, type }) => type === 'add' ? ({ ...t, [e]: ((t[e] + 1) || 1) }) : {}, {});
  const [isScanning, setIsScanning] = useState(true);
  const [selectedCode, setSelectedCode] = useState('');
  const [widthWindow, setWidthWindow] = useState(window.innerWidth)
  const [heightWindow, setHeightWindow] = useState(window.innerHeight)

  useEffect(() => {
    (async () => {
      const videoStream = await getUserMedia();

      video.current.srcObject = videoStream;
      video.current.play();
      quaggaConfig.inputStream.target = video.current;

      Quagga.init(quaggaConfig, (err) => {
        if (err) {
          console.log(err);
          return;
        }
        Quagga.CameraAccess.getActiveTrack();
        Quagga.onDetected(r => { 
          console.log("ok", r)
          addCode({ type: 'add', e: r.codeResult.code });
         });
        Quagga.start();
      });
    })();
  }, []);

  useEffect(() => {
    console.log('run');
    if (Object.values(codes).reduce((a, b) => a + b, 0) >= 50) {
      const selected = Object.entries(codes).sort((a, b) => b[1] - a[1])[0][0];
      Quagga.stop();
      setIsScanning(false);
      setSelectedCode(selected);
    }
  }, [codes]);

  useEffect(() => {
    window.addEventListener('resize', () => setWidthWindow(window.innerWidth))
    window.addEventListener('resize', () => setHeightWindow(window.innerHeight))
    return () => {
        window.removeEventListener('resize', () => setWidthWindow(window.innerWidth))
        window.removeEventListener('resize', () => setHeightWindow(window.innerHeight))
    }
}, [widthWindow, heightWindow])

  return (
    <div style={{display: 'flex', alignItems: 'center', width: '100%', height: '90%', position: 'fixed', top: '57px', left: 0}}>
      {
        isScanning ? (<div style={{ display: 'grid' }}>
          <video style={{
            gridRowStart: 1,
            gridColumnStart: 1,
            position: 'fixed',
            top: '57px',
            left: 0,
            width: widthWindow,
            height: heightWindow -57,//valor do top
            objectFit: 'fill',
            zIndex: 2
          }} ref={video}></video>
          <canvas style={{
            gridRowStart: 1,
            gridColumnStart: 1,
            position: 'fixed',
            top: '57px',
            left: 0,
            width: widthWindow,
            height: heightWindow -57,//valor do top
            objectFit: 'fill',
            zIndex: 2
          }} className='drawingBuffer' ref={canvas} ></canvas>
          <div style={{zIndex: 3, transform: 'rotate(90deg)'}}>
      <PrimaryButton style={{marginTop: '60%'}}>
        Digitar código de barras
        </PrimaryButton>
        </div>
        </div>)
          : (<div>
            <strong>{selectedCode}</strong>
            <div>
              reads:{Object.entries(codes).map(([k, c]) => (<div>{k}-{c}</div>))}
            </div>
          </div>)
      }
    </div >
  );
};

export default BarCode;
