import React from "react";

import Tag from "../Tag/Tag";
import "./CardTag.css";

const CardTag = (props) => {
    const { tagName = "Tag", productName, children, isFinish, chosenOption } = props;

    const option = () => {
        if (isFinish) {
            const opt = chosenOption ? "Sim" : "Não";
            const questionText = tagName === "falta" ? "Caixa/Lacre violado?" : "Manter o produto?";
            
            return <p className="p__cardtag--option">{questionText} {opt}</p>;
        }

        return null;
    };
    
    return (
        <div className="div__cardtag--container">
            <Tag name={tagName === "falta" ? "Falta" : "Sobra"} color={tagName === "falta" ? "#F3AF00" : "#2596DA"} />
            <p className="p__cardtag--productname">{productName}</p>
            <div className="div__cardtag--infocontainer">{children}</div>
            {option()}
        </div>
    );
};

export default CardTag;
