import React from 'react'
import PrimaryButton from '../PrimaryButton/PrimaryButton';
import './FooterButton.css'

const FooterButton = (props) => {
    const { classNameContainer, children, ...rest } = props;

    return (
        <div className={'containerFooter ' + classNameContainer}>
            <PrimaryButton additionalClassName='buttonFooter' {...rest}>
                {children}
            </PrimaryButton>
        </div>
    )
}

export default FooterButton