import React, { useState, useEffect, useCallback } from 'react'
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion'

import SideMenuListItems from '../SideMenuListItems/SideMenuListItems'

import './SideMenuResponsive.css'


const SideMenuResponsive = props => {

    const currentURL = useLocation()
    const divConteinerVariants = {
        open: {
            display: "flex",
            opacity: 1,
            x: 0,
            transition: {
                duration: 0.3
            }
        },
        closed: {
            transitionEnd: { display: "none" },
            x: "-100%",
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    };

    const [menuState, setMenuState] = useState({
        admin: false,
        financeiro: false,
        comercial: false,
        afiliado: false,
        material: false
    })

    const closeOpenWindow = useCallback((type) => {
        let newState
        Object.keys(menuState).forEach(item => {
            if (item !== type) {
                newState = {
                    ...newState,
                    [item]: false
                }
            } else {
                newState = {
                    ...newState,
                    [item]: !menuState[item]
                }
            }
        })
        setMenuState(newState)
    }, [menuState])

    const onClickMenuItemHandler = useCallback((type) => {
        setMenuState(prevState => ({ ...prevState, [type]: !prevState[type] }))
        closeOpenWindow(type)
    }, [closeOpenWindow])

    useEffect(() => {
        props.setShow(false)
        Object.keys(menuState).map(i => (currentURL.pathname.toLowerCase().includes(i) && setMenuState(prevState => ({ ...prevState, [i]: true }))))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentURL])

    return (
        <motion.div className="div__sidemenuresponsive--conteiner" variants={divConteinerVariants} animate={props.show ? 'open' : 'closed'}>
            <SideMenuListItems state={menuState} setState={onClickMenuItemHandler} shouldShow={props.show} isMenuVisible={true} />
        </motion.div>
    )
}


export default SideMenuResponsive