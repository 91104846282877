import React, { useEffect, useState, useCallback } from 'react';
import Checkbox from '@mui/material/Checkbox';

const round = (value) => {
  return Math.round((typeof (value) == 'number' ? value : 0) * 100) / 100;
};

const sum = (v1, v2) => {
  return v1 + v2;
};

const subtract = (v1, v2) => {
  return v1 - v2;
};

const CheckboxVendas = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const dateObj = new Date()
  let month = dateObj.getUTCMonth() + 1
  if (month < 10) month = "0" + month
  const year = dateObj.getUTCFullYear()
  const date = month + "/" + year

  const onCheckToBeSameProfiles = useCallback(()=>{
    for (let key in props.opcoes) {
      if (props.opcoes[key].selected) {
        if (props.opcoes[props.line.ano_mes].id_profiles !== props.opcoes[key].id_profiles) {
          setIsDisabled(true)
        }
      }
    }
  },[props.line.ano_mes, props.opcoes])

  useEffect(() => {
    if (props.line.chamado || props.line.num_credito || props.line.comissao <= 0 || date === props.line.ano_mes) {
      setIsDisabled(true);
      if (props.checkbox) props.isDisabled(true)
    }
  }, [props.line.ano_mes, props.line.num_credito, props.line.chamado, props.line.comissao, date, props]);

  useEffect(() => {
    props.setChecked(props.opcoes[props.line.ano_mes].selected)
  }, [props])

  const handleChange = (event) => {
    props.change(event);
    let valor, comissao, credito, deposito;

    if (props.opcoes[props.line.ano_mes].selected === true) {
      valor = sum(round(props.valorTotal), round(props.line.valor));
      comissao = sum(round(props.comissaoTotal), round(props.line.comissao));
      credito = sum(round(props.credito), round(props.line.credito));
      deposito = sum(round(props.deposito), round(props.line.deposito));
    } else {
      valor = subtract(round(props.valorTotal), round(props.line.valor));
      comissao = subtract(round(props.comissaoTotal), round(props.line.comissao));
      credito = subtract(round(props.credito), round(props.line.credito));
      deposito = subtract(round(props.deposito), round(props.line.deposito));
    }

    props.setValor(valor);
    props.setComissao(comissao);
    props.setCredito(credito);
    props.setDeposito(deposito);
  };

  useEffect(() => {
    onCheckToBeSameProfiles()
  }, [onCheckToBeSameProfiles])

  return (
    <div>
      {(props.checkbox) ?
        <Checkbox
          style={isDisabled ? { display: "none" } : { display: "block", color: `${props.opcoes[props.line.ano_mes].selected ? '#AD9078' : 'black'}` }}
          name={props.line.ano_mes}
          checked={props.opcoes[props.line.ano_mes].selected}
          onChange={handleChange}
          disabled={isDisabled}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
        :
        ""
      }
    </div>
  );
};

export default CheckboxVendas;
