let apiPath = "https://mais-atacado-backend.somalabs.com.br";

// eslint-disable-next-line no-restricted-globals
// let apiPath = `${location.origin}:8080`;

const logoff = () => {
  //authFetch('/auth/logoff', 'GET');
  //setUser(undefined)
  localStorage.removeItem("CurUser");
};

const authFetch = async (path, method = "GET", data, abort) => {
  let response = await fetch(`${apiPath}${path}`, {
    signal: abort,
    body: data ? JSON.stringify(data) : undefined,
    method: method,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("CurUser")}`,
    },
    redirect: "follow",
  });

  if (response.status === 403) {
    // logoff();
    // eslint-disable-next-line no-restricted-globals
    //location.assign("/")
  }
  if (response.status !== 200) {
    //debugger;
  }

  return new Promise((resolve, reject) => {
    resolve(response);
  });
};

export {
  authFetch,
  logoff,
};
