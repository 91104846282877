import React, { useCallback } from 'react'
import { motion } from 'framer-motion'
import './index.css'


const Backdrop = props => {

    const { show, setShow } = props;

    const onClickHandler = useCallback(() => {
        setShow(false)
    }, [setShow])

    const backdropVariants = {
        open: {
            opacity: 1,
            y: 0,
            transition: {
                type: "tween",
                duration: 0.5
            }
        },
        closed: {
            y: "-100%",
            opacity: 0,
            transition: {
                duration: 0.2
            }
        }
    }

    return <motion.div variants={backdropVariants} animate={show ? "open" : "closed"} className="div__menu--backdrop" data-testid="div__menu--backdrop" onClick={onClickHandler} style={{ display: show ? "block" : "none" }}></motion.div>
}


export default Backdrop