import React from 'react'

import DetailsItem from './DetailsBoxItem'
import s from './DetailsBox.module.css'

const DetailsBox = (props) => {
    const { list } = props

    return (
        <div className={s.detailsBox}>
            <p>{list.name}</p>
            {list?.items?.map(item => (
                <DetailsItem key={item.title.key} {...item} />
            ))}
        </div>
    )
}

export default DetailsBox