import { useLocation } from "react-router-dom";

const useQuery = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return Object.fromEntries(params.entries());
};

const useQueryNoRouter = () => {
  // eslint-disable-next-line no-restricted-globals
  const params = new URLSearchParams(location.search);
  return Object.fromEntries(params.entries());
};
export { useQueryNoRouter };
export default useQuery;
