import React from 'react'

import { MenuItem, Select } from '@mui/material'

const SelectNegotiation = ({ label, handleChange, options, value }) => {
    return (
        <div>
            <Select fullWidth onChange={handleChange} value={value || ''}>
                {options?.map((op) => <MenuItem key={op.value} value={op.value}>{op.option}</MenuItem>)}
            </Select>
        </div>
    )
}

export default SelectNegotiation;