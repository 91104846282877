import React from 'react';
import { Typography, Grid } from '@mui/material';

const CadastroBloqueado = (props) => {
    return <>
        <Grid container style={{ alignItems: "center", justifyContent: "center" }}>
            <Typography style={{ fontFamily: 'flama-medium', fontSize: '19px' }}>
                Seu cadastro foi Bloqueado, entre em contato com seu representante para mais informações
            </Typography>
        </Grid>
    </>

}

export default CadastroBloqueado;