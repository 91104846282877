
import React from 'react';
import { LinearProgress, Grid, CircularProgress } from '@mui/material';

const SomaStatus = (props) => {
  const { children, status, emptyTitle, emptyText, ...rest } = props;

  const states = {
    waitComponent:
      <CircularProgress data-testid="waitComponent" variant="indeterminate" color='primary' />,
    wait:
      <LinearProgress data-testid="wait" variant="query" color="primary" />,
    empty:
      <Grid data-testid="empty" container justifyContent="center" alignItems="center" style={{ marginTop: "10vh" }} direction="column">
        <span style={{ fontSize: "700%", fontFamily: "flama-medium", fontWeight: "900", color: "#707070", textShadow: "1px 0px #707070, 9px 0px #E4D3C7" }}> {emptyTitle || 'Ooops'}</span>
        <span style={{ fontSize: "150%", fontFamily: "flama-medium", fontWeight: "bolder", color: '#707070' }}> {emptyText || 'Sem resultados para essa busca :('} </span>
      </Grid>,
    error:
      <Grid data-testid="error" container justifyContent="center" direction="column" alignItems="center" style={{ marginTop: "10vh" }}>
        <Grid item>
          <span style={{ fontSize: "600%", fontFamily: "flama-medium", fontWeight: "900", color: "#707070", textShadow: "1px 0px #707070, 9px 0px #E4D3C7", whiteSpace: "nowrap" }}> Deu <span role="img" aria-label="zebra">🦓</span></span>
        </Grid>
        <Grid item style={{ fontSize: "150%", fontFamily: "flama-medium", fontWeight: "bolder" }}>
          Ocorreu um erro inesperado.
        </Grid>
        <Grid item style={{ fontSize: "150%", fontFamily: "flama-medium", fontWeight: "bolder" }}>
          Tente novamente mais tarde!
        </Grid>
      </Grid>,
    result: children,
    ...rest
  };
  return states[status] || null;
};
export default SomaStatus

