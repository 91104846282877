import React from 'react'
import './SecondaryButton.css'

const SecondaryButton = props => {
    const { additionalClassName, ...rest } = props;

    return (
        <button className={`button__SecondaryButton--conteiner ${additionalClassName}`} {...rest} >
            {props.children}
        </button>
    )
}

export default SecondaryButton