import { toast } from "react-toastify";
import MaisAtacado from "./MaisAtacado";
import qs from "qs";

const getBoletos = (clientes,filter, { sz, pg, order }) =>
  MaisAtacado.post(`/v1/boleto?${qs.stringify({ sz, pg, order, ...filter })}`, clientes);

const getBoletoPdf = async (boleto) => {
  try {
    const response = await MaisAtacado.get(`/v1/boleto/pdf?${qs.stringify(boleto)}`);

    var tempElement = document.createElement("div");
    tempElement.innerHTML = response.data;
    var link = tempElement.querySelector("a").getAttribute("href");
    
    window.open(link);
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

const Boleto = { getBoletoPdf, getBoletos};
export default Boleto;
