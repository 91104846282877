import React, { useState, useEffect } from 'react';

import { authFetch } from '../../../helpers/authHelper';
import SomaTable from '../../../components/SomaStandard/SomaTableOld';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import Tabelas from '../../../components/SomaStandard/TableComponents/Tabelas';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import { encodeFilter } from '../../../helpers/webHelper';
import ListaMetas from '../Components/ListaMetas';
import EditMeta from '../Components/EditMeta';
import { useFontStyles } from '../../../helpers/styleHelper';
import { Grid, Button } from '@mui/material';
import { toast } from 'react-toastify';
import SomaConfirm from '../../../components/SomaStandard/SomaConfirm';

import TableSettings from '../../../components/TableSettings/TableSettings';

const Metas = (props) => {

  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filter, setFilter] = useState("");
  const [counter, setCounter] = useState(0);
  const [state, setState] = useState('result');

  const [meta, setMeta] = useState(undefined);
  const [metaMarca, setMetaMarca] = useState([]);

  const [open, setOpen] = useState(false);

  const classes = useFontStyles();

  const somatorioMetaPorMarca = () => {
    const aaa = [];

    metaMarca.forEach((meta) => {
      aaa.push(
        <Grid container
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          xs={12}
          sm={6}
        >
          <Grid item>
            <span className={`${classes.s8} ${classes.bold}`}>{meta.MARCA}</span>
          </Grid>
          <Grid item>
            <span className={`${classes.s6} ${classes.bold}`}>Somatório Metas Cliente</span>
          </Grid>
          <Grid item>
            <span className={`${classes.s5} `}><ConverteDinheiro>{meta.VALOR_META || "0"}</ConverteDinheiro></span>
          </Grid>
          <Grid item>
            <span className={`${classes.s6} ${classes.bold}`}>Meta a Atingir</span>
          </Grid>
          <Grid item>
            <span className={`${classes.s5}`}>
              {meta.META ? <ConverteDinheiro>{meta.META}</ConverteDinheiro> : "Sem Meta Definida"}
            </span>
          </Grid>
        </Grid>);
    });

    return (
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="center"
      >
        {aaa}
      </Grid>
    );
  };

  const EditMetaButton = (props) => {
    return (
      <Grid item>
        <Button variant="contained" style={{ marginRight: '10%', borderRadius: '4px', width: 170, height: 32, backgroundColor: "#CFBCAD", color: '#FFFFFF', fontWeight: 600, fontFamily: 'flama-basic', fontSize: 12, whiteSpace: 'nowrap' }}
          fullWidth onClick={() => { setMeta({ VALOR_META: props.line.VALOR_META, MARCA: props.line.MARCA_COD, CLIFOR_CLIENTE: props.line.CLIFOR, metasMarkup: props.line.metas }); }} >
          Editar Meta do Cliente
        </Button>
      </Grid>
    );
  };

  const MetaRepresentante = (props) => {
    return (
      <>
        {props.line.VALOR_META
          ?
          <ConverteDinheiro>{props.line.VALOR_META}</ConverteDinheiro>
          :
          <>Sem Meta Definida</>}
      </>
    );
  };

  let Opts = {
    endpoint: "/v1/Metas",
    columns: [
      {
        propName: "CLIENTE_ATACADO",
        component: Tabelas,
        componentProps: {
          colunas: [
            { propName: "TIPO_BLOQUEIO", propText: "Bloqueio" },
            // { propName: "NUMERO_PEDIDOS", propText: "Numero de Pedidos" },
            // { propName: "MAIOR_PEDIDO", propText: "Maior Pedido", component: ConverteDinheiro },
            // { propName: "MAIOR_QTDE_PEDIDO", propText: "Maior QTDE" },
            { propName: "VALOR_META", propText: "Meta Cliente", component: MetaRepresentante },
            { propName: "editMetaButton", propText: "", component: EditMetaButton },
          ],
          principal: { propName: "CLIENTE_ATACADO", propText: "Cliente " },
          gaveta: ListaMetas,
          gavetaTitle: 'Pedido Mínimo',
          gavetaProps: { editMeta: (meta, clifor) => setMeta({ meta, clifor }) }
        }
      }

    ],
    total, results,
    page, setPage,
    rowsPerPage, setRowsPerPage
  };

  const FilterOpts = {
    filterFields: [
      { propName: "CLIENTES_ATACADO.CLIENTE_ATACADO[lk]", propText: "Cliente", format: val => `%${val}%` },
      { propName: "CLIENTES_ATACADO.CLIFOR[lk]", propText: "Clifor", format: val => `%${val}%` },
      // { propName: "LOJAS_REDE.DESC_REDE_LOJAS[lk]", propText: "Marca", format: val => `%${val}%`, component:Dropdown, componentProps:{options: metaMarca.map(x=>{return {value: x.MARCA, text: x.MARCA}})} }
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  useEffect(() => {
    setState('wait');
    (async () => {
      try {
        getMetas(page, rowsPerPage, filter, Opts.endpoint);
      } catch (err) {
        setResults(undefined);
        console.log(err);
        setState('error');
      }
    })();
  }, [Opts.endpoint, page, rowsPerPage, filter, counter]);

  const getMetas = async (page, rowsPerPage, filter, endpoint) => {
    const json = await (await authFetch(`${endpoint}?pg=${page}&sz=${rowsPerPage}&${filter}`)).json();
    json.results = json.results.map((obj) => { return obj; });
    setResults(json.results);
    setTotal(json.total);
    setState(json.results?.length ? 'result' : 'empty');

    const jsonMetasMarca = await (await authFetch(`/v1/Metas/MetasMarca`)).json();
    setMetaMarca(jsonMetasMarca);
  };

  const saveMeta = (meta) => {
    if (meta.metasMarkup) {
      if (meta.metasMarkup.length > 0) {
        if (meta.VALOR_META < Math.min(...meta.metasMarkup.map(e => e.VALOR_ATINGIR))) {
          toast.error(`O Valor tem que ser igual ou maior que o Pedido Mínimo, que é ${Math.min(...meta.metasMarkup.map(e => e.VALOR_ATINGIR))}`, { theme: "colored" });
          return;
        }
      }
    } else {
      toast.error(`Opa, parece que esse cliente não tem pedido mínimo definido. Fale com a área Comercial!`, { theme: "colored" });
      return;
    }

    setMeta(undefined);
    (async () => {
      const resultSave = await (await authFetch(`/v1/Metas/save`, `POST`, meta)).json();
      if (resultSave === "error") {
        toast.error("Esse Cliente já realizou uma compra e portanto não pode alterar a meta!", { theme: "colored" });
      } else {
        toast.success("Meta salva com sucesso!", { theme: "colored" });
      }
      await getMetas(page, rowsPerPage, filter, Opts.endpoint);
    })();
  };

  return (
    <>
      {meta
        ?
        <EditMeta
          meta={meta}
          open={true}
          cancel={() => setMeta(undefined)}
          save={(meta) => { saveMeta(meta); }}
        />
        :
        ""}
      {somatorioMetaPorMarca()}
      <SomaConfirm onConfirm={async () => {
        setState('wait');
        await authFetch(`/v1/Metas/reset`, `POST`, {});
        setState('result');
        setOpen(false);
        setCounter(counter + 1);
      }} onCancel={() => setOpen(false)} open={open} message={'Isso ira zerar as metas de todos os seus clientes, a não ser que ja tenham comprado algo no showroom'} ></SomaConfirm>
      <div style={{ margin: "10px", padding: "15px" }}>
        <Button variant="contained" color="primary" fullWidth onClick={() => setOpen(true)}>Zerar Metas</Button>
      </div>
      <TableSettings>
        <div></div>
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={state}>
        <SomaTable {...Opts} />
      </SomaStatus>
    </>
  );

};

export default Metas;
