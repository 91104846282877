import React from 'react'
import TableButton from '../../../components/SomaStandard/TableComponents/TableButton/TableButton'
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';


const ButtonProduct = props => {

    return (
        <TableButton clicked={() => props.setShow(!props.show)}>
            {!props.show ?
                (<>
                    <AddIcon />
                    <span>detalhar</span>
                </>) : (
                    <>
                        <RemoveIcon />
                        <span>recolher</span>
                    </>
                )}
        </TableButton>
    )
}


export default ButtonProduct