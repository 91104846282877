import MaisAtacado from "./MaisAtacado";
import qs from "qs";

const fetchInfo = () => MaisAtacado.get("/v1/afiliados");

const fetchByCpf = (cpf) => MaisAtacado.get(`/v1/afiliados/find/cpf/${cpf}`);

const getContrato = () =>
  MaisAtacado.get("/v1/afiliados/contrato", { responseType: "text" });

const getBrands = () => MaisAtacado.get("/v1/afiliados/brands");

const acceptContrato = ({ idContrato, data }) =>
  MaisAtacado.post("/v1/afiliados/contrato/aceito", { idContrato, data });

const cadastraMM = (cadastro) => MaisAtacado.post("/v1/afiliados/", cadastro);

const editaMM = (cadastro) => MaisAtacado.patch("/v1/afiliados/", cadastro);

const cadastraVend = (cadastro) =>
  MaisAtacado.post("/v1/afiliados/vendedor", cadastro);

const editaVend = (cadastro) =>
  MaisAtacado.patch("/v1/afiliados/vendedor", cadastro);

const removeVend = (idAffiliates) =>
  MaisAtacado.delete(`/v1/afiliados/vendedor/${idAffiliates}`);

const getVend = (filter) =>
  MaisAtacado.get(`/v1/afiliados/vendedor?${qs.stringify(filter)}`);

const minhasVendasUploadFile = (file, periodo, profile) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("periodo", periodo);
  formData.append("profile", profile);

  return MaisAtacado.post("/v1/MinhasVendasNewer/upload", formData);
};

const minhasVendasUploadFileAdm = (file, periodo, profile) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("periodo", periodo);
  formData.append("profile", profile);

  return MaisAtacado.post("/v1/MinhasVendasNewer/upload/adm", formData);
};

const Afiliados = {
  fetchInfo,
  fetchByCpf,
  getBrands,
  getContrato,
  acceptContrato,
  cadastraMM,
  editaMM,
  cadastraVend,
  removeVend,
  editaVend,
  getVend,
  minhasVendasUploadFile,
  minhasVendasUploadFileAdm,
};
export default Afiliados;
