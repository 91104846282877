import React from "react"
import styles from './Tag.module.css'

const Tag = (props) => {
    const { name, color, style } = props;
 
    return (
        <div style={{backgroundColor: color || "#F2F2F2", ...style}} className={styles.divergencyTag}>
            {name}
        </div>
    )
}

export default Tag