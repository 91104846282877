import * as XLSX from 'xlsx';

export const exportToExcel = (data, filename) => {
    if (data.length === 0) {
        return;
    }

    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'planilha1');
    XLSX.writeFile(wb, filename);
};