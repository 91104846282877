import React from 'react';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import FormMultimarca from '../Components/FormMultimarca';
// import FormVendedor from '../Components/FormVendedor';
import CadastroBloqueado from '../Components/CadastroBloqueado';
import useAffiliate from '../../../Hooks/AffiliateContext';

const CadastroMM = (props) => {
  const { affiliate, status, updateAffiliate, profiles } = useAffiliate();

  const pageStatus = status ? 'result' : 'wait';

  const edit = affiliate ? {
    id_affiliates: affiliate.id_affiliates,
    name: affiliate.affiliate_name,
    email: affiliate.info.email,
    document: affiliate.info.CNPJ,
    phone: affiliate.info.phone,
    cellPhone: affiliate.info.phone_2,
    postalCode: affiliate.info.CEP,
    city: affiliate.info.city,
    state: affiliate.info.state,
    banco: affiliate.info.bank_number,
    agencia: affiliate.info.bank_agency,
    conta: affiliate.info.bank_account,
    id_profiles: profiles.find(profile => profile.id_profiles === affiliate?.id_profiles)?.id_profiles || profiles[0].id_profiles,
    contratoAccept: affiliate.acceptedContracts.find(x => x.id_profiles === affiliate?.id_profiles && !x.deactivated_at)
  } : undefined;

  return (
    <>
      <SomaStatus status={pageStatus}>
        {
          !status?.bloqueio ?
            <FormMultimarca setAfiliado={updateAffiliate} edit={edit} profiles={profiles} />
            :
            <CadastroBloqueado />
        }
      </SomaStatus>
    </>
  );
};

export default CadastroMM;
