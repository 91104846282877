import React, { useState, useEffect, useMemo } from 'react';
import { authFetch } from '../../../helpers/authHelper';
import SomaStatus from '../../../components/SomaStandard/SomaStatus';
import SomaFilter from '../../../components/SomaStandard/SomaFilter';
import { encodeFilter } from '../../../helpers/webHelper';
import ConverteDinheiro from '../../../components/SomaStandard/TableComponents/ConverteDinheiro';
import DatePicker from '../../../components/SomaStandard/FilterComponents/DatePicker';
import ToFrom from '../../../components/SomaStandard/FilterComponents/ToFrom';
import TabelaResumo from '../../../components/TabelaResumo';
import SomaTable from '../../../components/SomaStandard/SomaTable/SomaTable';
import TableSettings from '../../../components/TableSettings/TableSettings';

const calculaResumo = (res) => res.reduce((a, b) => ({ venda_total: a.venda_total + b.venda_total, qtd_pedidos_totais: a.qtd_pedidos_totais + b.qtd_pedidos_totais }), { venda_total: 0, qtd_pedidos_totais: 0 });

const Vendas = (props) => {
  const [total, setTotal] = useState(0);
  const [results, setResults] = useState([]);
  const [status, setStatus] = useState('wait');
  const [filter, setFilter] = useState("");

  const FilterOpts = {
    filterFields: [
      { propName: "data", component: ToFrom, propText: "Data", componentProps: { component: DatePicker } },
      { propName: "CLIENTES_ATACADO.CLIENTE_ATACADO[lk]", propText: "Multimarca", format: val => `%${val}%` },
    ],
    updateFilter: (e) => setFilter(encodeFilter(e))
  };

  const Opts = {
    endpoint: "/v1/Vendas/representante",
    columns: [
      {
        propName: "Vendas Afiliados",
        componentProps: {
          colunas: [
            { propName: "affiliate_name", propText: "",highlighted: true },
            { propName: "CLIFOR", propText: "Clifor", component: ({ line }) => line.info.CLIFOR },
            { propName: "qtd_pedidos_totais", propText: "Pedidos Totais" },
            { propName: "venda_total", propText: "Venda", component: ConverteDinheiro },
            { propName: "venda_total", propText: "Comissão", component: (props) => (<>R$ {(parseFloat(props.children) * 0.02).toFixed(2)} </>) }
          ]
        }
      }
    ],
    total, results
  };

  useEffect(() => {
    setStatus('wait');
    (async () => {
      try {
        const json = await (await authFetch(`${Opts.endpoint}?${filter}`)).json();
        console.log(json)
        setResults(json);
        setTotal(json.length);
        setStatus(json.length ? 'result' : 'empty');
      } catch (err) {
        setResults([]);
        console.log(err);
        setStatus('error');
      }
    })();
  }, [Opts.endpoint, filter]);

  const resumo = useMemo(() =>
    results.length ? calculaResumo(results) : { venda_total: 0, qtd_pedidos_totais: 0 }
    , [results]);

  return (
    <>
      <TableSettings>
        <div></div>
        <SomaFilter {...FilterOpts} />
      </TableSettings>
      <SomaStatus status={status}>
        <TabelaResumo indicatorsList={[{ indicator: 'Venda Total', value: `R$ ${(resumo.venda_total).toFixed(2)}` },
        { indicator: 'Comissão Total', value: `R$ ${(0.02 * resumo.venda_total).toFixed(2)}` },
        { indicator: 'Pedidos Totais', value: resumo.qtd_pedidos_totais }]}
          title={'Resumo'} />
        <SomaTable {...Opts} />
      </SomaStatus>
    </>
  );
};

export default Vendas;
