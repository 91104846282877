import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import useUser from "../../../Hooks/UserContext";

const PrivateRoute = (props) => {
  const user = useUser();
  const location = useLocation();

  const { children, to, redirect } = props;

  let show = false;
  if (user) {
    show = true;
    if (typeof to == "string") {
      show = user.features[to];
    }
    if (typeof to == "function") {
      show = to(user.features);
    }
    if (typeof to == "boolean") {
      show = to;
    }
  }

  if (show) {
    if (children) {
      return children;
    } else {
      return <Navigate replace={true} to="/" />;
    }
  } else {
    return (
      <Navigate
        replace={true}
        to={{ pathname: redirect || "/Login", search: location.search }}
      />
    );
  }
};
export default PrivateRoute;
