import { toast } from "react-toastify";

class ErrorWithToast extends Error {
  constructor(message, status) {
    super(message);

    this.display = (apiMessage) => {
      let type;
      if (status > 400 && status < 500) type = 'warning'
      else type = 'error'

      toast[type](message);
      console.log(this.stack);
    }
  }
}

export default ErrorWithToast;
