import React from 'react';
import LogoAtacado from '../../../assets/images/icons/LogoMaisAtacado.svg';
import LogoSoma from '../../../assets/images/icons/logoSoma.svg';
import './LoginForm.css';

const LoginForm = props => {
  return (
    <div className="div__loginform--conteiner">
      <div className="div__loginformatacado--logo">
        <img src={LogoAtacado} alt="Logo +Atacado" />
      </div>
      <div className="form__loginform--conteiner">
        <a href={`${process.env.REACT_APP_SA_ADDRESS}/auth?scope=openid%20permissions&response_type=code&client_id=${process.env.REACT_APP_SA_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_SA_RETURN_URL}`}>
          Entrar
        </a>
      </div>
      <div className="div__formloginsoma--logo"><img src={LogoSoma} alt="Grupo Soma logo" /></div>
    </div>
  );
};

export default LoginForm;
