const valitadeEmail = (email) => {
    const valitadors = [
        { test: (e) => e, message: 'Obrigatorio' },
        { test: (e) => e?.split('@').length >= 2, message: 'Email Invalido' }
    ]

    return valitadors.map(v => v.test(email) ? false : v.message).filter(v => v)[0]
}


export { valitadeEmail }