import React from "react";
import { NavLink } from "react-router-dom";
import { motion } from "framer-motion";

import ArrowRightIcon from "../../../assets/images/icons/arrow_right.svg";

import Show from "../../SomaStandard/AuthComponents/Show";
import "./SideMenuListItem.css";

const SideMenuListItem = (props) => {
  const { onClick, data, open, show } = props;
  const ulVariants = {
    open: {
      display: "flex",
      opacity: 1,
      y: 0,
    },
    closed: {
      transitionEnd: { display: "none" },
      y: "-10%",
      opacity: 0,
      transition: {
        duration: 0.2,
      },
    },
  };

  return (
    <Show to={show}>
      <li
        data-testid="li_menu"
        className={`li__menuitem--conteiner ${open && "li__menuitemActived--conteiner"
          }`}
        onClick={onClick}
      >
        <motion.img
          animate={open ? { rotate: 90 } : { rotate: 0 }}
          transition={{ duration: 0.1 }}
          src={ArrowRightIcon}
          alt="Ícone de seta."
        />
        <span>{data.title}</span>
      </li>
      <motion.ul
        className="ul__menusubitems--conteiner"
        variants={ulVariants}
        animate={open ? "open" : "closed"}
      >
        {data.items.map((item) => (
          <Show key={item.name} to={item.showTo}>
            <NavLink
              exact="true"
              key={item.name}
              id={data.title + item.name}
              className={({ isActive }) =>
                `a__menusubitems--item ${isActive ? "a__menusubitemsactived--item" : ""}`
              }
              to={item.url}
            >
              <li> {item.name} </li>
            </NavLink>
          </Show>
        ))}
      </motion.ul>
    </Show>
  );
};

export default SideMenuListItem;
