import React from 'react'
import s from './Products.module.css'

import ProductItem from './ProductItem'

const ProductsList = (props) => {
  const { list } = props

  return (
    <ul className={s.productsList}>
      {
        list &&
        list.map(item => <ProductItem key={item.title.id} {...item} />)
      }
    </ul>
  )
}

export default ProductsList