import React from 'react'
import { motion } from 'framer-motion'

const AnimationDots = props => {


    const loadingVariants = {
        animationOne: {
            opacity: [1, 0],
            transition: {
                opacity: {
                    repeat: Infinity,
                    duration: 0.8,
                    ease: "easeOut"
                },
            }
        },
        animationTwo: {
            opacity: [1, 0],
            transition: {
                opacity: {
                    repeat: Infinity,
                    duration: 0.8,
                    ease: "easeOut",
                    delay: 0.12
                },
            }
        },
        animationThree: {
            opacity: [1, 0],
            transition: {
                opacity: {
                    repeat: Infinity,
                    duration: 0.8,
                    ease: "easeOut",
                    delay: 0.14
                },
            }
        }
    }

    return (
        <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
            <div data-testid="animationDots_test" style={{ display: "flex" }}>
                <motion.span variants={loadingVariants} animate="animationOne" style={{ margin: "0 2px", width: "7px", height: "7px", backgroundColor: `${props.color}`, borderRadius: "50%" }}></motion.span>
                <motion.span variants={loadingVariants} animate="animationTwo" style={{ margin: "0 2px", width: "7px", height: "7px", backgroundColor: `${props.color}`, borderRadius: "50%" }}></motion.span>
                <motion.span variants={loadingVariants} animate="animationThree" style={{ margin: "0 2px", width: "7px", height: "7px", backgroundColor: `${props.color}`, borderRadius: "50%" }}></motion.span>
            </div>
        </div>
    )
}

export default AnimationDots