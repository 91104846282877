import React, { useEffect, useState } from "react";

import "./Styles.css";

const PageLoading = ({isLoading}) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const texts = ['Atualização em progresso, estamos quase lá.', 'Fique tranquilo, estamos quase acabando!', 'Só mais um pouquinho! A atualização está quase pronta.'];
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }, 8000);
  
      return () => clearInterval(intervalId);
    }, [texts.length]); 

    return (
    <>
        {isLoading &&
            <div className="div__pageloading-loadercontainer">
            <div className="div__pageloading-loader"></div>
            <span id='loaderTextChange' className="div__pageloading-loadertext">{texts[currentIndex]}</span>
            </div>
        }
    </>
  );
};

export default PageLoading;
